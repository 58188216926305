import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, Spinner } from '@chakra-ui/react';

const LoadingModal1 = React.memo(({ isOpen, onClose }) => {
  console.log('LoadingModal1 rerendered')
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="rgba(26, 32, 44, 0)" boxShadow="none">
        <ModalBody
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="400px"
          opacity="0.6"
        >
          <Spinner style={{ "--spinner-size": "14rem" }} color="white" thickness="3px" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default LoadingModal1;
