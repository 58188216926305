import React from "react";
import { Box, Divider, Text } from "@chakra-ui/react";

const ManualCategoriesList = ({ names, onNameClick }) => {
    const handleClick = (name) => {
        if (onNameClick) {
          var data = JSON.stringify({
            // "spec":"ATA-100",
            "vlibrary":`${name.fileName}`,
            // "mdpath":"CH604",
            // "library":"CH604MM",
            // "publication":"AMM",
            // "publicationFile":"CH604MM_AMM_INDEX.json",
            // "publicationTitle":"Aircraft Maintenance Manual (Part Two)",
            // "coverPage":"CH604MM_AMM.pdf"
          });
          // console.log(data)
          onNameClick(name);
        }
    };

    // function formatDate(dateString) {
    //   const year = dateString.slice(0, 4);
    //   const month = parseInt(dateString.slice(4, 6)) - 1;
    //   const day = dateString.slice(6, 8);
    
    //   const formattedDate = new Date(year, month, day).toLocaleDateString("en-US", {
    //     month: "long",
    //     day: "numeric",
    //     year: "numeric",
    //   });
    
    //   return formattedDate;
    // }
  return (
    <Box>
        <Divider/>
        {names.map((name, index) => (
            <Box key={index}
            _hover={{ cursor: "pointer", bg: "brand.500" }}>
                {/* <Text ml='30px' py={2} onClick={() => console.log(name)}>{`${name.title} [${name.fldrName}] :: ${name.tsn}, dated ${name.revdate}`}</Text> */}
                <Text ml='30px' py={2} onClick={() => handleClick(name)}>{`${name.title} [${name.fldrName}] :: ${name.tsn}, dated ${name.revdate}`}</Text>
                {index !== names.length - 1 && <Divider/>}
            </Box>
        ))}
      <Divider/>
      {/* <Divider my={2} /> */}
    </Box>
  );
};

export default ManualCategoriesList;