import React, { useState, useEffect, useRef } from 'react';

import axios from "axios";

import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Input,
  ListItem,
  UnorderedList,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  MenuGroup,
  MenuDivider,
  Box,
  useToast,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Tabs,
  TabList,
  Tab,
  Icon,
  TabPanels,
  TabPanel,
  TabIndicator,
  Checkbox,
  Grid,
  Spinner,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react";

import './main.css';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import { BiReset } from "react-icons/bi";
import { AiFillSave, AiOutlineSetting, AiOutlineLink, AiOutlineReload, AiOutlineSearch, AiOutlinePicture, AiOutlineDatabase, AiOutlineDownload } from "react-icons/ai";
import { Element, Link } from "react-scroll";

import { verifyUserAPI, getSignInData } from '../../helpers/backend_helper';

// Tables
import AdminPanelConnectionsTable from "components/Tables/AdminPanelConnectionsTable";
import { columnsDataAdminPanelConnections } from "variables/columnsData";
import tableDataPartsPrices from "variables/tableDataPartsPrices.json";

const AdminPanel = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState('');
  const [tableDataConnectionsList, setTableDataConnectionsList] = useState([]);

  useEffect(() => {
      setIsLoading(true)
      verifyUserAPI().then((userData) => {
          if (userData?.status === true) {
              setRole(userData?.data.role)
              getSignInData().then((connectionsData) => {
                setTableDataConnectionsList(connectionsData.data)
              })
              setIsLoading(false)
          }
          else {
            setIsLoading(false)
          }
      })
  }, [])

  return (
    <div style={{marginTop: '70px', color: 'white'}}>
      <Box
          // mt='20px'
				mb={'8px'}
				borderRadius='15px'
				// px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				{/* Header */}
				<Card
					direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					justifyContent={{ sm: 'center', md: 'space-between' }}
					align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
					<Flex align='center' direction={{ sm: 'column', md: 'row' }}>
						<Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
						{/* <Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}> */}
							<Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
								<Text
                  align='top'
									fontSize={{ sm: 'lg', lg: 'xl' }}
									color='#fff'
									fontWeight='bold'
									ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
									Admin panel
								</Text>
								{/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
							</Flex>
						</Flex>
            {/* <Stack
                direction={{ sm: "column", lg: "row" }}
                spacing='24px'
                mr='600px'>
                <FormControl width='180px'>
                  <Select
                    bg='#0F1535'
                    border='0.5px solid'
                    borderColor='#E2E8F04D'
                    borderRadius='15px'
                    placeholder='Aircraft type...'
                    fontSize='xs'
                    cursor='pointer'
                    color='gray.400'>
                    <option>Challenger 300</option>
                    <option>Challenger 350</option>
                    <option>Challenger 601</option>
                    <option>Challenger 604</option>
                    <option>Challenger 605</option>
                    <option>Challenger 650</option>
                    <option>Global 5000</option>
                    <option>Global 5500</option>
                    <option>Global 6000</option>
                    <option>Global 6500</option>
                    <option>Global 7500</option>
                    <option>Global XRS</option>
                    <option>Learjet 75</option>
                  </Select>
                </FormControl>
              </Stack> */}
						<Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
            {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineReload size='20px' />}
                onClick={() => {
                  refreshData()
                }}>
                REFRESH
            </Button> */}
            {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  ConnectToServer()
                }}>
                CONNECT
              </Button> */}
            {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  onOpen2()
                }}>
                TEST
              </Button> */}
              {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  resetData()
                }}>
                RESET
              </Button> */}
              {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                // mt='38px'
                ml='10px'
                mr='14px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  // resetData()
                  onOpen3()
                }}>
                TEST
              </Button> */}
						</Flex>
					</Flex>
				</Card>
			</Box>
      {role === 'admin' ? (
        <Card px='0px'>
          <CardHeader ml='20px'>
						<Text fontSize='lg' color='#fff' fontWeight='bold'>
							Connections history
						</Text>
					</CardHeader>
          <CardBody>
            {/* <SearchTablePartsPrices tableData={tableDataPartsPricesList} columnsData={columnsDataPartsPrices} /> */}
            <AdminPanelConnectionsTable tableData={tableDataConnectionsList} columnsData={columnsDataAdminPanelConnections} />
            {/* {tableDataConnectionsList} */}
          </CardBody>
        </Card>
      ) : (
        <div>
          {/* Content only visible to admin */}
          <p>Welcome!</p>
          <p>You are not administrator.</p>
        </div>
      )}
      <Modal isOpen={isLoading} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent  bg="rgba(26, 32, 44, 0)" boxShadow='none'>
          <ModalBody display="flex" alignItems="center" justifyContent="center" minHeight="400px" opacity='0.6'>
            <Spinner style={{ "--spinner-size": "14rem" }} color='white' thickness='3px'/>
            {/* <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
              /> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default AdminPanel;
