import React, { useRef, useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';

import {
  Button,
  Flex,
  Text,
  color
} from "@chakra-ui/react";

import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

function DocumentViewer({ urlPDF, fileType }) {
  const isPDF = fileType.endsWith('.pdf');
  const isSVG = fileType.endsWith('.svg');
  const isHTML = fileType.endsWith('.html');
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [scrollZoomFactor, setScrollZoomFactor] = useState(0);
  const [htmlContent, setHtmlContent] = useState('');

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(prevPageNumber => prevPageNumber - 1);
    }
  };

  const handleZoomIn = () => {
    if (svgRef.current) {
      const newZoomLevel = zoomLevel + 0.3;
      svgRef.current.style.transform = `scale(${newZoomLevel}) translate(${offsetX}px, ${offsetY}px)`;
      setZoomLevel(newZoomLevel);
    }
  };

  const handleZoomOut = () => {
    if (svgRef.current && zoomLevel > 1.0) {
      const newZoomLevel = zoomLevel - 0.3;
      svgRef.current.style.transform = `scale(${newZoomLevel}) translate(${offsetX}px, ${offsetY}px)`;
      setZoomLevel(newZoomLevel);
    }
  };

  const handleZoomInPDF = () => {
    setZoomLevel(prevZoomLevel => prevZoomLevel + 0.3);
  };

  const handleZoomOutPDF = () => {
    setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel - 0.3, 0.3));
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsDragging(true);
    setStartX(event.clientX);
    setStartY(event.clientY);
  };

  const handleMouseMove = (event) => {
    event.preventDefault();
    if (isDragging && svgRef.current && containerRef.current) {
      const deltaX = event.clientX - startX;
      const deltaY = event.clientY - startY;
      const newOffsetX = offsetX + deltaX;
      const newOffsetY = offsetY + deltaY;
      svgRef.current?.style?.setProperty('transform', `scale(${zoomLevel}) translate(${newOffsetX}px, ${newOffsetY}px)`);
      setOffsetX(newOffsetX);
      setOffsetY(newOffsetY);
      setStartX(event.clientX);
      setStartY(event.clientY);
    }
  };

  // const handleMouseMove = (event) => {
  //   event.preventDefault();
  //   if (isDragging && svgRef.current && containerRef.current) {
  //     const deltaX = event.clientX - startX;
  //     const deltaY = event.clientY - startY;
  //     const newOffsetX = offsetX + deltaX;
  //     const newOffsetY = offsetY + deltaY;

  //     svgRef.current.style.transform = `scale(${zoomLevel}) translate(${newOffsetX}px, ${newOffsetY}px)`;

  //     setOffsetX(newOffsetX);
  //     setOffsetY(newOffsetY);
  //     setStartX(event.clientX);
  //     setStartY(event.clientY);
  //   }
  // };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleScroll = (event) => {
    event.preventDefault();
    const zoomFactor = 0.3;
    const deltaY = event.deltaY;

    if (isHTML && svgRef.current) {
      const currentStyle = svgRef.current.style;

      if (currentStyle) {
        const currentTransform = currentStyle.transform || '';

        let currentZoomLevel = 1.0;
        const match = currentTransform.match(/scale\(([^)]+)\)/);
        if (match) {
          currentZoomLevel = parseFloat(match[1]);
        }

        const newZoomLevel = deltaY > 0 ? currentZoomLevel - zoomFactor : currentZoomLevel + zoomFactor;

        currentStyle.transform = `scale(${newZoomLevel}) translate(${offsetX}px, ${offsetY}px)`;
        setZoomLevel(newZoomLevel);
      }
    }
  };

  // const handleScroll = (event) => {
  //   event.preventDefault();
  //   const zoomFactor = 0.3;
  //   const deltaY = event.deltaY;

  //   if (deltaY > 0) {
  //     const newZoomLevel = zoomLevel - zoomFactor;
  //     svgRef.current.style.transform = `scale(${newZoomLevel}) translate(${offsetX}px, ${offsetY}px)`;
  //     setZoomLevel(newZoomLevel);
  //   } else if (deltaY < 0) {
  //     const newZoomLevel = zoomLevel + zoomFactor;
  //     svgRef.current.style.transform = `scale(${newZoomLevel}) translate(${offsetX}px, ${offsetY}px)`;
  //     setZoomLevel(newZoomLevel);
  //   }
  // };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1)
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('wheel', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isHTML) {
      fetch(urlPDF)
        .then(response => response.text())
        .then(data => setHtmlContent(data))
        .catch(error => console.error('Error fetching HTML content:', error));
    }
  }, [isHTML, urlPDF]);

  return (
    <div>
      {isPDF && (
        <div style={{ width: '1200px', height: '90vh' }}>
          {/* <h2>PDF Viewer</h2> */}
          <object data={urlPDF} type="application/pdf" width="100%" height="900px">
            <div>
              <Flex direction="row">
                <Button
                  variant='outline'
                  _hover={{ bg: 'brand.500', color: 'white' }}
                  borderColor='brand.500'
                  color='brand.200'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "6px" }}
                  pr='0px'
                  pl='7px'
                  mt='6px'
                  ml='10px'
                  leftIcon={<FaArrowLeft size='20px' />}
                  onClick={handlePreviousPage}
                />
                <Text
                  mb={{ sm: "6px", md: "6px" }}
                  pr='0px'
                  pl='7px'
                  mt='10px'
                  ml='20px'
                >
                  {pageNumber} / {numPages}
                </Text>
                <Button
                  variant='outline'
                  _hover={{ bg: 'brand.500', color: 'white' }}
                  borderColor='brand.500'
                  color='brand.200'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "6px" }}
                  pr='0px'
                  pl='7px'
                  mt='6px'
                  ml='30px'
                  leftIcon={<FaArrowRight size='20px' />}
                  onClick={handleNextPage}
                />
                <Button
                  variant='outline'
                  _hover={{ bg: 'brand.500', color: 'white' }}
                  borderColor='brand.500'
                  color='brand.200'
                  // color='white'
                  // w='150px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "6px" }}
                  pr='0px'
                  pl='7px'
                  mt='6px'
                  ml='200px'
                  leftIcon={<AiOutlineZoomIn size='20px' />}
                  onClick={handleZoomInPDF}>
                </Button>
                <Button
                  variant='outline'
                  _hover={{ bg: 'brand.500', color: 'white' }}
                  borderColor='brand.500'
                  color='brand.200'
                  // color='white'
                  // w='150px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "6px" }}
                  pr='0px'
                  pl='7px'
                  mt='6px'
                  ml='30px'
                  leftIcon={<AiOutlineZoomOut size='20px' />}
                  onClick={handleZoomOutPDF}>
                </Button>
              </Flex>
            </div>
            <div
              // ref={containerRef}
              style={{
                width: '100%',
                height: '900px',
                position: 'relative',
                // cursor: isDragging ? 'grabbing' : 'grab',
                overflow: 'hidden',
              }}
            >
              <Document
                file={urlPDF}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  pageNumber={pageNumber}
                  height={containerRef.current?.offsetHeight}
                  renderTextLayer={false}
                  // width={containerRef.current?.offsetWidth}
                  scale={zoomLevel}
                />
              </Document>
            </div>
            {/* <p>
              It seems your browser doesn't support PDFs. You can download the PDF file
              <a href={urlPDF}>here</a>.
            </p> */}
          </object>
          {/* <iframe src={urlPDF} width="100%" height="900px"></iframe> */}
        </div>
      )}

      {isSVG && (
        <div>
          {/* <h2>SVG Viewer</h2> */}
          <div style={{ width: '1200px' }}>
            <Button
              variant='outline'
              _hover={{ bg: 'brand.500', color: 'white' }}
              borderColor='brand.500'
              color='brand.200'
              // color='white'
              // w='150px'
              h='35px'
              fontSize='xs'
              fontWeight='bold'
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "6px", md: "6px" }}
              pr='0px'
              pl='7px'
              mt='6px'
              ml='10px'
              leftIcon={<AiOutlineZoomIn size='20px' />}
              onClick={handleZoomIn}>
            </Button>
            <Button
              variant='outline'
              _hover={{ bg: 'brand.500', color: 'white' }}
              borderColor='brand.500'
              color='brand.200'
              // color='white'
              // w='150px'
              h='35px'
              fontSize='xs'
              fontWeight='bold'
              alignSelf={{ sm: "flex-start", lg: null }}
              mb={{ sm: "6px", md: "6px" }}
              pr='0px'
              pl='7px'
              mt='6px'
              ml='10px'
              leftIcon={<AiOutlineZoomOut size='20px' />}
              onClick={handleZoomOut}>
            </Button>
          </div>
          <div
            ref={containerRef}
            style={{
              width: '100%',
              height: '600px',
              position: 'relative',
              cursor: isDragging ? 'grabbing' : 'grab',
              overflow: 'hidden',
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            <svg
              ref={svgRef}
              width="100%"
              height="100%"
              style={{ backgroundColor: "white", transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)` }}
            >
              <image xlinkHref={urlPDF} width="100%" height="100%" />
            </svg>
          </div>
        </div>
      )}

      {isHTML && (
        <div style={{ width: '1200px'}}>
          {/* HTML Viewer */}
          <div>
            <Button
              variant='outline'
              _hover={{ bg: 'brand.500', color: 'white' }}
              borderColor='brand.500'
              color='brand.200'
              h='35px'
              fontSize='xs'
              fontWeight='bold'
              alignSelf={{ sm: 'flex-start', lg: null }}
              mb={{ sm: '6px', md: '6px' }}
              pr='0px'
              pl='7px'
              mt='6px'
              ml='10px'
              leftIcon={<AiOutlineZoomIn size='20px' />}
              onClick={handleZoomIn}
            >
            </Button>
            <Button
              variant='outline'
              _hover={{ bg: 'brand.500', color: 'white' }}
              borderColor='brand.500'
              color='brand.200'
              h='35px'
              fontSize='xs'
              fontWeight='bold'
              alignSelf={{ sm: 'flex-start', lg: null }}
              mb={{ sm: '6px', md: '6px' }}
              pr='0px'
              pl='7px'
              mt='6px'
              ml='10px'
              leftIcon={<AiOutlineZoomOut size='20px' />}
              onClick={handleZoomOut}
            >
            </Button>
          </div>
          <div
            ref={containerRef}
            style={{
              width: '100%',
              // height: '100%',
              position: 'relative',
              // cursor: isDragging ? 'grabbing' : 'grab',
              overflow: 'scroll',  // Allow scrolling if content is too large
              height: '80vh',
              background: '#fff'
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                color: 'black',
                transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
              }}
              dangerouslySetInnerHTML={{ __html: htmlContent }} // Assuming urlPDF contains the HTML content
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentViewer;