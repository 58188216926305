import React from "react";
import { Box, Divider, Text } from "@chakra-ui/react";

const NameList = ({ names, onNameClick }) => {
    const handleClick = (name) => {
        if (onNameClick) {
          onNameClick(name);
        }
    };
  return (
    <Box>
        <Divider/>
        {names.map((name, index) => (
            <Box key={index}
            _hover={{ cursor: "pointer", bg: "brand.500" }}>
                <Text ml='30px' py={2} onClick={() => handleClick(name)}>{name}</Text>
                {index !== names.length - 1 && <Divider/>}
            </Box>
        ))}
      <Divider/>
      {/* <Divider my={2} /> */}
    </Box>
  );
};

export default NameList;