/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState, useReducer, useRef} from "react";

// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Wrap,
  WrapItem,
  Container,
  Checkbox,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Avatar,
  AvatarBadge,
  Stack,
  Grid,
} from "@chakra-ui/react";


import { Link as LinkReact} from "react-router-dom";

// Assets
import signUpImage from "assets/img/signUpImage.png";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";

import { IoIosStar, IoMdShareAlt, IoMdStarHalf } from "react-icons/io";
import { IoDocumentsSharp, IoEllipsisVerticalSharp } from "react-icons/io5";


// Icons
import {
  FaApple,
  FaCube,
  FaFacebook,
  FaPenFancy,
  FaGoogle,
} from "react-icons/fa";
import "../../../assets/css/animated-icons.css"

// import {register} from "../../../network/ApiAxios";
import config from "../../../config";

import { createUserAccount, getAllUserRoleExceptAdminAPI, getOneAboutContentAPI } from '../../../helpers/backend_helper';

// Wizard
import { BsCircleFill } from "react-icons/bs";


function SignUpCover() {

  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";
  const bgPrevButton = "white";

  // Set active bullets based on current state
  const [activeBullets, setActiveBullets] = useState({
    about: true,
    account: false,
    address: false,
  });

  // Tabs references
  const aboutTab = useRef();
  const accountTab = useRef();
  const addressTab = useRef();
  

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setCompany] = useState("");
  const [job, setJob] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("customer");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms_conditions, setCheckbox] = useState(false);
  const [error, setError] = useState("");
  // const [showToast, setShowToast] = useState(false);
  // const [toastMessage, setToastMessage] = useState("Email sent! Check it to reset your password.");
  // const [userID, setUserID] = useState(null);

  // This reducer changes the active button based on the current state
  // const reducer = (state, action) => {
  //   if (action.type === "SWITCH_ACTIVE") {
  //     if (action.payload === "customer") {
  //       const newState = {
  //         customer: true,
  //         staff: false,
  //       };
  //       setUserType("customer");
  //       return newState;
  //     } else if (action.payload === "staff") {
  //       const newState = {
  //         customer: false,
  //         staff: true,
  //       };
  //       setUserType("staff");
  //       return newState;
  //     }
  //   }
  //   return state;
  // };

  // const [state, dispatch] = useReducer(reducer, {
  //   customer: true,
  //   staff: false,
  // });

  const registerUser = async () => {
    if (!(name && surname && company && job && email && phone && userType && password && confirmPassword && terms_conditions==true)) {
        toast({
          title: `Not registered`,
          description: 'Make sure to fill all the inputs and agree to the privacy policy',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
        // setError("Make sure to fill all the inputs and agree to the privacy policy");
        return;
    }
    const registrationValues = {
      'name': name,
      'surname': surname,
      'company': company,
      'job': job,
      'email': email,
      'phone': phone,
      'password': password,
      'confirmPassword': confirmPassword,
      'terms_conditions': terms_conditions
    }

    createUserAccount(registrationValues).then(data => {
      console.log(registrationValues)
      if (data?.status === true) {
          // setIsUserloading(false)
          toast({
            title: `Registration successful`,
            description: data?.message,
            status: 'success',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          })
          // toast.success(data?.message);
          // setTimeout(() => {
          //     router.push('/login')
          // }, 3000);
      } else {
          // setIsUserloading(false)
          toast({
            title: `Not registered`,
            description: data?.message,
            status: 'error',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          })
          // toast.info(data?.message);
      }
    })
    const {data} = response;
    // if (!data.success) {
    //     setError(data.msg);
    //     return;
    // }
    if (config.DEMO) {
        setToastMessage("This is a demo, so we will not send you an email. Instead, click on this link to verify your account:")
        setUserID(data.userID);
    }
    setError("");
    setName("");
    setSurname("");
    setCompany("");
    setJob("");
    setEmail("");
    setPhone("");
    setPassword("");
    setUserType("");
    setConfirmPassword("");
    setCheckbox(false);
    setShowToast(true);

    setOverlay(<OverlayOne />)
    onOpen1()
  };

  // Modals overlay
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(10deg)'
    />
  )
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure()
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const btnRef = React.useRef(null)

  //Phone number validation
  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);

    // Allow only numbers
    if (/[^0-9+]/.test(keyValue)) {
      event.preventDefault();
    }
  };

  // Toast notification
  const toast = useToast()

  return (
    <Flex position='relative' overflow={{ lg: "hidden" }}>
      <Flex
        flexDirection='column'
        h={{ sm: "initial", md: "unset" }}
        w={{ base: "90%" }}
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        pt={{ sm: "100px", md: "0px" }}
        me={{ base: "auto", lg: "50px", xl: "auto" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          flexDirection='column'
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='50px'
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            textAlign='center'
            justifyContent='center'
            align='center'
            mt={{ base: "60px", md: "140px", lg: "200px" }}
            mb='50px'>
            <Text
              fontSize='4xl'
              lineHeight='39px'
              color='white'
              fontWeight='bold'>
              Welcome!
            </Text>
            <Text
              fontSize='md'
              color='white'
              fontWeight='normal'
              mt='10px'
              w={{ base: "100%", md: "90%", lg: "90%", xl: "80%" }}>
              There you can register in our system as a customer or company employee
            </Text>
          </Flex>
          <GradientBorder p='2px' me={{ base: "none", lg: "30px", xl: "none" }}>
            <Flex
              background='transparent'
              borderRadius='30px'
              direction='column'
              p='40px'
              minW={{ base: "unset", md: "430px", xl: "450px" }}
              w='100%'
              mx={{ base: "0px" }}
              bg={{
                base: "rgb(19,21,56)",
              }}>
              <Text
                fontSize='xl'
                color={textColor}
                fontWeight='bold'
                textAlign='center'
                // mb='22px'
                >
                Create your account
              </Text>
              {/* <HStack spacing='15px' justify='center' mb='22px'>
                <GradientBorder borderRadius='15px'>
                  <Flex
                    _hover={{ filter: "brightness(120%)" }}
                    transition='all .25s ease'
                    cursor='pointer'
                    justify='center'
                    align='center'
                    bg='rgb(19,21,54)'
                    w='71px'
                    h='71px'
                    borderRadius='15px'>
                    <Link href='#'>
                      <Icon
                        color={titleColor}
                        as={FaFacebook}
                        w='30px'
                        h='30px'
                        _hover={{ filter: "brightness(120%)" }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
                <GradientBorder borderRadius='15px'>
                  <Flex
                    _hover={{ filter: "brightness(120%)" }}
                    transition='all .25s ease'
                    cursor='pointer'
                    justify='center'
                    align='center'
                    bg='rgb(19,21,54)'
                    w='71px'
                    h='71px'
                    borderRadius='15px'>
                    <Link href='#'>
                      <Icon
                        color={titleColor}
                        as={FaApple}
                        w='30px'
                        h='30px'
                        _hover={{ filter: "brightness(120%)" }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
                <GradientBorder borderRadius='15px'>
                  <Flex
                    _hover={{ filter: "brightness(120%)" }}
                    transition='all .25s ease'
                    cursor='pointer'
                    justify='center'
                    align='center'
                    bg='rgb(19,21,54)'
                    w='71px'
                    h='71px'
                    borderRadius='15px'>
                    <Link href='#'>
                      <Icon
                        color={titleColor}
                        as={FaGoogle}
                        w='30px'
                        h='30px'
                        _hover={{ filter: "brightness(120%)" }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
              </HStack> */}
              {/* <Text
                fontSize='lg'
                color='gray.400'
                fontWeight='bold'
                textAlign='center'
                mb='22px'>
                or
              </Text> */}
              <FormControl>
              <Tabs variant='unstyled' mt='24px' display='flex' flexDirection='column'>
        {" "}
        <TabList display='flex' align='center' justifyContent='center'>
          <Tab
            ref={aboutTab}
            _focus='none'
            w={{ sm: "120px", md: "250px", lg: "80px" }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: false,
                address: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              // _before={{
              //   content: "''",
              //   width: { sm: "120px", md: "250px", lg: "80px" },
              //   height: "3px",
              //   bg: activeBullets.account ? "#fff" : "#182058",
              //   left: { sm: "12px", md: "32px" },
              //   top: { sm: activeBullets.about ? "8px" : "6px", md: null },
              //   position: "absolute",
              //   bottom: activeBullets.about ? "40px" : "38px",
              //   zIndex: 1,
              //   transition: "all .3s ease",
              // }}
              >
              {/* <Icon
                as={BsCircleFill}
                color={activeBullets.about ? "#fff" : "brand.200"}
                w='10px'
                h='10px'
                border={activeBullets.about ? "none" : "1px solid"}
                borderColor={activeBullets.about ? "#fff" : "#182058"}
                borderRadius='50%'
                mb='8px'
              /> */}
            </Flex>
          </Tab>
          <Tab
            ref={accountTab}
            _focus='none'
            w={{ sm: "120px", md: "250px", lg: "80px" }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: true,
                address: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              // _before={{
              //   content: "''",
              //   width: { sm: "120px", md: "250px", lg: "80px" },
              //   height: "3px",
              //   bg: activeBullets.address ? "#fff" : "#182058",
              //   left: { sm: "12px", md: "32px" },
              //   top: { sm: activeBullets.account ? "8px" : "8px", md: null },
              //   position: "absolute",
              //   bottom: activeBullets.account ? "40px" : "38px",
              //   zIndex: 1,
              //   transition: "all .3s ease",
              // }}
              >
              {/* <Icon
                as={BsCircleFill}
                color={activeBullets.account ? "#fff" : "brand.200"}
                w='10px'
                h='10px'
                border={activeBullets.account ? "none" : "1px solid"}
                borderColor={activeBullets.account ? "#fff" : "#182058"}
                borderRadius='50%'
                mb='8px'
              /> */}
            </Flex>
          </Tab>
          <Tab
            ref={addressTab}
            _focus='none'
            w={{ sm: "120px", md: "250px", lg: "80px" }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: true,
                address: true,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'>
              {/* <Icon
                as={BsCircleFill}
                color={activeBullets.address ? "#fff" : "brand.200"}
                w='10px'
                h='10px'
                border={activeBullets.address ? "none" : "1px solid"}
                borderColor={activeBullets.address ? "#fff" : "#182058"}
                borderRadius='50%'
                mb='8px'
              /> */}
            </Flex>
          </Tab>
        </TabList>
        <TabPanels maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel mx='auto'>
                <Flex direction='column' w='100%'>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    w='100%'
                    mb='24px'>
                    <Stack direction='column' spacing='20px' w='100%'>
                      <FormLabel
                        color={titleColor}
                        ms='4px'
                        marginBottom='none'
                        fontSize='sm'
                        fontWeight='normal'>
                        Name
                      </FormLabel>

                      <GradientBorder
                        mb='24px'
                        h='50px'
                        w={{ base: "100%", lg: "fit-content" }}
                        borderRadius='20px'>
                        <Input
                          color={titleColor}
                          bg={{
                            base: "rgb(19,21,54)",
                          }}
                          border='transparent'
                          borderRadius='20px'
                          fontSize='sm'
                          size='lg'
                          w={{ base: "100%", md: "320px" }}
                          maxW='100%'
                          h='46px'
                          type='text'
                          placeholder='Your name'
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </GradientBorder>
                      <FormLabel
                        color={titleColor}
                        ms='4px'
                        fontSize='sm'
                        fontWeight='normal'>
                        Surname
                      </FormLabel>
                      <GradientBorder
                        mb='24px'
                        h='50px'
                        w={{ base: "100%", lg: "fit-content" }}
                        borderRadius='20px'>
                        <Input
                          color={titleColor}
                          bg={{
                            base: "rgb(19,21,54)",
                          }}
                          border='transparent'
                          borderRadius='20px'
                          fontSize='sm'
                          size='lg'
                          w={{ base: "100%", md: "320px" }}
                          maxW='100%'
                          h='46px'
                          type='text'
                          placeholder='Your surname'
                          value={surname}
                          onChange={e => setSurname(e.target.value)}
                        />
                      </GradientBorder>
                      <FormLabel
                        color={titleColor}
                        ms='4px'
                        fontSize='sm'
                        fontWeight='normal'>
                        Email
                      </FormLabel>
                      <GradientBorder
                        mb='24px'
                        h='50px'
                        w={{ base: "100%", lg: "fit-content" }}
                        borderRadius='20px'>
                        <Input
                          color={titleColor}
                          bg={{
                            base: "rgb(19,21,54)",
                          }}
                          border='transparent'
                          borderRadius='20px'
                          fontSize='sm'
                          size='lg'
                          w={{ base: "100%", md: "320px" }}
                          maxW='100%'
                          h='46px'
                          type='email'
                          placeholder='Your email address'
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </GradientBorder>
                    </Stack>
                  </Flex>
                  <Button
                    variant='brand'
                    alignSelf='flex-end'
                    mt='24px'
                    w='100%'
                    maxW='350px'
                    h='35px'
                    onClick={() => accountTab.current.click()}>
                    <Text fontSize='xs' color='#fff' fontWeight='bold'>
                      NEXT
                    </Text>
                  </Button>
                </Flex>
          </TabPanel>
          <TabPanel mx='auto'>
                <Flex direction='column' w='100%'>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    w='100%'
                    mb='24px'>
                    <Stack direction='column' spacing='20px' w='100%'>
                    <FormLabel
                  color={titleColor}
                  ms='4px'
                  marginBottom='none'
                  fontSize='sm'
                  fontWeight='normal'>
                  Company
                </FormLabel>

                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "320px" }}
                    maxW='100%'
                    h='46px'
                    type='text'
                    placeholder='Your company'
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                  />
                </GradientBorder>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='normal'>
                  Job title
                </FormLabel>

                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "320px" }}
                    maxW='100%'
                    h='46px'
                    type='text'
                    placeholder='Your job title'
                    value={job}
                    onChange={e => setJob(e.target.value)}
                  />
                </GradientBorder>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='normal'>
                  Phone number
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "320px" }}
                    maxW='100%'
                    h='46px'
                    type='tel'
                    placeholder='Your phone number'
                    value={phone}
                    onKeyPress={handleKeyPress}
                    onChange={e => setPhone(e.target.value)}
                  />
                </GradientBorder>
                    </Stack>
                  </Flex>

                  <Flex justify='space-between'>
                    <Button
                      variant='no-hover'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'
                      onClick={() => aboutTab.current.click()}>
                      <Text fontSize='xs' color='#313860' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'
                      onClick={() => addressTab.current.click()}>
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        NEXT
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
          </TabPanel>
          <TabPanel mx='auto'>
                <Flex direction='column' w='100%'>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    w='100%'
                    mb='24px'>
                    <Stack direction='column' spacing='20px' w='100%'>
                  <FormLabel
                  color={titleColor}
                  ms='4px'
                  marginBottom='none'
                  fontSize='sm'
                  fontWeight='normal'>
                  Password
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "320px" }}
                    maxW='100%'
                    h='46px'
                    type='password'
                    placeholder='Your password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </GradientBorder>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='normal'>
                  Confirm password
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "320px" }}
                    maxW='100%'
                    h='46px'
                    type='password'
                    placeholder='Password again'
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </GradientBorder>
                  </Stack>
                  </Flex>
                  <Text fontSize='14px' color='#A0AEC0' mt='20px'>
                  <DarkMode>
                    <Checkbox
                      me='10px'
                      colorScheme='brand'
                      borderColor='#292E5D'
                      size='lg'
                      value={terms_conditions}
                      isChecked={terms_conditions}
                      onChange={(e) => {
                        setCheckbox(e.target.checked)
                      }}
                    />
									</DarkMode>
                  <span/>I agree to the{" "}
                  <Link color='white' onClick={() => onOpen2()}>
                    terms and conditions
                  </Link>
                </Text>
                <Flex justify='space-between'>
                    <Button
                      variant='no-hover'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'
                      onClick={() => accountTab.current.click()}>
                      <Text fontSize='xs' color='#313860' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      ml='20px'
                      w='100%'
                      maxW='350px'
                      h='35px'
                      onClick={registerUser}
                      >
                        <Text fontSize='xs' color='#fff' fontWeight='bold'>
                          SIGN UP
                        </Text>
                    </Button>
                  </Flex>
                {/* <Button
                  variant='brand'
                  fontSize='10px'
                  type='submit'
                  w='100%'
                  maxW='350px'
                  h='45'
                  mb='20px'
                  mt='20px'
                  onClick={registerUser}
                  >
                  SIGN UP
                </Button> */}
              </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
                {/* <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='normal'>
                  User Type
                </FormLabel>
                  <Box
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    h='46px'
                    marginTop='10px'
                    marginBottom='20px'>
                  <Flex
                    // direction={{ sm: "column", lg: "row" }}
                    // w={{ sm: "100%", md: "50%", lg: "auto" }}
                    >
                    <Button
                      // left='5%'
                      // marginTop={'3px'}
                      // marginLeft={'10%'}
                      variant='outline'
                      borderColor='brand.500'
                      borderRadius='12px'
                      transition='background .3s ease'
                      bg={state.customer ? "brand.700" : "transparent"}
                      _hover={{
                        bg: "brand.200",
                      }}
                      _active={{
                        bg: "brand.700",
                      }}
                      me={{ base: "none", lg: "20px" }}
                      leftIcon={<Icon color='white' as={FaCube} me='6px' />}
                      onClick={() =>
                        dispatch({ type: "SWITCH_ACTIVE", payload: "customer" })
                      }
                      minW='135px'>
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        CUSTOMER
                      </Text>
                    </Button>
                    <Button
                      // marginTop={'3px'}
                      variant='outline'
                      borderColor='brand.500'
                      borderRadius='12px'
                      transition='background .3s ease'
                      bg={state.staff ? "brand.700" : "transparent"}
                      _hover={{
                        bg: "brand.200",
                      }}
                      _active={{
                        bg: "brand.600",
                      }}
                      me={{ base: "none", lg: "20px" }}
                      leftIcon={<Icon color='white' as={IoDocumentsSharp} me='6px' />}
                      onClick={() =>
                        dispatch({ type: "SWITCH_ACTIVE", payload: "staff" })
                      }
                      minW='135px'>
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        STAFF
                      </Text>
                    </Button>
                  </Flex>
                  </Box> */}
                {/* </GradientBorder> */}
                {/* <FormControl display='flex' alignItems='center' mb='24px'>
                  <DarkMode>
                    <Switch id='remember-login' colorScheme='brand' me='10px' />
                  </DarkMode>

                  <FormLabel
                    color={titleColor}
                    htmlFor='remember-login'
                    mb='0'
                    fontWeight='normal'>
                    Remember me
                  </FormLabel>
                </FormControl> */}
                {/* <span className="form-check-sign" />I agree to the{" "} */}
              </FormControl>
              {/* {error ?
                            <div style={{ color: 'red', fontStyle: 'italic' }}>
                                <small>
                                    Try again:{" "}
                                    <span style={{ color: 'white' }}>{error}</span>
                                </small>
                            </div> : null } */}
              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={textColor} fontWeight='medium'>
                  Already have an account?
                  <Link
                    color={titleColor}
                    ms='5px'
                    href='#'
                    fontWeight='bold'
                    as={LinkReact} to='/auth/authentication/sign-in/cover'>
                    Sign In
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </GradientBorder>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='90px'>
          <AuthFooter />
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX='hidden'
          h='1300px'
          maxW={{ md: "50vw", lg: "48vw" }}
          w='960px'
          position='absolute'
          left='0px'>
          <Box
            bgImage={signUpImage}
            w='100%'
            h='1300px'
            bgSize='cover'
            bgPosition='50%'
            // position='absolute'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            position='absolute'>
            <Text
              textAlign='center'
              color='white'
              letterSpacing='8px'
              fontSize='20px'
              fontWeight='500'>
              INSPIRED BY THE FUTURE:
            </Text>
            <Text
              textAlign='center'
              color='transparent'
              letterSpacing='8px'
              fontSize='36px'
              fontWeight='bold'
              bgClip='text !important'
              bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
              THE AVIA OFFICE DASHBOARD
            </Text>
          </Box>
        </Box>
      </Flex>
      <Modal isCentered isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent bg='#1a202c'>
          <ModalHeader color='white' pb='none'>Thank you for registration!</ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody>
            <Flex gap='2' alignItems='center'>
              <Box pl='2'>
                <div
                  className="swal2-icon swal2-success swal2-animate-success-icon"
                  style={{ display: "flex" }}
                >
                  <div
                    className="swal2-success-circular-line-left"
                    style={{ backgroundColor: "#1a202c" }}
                  />
                  <span className="swal2-success-line-tip" />
                  <span className="swal2-success-line-long" />
                  <div className="swal2-success-ring" />
                  <div
                    className="swal2-success-fix"
                    style={{ backgroundColor: "#1a202c" }}
                  />
                  <div
                    className="swal2-success-circular-line-right"
                    style={{ backgroundColor: "#1a202c" }}
                  />
                </div>
              </Box>
              <Box flex='1' p='2'>
                <Text color='white'>Please wait till our staff will activate your account. It can take from 5 minutes till 24 hours.</Text>
              </Box>
            </Flex>
          </ModalBody>
          {/* <ModalFooter>
            <Button bg="#582CFF" fontSize='12px' color='white' onClick={onClose}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      <Modal
        onClose={onClose2}
        finalFocusRef={btnRef}
        isOpen={isOpen2}
        scrollBehavior='inside'
        size='xl'
      >
        <ModalOverlay />
        <ModalContent bg='#1a202c'>
          <ModalHeader color='white'>Terms and conditions</ModalHeader>
          <ModalCloseButton color='white'/>
          <ModalBody>
            <Container fontSize='14px' color='white'>
              <p>Welcome to JET SOURCE FZE!</p>

              <p>These terms and conditions outline the rules and regulations for the use of Jet Source FZE's Website, located at www.jetsource.ae.</p>

              <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use JET SOURCE FZE if you do not agree to take all of the terms and conditions stated on this page.</p>

              <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

              <h3><strong>Cookies</strong></h3>

              <p>We employ the use of cookies. By accessing JET SOURCE FZE, you agreed to use cookies in agreement with the Jet Source FZE's Privacy Policy. </p>

              <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

              <h3><strong>License</strong></h3>

              <p>Unless otherwise stated, Jet Source FZE and/or its licensors own the intellectual property rights for all material on JET SOURCE FZE. All intellectual property rights are reserved. You may access this from JET SOURCE FZE for your own personal use subjected to restrictions set in these terms and conditions.</p>

              <p>You must not:</p>
              <ul>
                  <li>Republish material from JET SOURCE FZE</li>
                  <li>Sell, rent or sub-license material from JET SOURCE FZE</li>
                  <li>Reproduce, duplicate or copy material from JET SOURCE FZE</li>
                  <li>Redistribute content from JET SOURCE FZE</li>
              </ul>

              <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the <a href="https://www.termsandconditionsgenerator.com/">Free Terms and Conditions Generator</a>.</p>

              <p>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Jet Source FZE does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Jet Source FZE,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Jet Source FZE shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>

              <p>Jet Source FZE reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

              <p>You warrant and represent that:</p>

              <ul>
                  <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                  <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                  <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                  <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
              </ul>

              <p>You hereby grant Jet Source FZE a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

              <h3><strong>Hyperlinking to our Content</strong></h3>

              <p>The following organizations may link to our Website without prior written approval:</p>

              <ul>
                  <li>Government agencies;</li>
                  <li>Search engines;</li>
                  <li>News organizations;</li>
                  <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                  <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
              </ul>

              <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

              <p>We may consider and approve other link requests from the following types of organizations:</p>

              <ul>
                  <li>commonly-known consumer and/or business information sources;</li>
                  <li>dot.com community sites;</li>
                  <li>associations or other groups representing charities;</li>
                  <li>online directory distributors;</li>
                  <li>internet portals;</li>
                  <li>accounting, law and consulting firms; and</li>
                  <li>educational institutions and trade associations.</li>
              </ul>

              <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Jet Source FZE; and (d) the link is in the context of general resource information.</p>

              <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

              <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Jet Source FZE. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

              <p>Approved organizations may hyperlink to our Website as follows:</p>

              <ul>
                  <li>By use of our corporate name; or</li>
                  <li>By use of the uniform resource locator being linked to; or</li>
                  <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
              </ul>

              <p>No use of Jet Source FZE's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

              <h3><strong>iFrames</strong></h3>

              <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

              <h3><strong>Content Liability</strong></h3>

              <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

              <h3><strong>Your Privacy</strong></h3>

              <p>Please read Privacy Policy</p>

              <h3><strong>Reservation of Rights</strong></h3>

              <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

              <h3><strong>Removal of links from our website</strong></h3>

              <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

              <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

              <h3><strong>Disclaimer</strong></h3>

              <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

              <ul>
                  <li>limit or exclude our or your liability for death or personal injury;</li>
                  <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                  <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                  <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
              </ul>

              <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

              <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button variant='brand' mr='10px' bg="#582CFF" fontSize='12px' color='white' onClick={onClose2}>Agree</Button>
            <Button variant='outline' _hover={{bg: 'brand.300'}} borderColor='#582CFF' fontSize='12px' color='white' onClick={onClose2}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  </Flex>
  );
}

export default SignUpCover;
