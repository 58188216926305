/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
import { useHistory } from "react-router-dom";

// Chakra imports
import { 
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Spacer,
  SimpleGrid,
  Text,
  Wrap,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

// Images
import BackgroundCard1 from "assets/img/billing-background-card.png";

// My Images
import bgimage1 from 'assets/img/programs/forex-stock-market-trade-concept-trading-floor-board-with-currency-exchange-rates-and-graph_r0glflrmg_thumbnail-1080_01.png'
import bgimage2 from 'assets/img/programs/istockphoto-1085649282-612x612.jpg'
import bgimage3 from 'assets/img/programs/document_shutterstock_1416401996.jpg'
import bgimage4 from 'assets/img/programs/3073697-account_black-and-white_business_commerce_data_documents_files_finance_information_list_numbers_office_papers_research.jpg'
import bgimage5 from 'assets/img/programs/addJobCards.jpg'

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GradientBorder from "components/GradientBorder/GradientBorder";
import IconBox from "components/Icons/IconBox";
import BillingRow from "components/Tables/BillingRow";
import InvoicesRow from "components/Tables/InvoicesRow";
import TransactionRow from "components/Tables/TransactionRow";

// My components
import medusa from "assets/img/cardimgfree.png";
import { BsArrowRight } from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import { AiFillSave, AiOutlineSave } from "react-icons/ai";
// import PartsPrograms from "components/Parts/PartsPrograms";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import ProgramCard from "components/Card/ProgramCard.js";
// import ProgramAddPopup from "components/Popups/ProgramAddPopup.js";
import FadeIn from 'react-fade-in';

// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';

// Icons
import { FaPencilAlt, FaRegCalendarAlt, FaCube } from "react-icons/fa";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { RiMastercardFill } from "react-icons/ri";
import {
  BillIcon,
  GraphIcon,
  MastercardIcon,
  VisaIcon,
} from "components/Icons/Icons";
import "../../assets/css/animated-icons.css"

// import ProgramsPopupA from "components/Popups/ProgramsPopupA.js";

// Data
import {
  billingData,
  invoicesData,
  newestTransactions,
  olderTransactions,
} from "variables/general";

// ApiAxios
import { activateProgram } from "network/ApiAxios";

// Modals
import ProgramsAdd from "components/Programs/ProgramsAdd";
import ProgramsManage from "components/Programs/ProgramsManage";
// import { Navigate } from "react-big-calendar";

const Programs = () => {
  const history = useHistory();
  // let userId = JSON.parse(localStorage.getItem("user"))._id;
  let userId = '2313123'
    // Modals overlay
    const OverlayOne = () => (
      <ModalOverlay
        // bg='blackAlpha.300'
        bg='rgba(0, 0, 0, 0.8)'
        // opacity='0.2'
        // bg='none'
        // backdropFilter='auto'
        // backdropInvert='80%'
        // backdropBlur='2px'
        backdropFilter='hue-rotate(10deg)'
        // WebkitFilter='blur(10px)'
        // backdropFilter='blur(10px) hue-rotate(10deg)'
        
      >
      {/* <BackdropFilter
        className="bluredForm"
        filter={"blur(10px) sepia(50%)"}></BackdropFilter> */}
      </ModalOverlay>
    )
    const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure()
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const btnRef = React.useRef(null)
  
    const activateProgram2 = async (programId) => {
      const { data } = await activateProgram(userId, programId);
      console.log('test2')
      console.log(data)
    };

  function getTitle(title) {
    // console.log('test')
    switch(title) {
      case "Oanda":
        // setIsPreview(<ProgramAddPopup></ProgramAddPopup>)
        // setIsPreview(<ProgramsPopupA title={name}></ProgramsPopupA>)
        // let programId = '00001'
        // activateProgram2(programId)
        // console.log(title)
        break;
      case "Parts search":
        history.push('/admin/other/PartsPrices')
        // setIsPreview(<ProgramsPopupA title={name}></ProgramsPopupA>)
        break;
      case "Revisions":
        history.push('/admin/other/MaintenanceData')
        // setIsPreview(<ProgramsPopupA title={name}></ProgramsPopupA>)
        break;
      case "Manuals":
        history.push('/admin/other/Manuals')
        // setIsPreview(<ProgramsPopupA title={name}></ProgramsPopupA>)
        break;
    }
    // if (name=="Oanda") {
    //   setIsPreview(<ProgramAddPopup></ProgramAddPopup>)
    // }
  }
 
  const programsArray = [
    {
      'title': "Oanda",
      'subtitle': "Currency converter",
      'background': bgimage1,
    },
    {
      'title': "Parts search",
      'subtitle': "Find parts prices",
      'background': bgimage2,
    },
    {
      'title': "Revisions",
      'subtitle': "Update revisions",
      'background': bgimage3,
    },
    {
      'title': "Manuals",
      'subtitle': "Download manuals",
      'background': bgimage4,
    },
    {
      'title': "Add Job Cards",
      'subtitle': "Add tasks to Blue Eye",
      'background': bgimage5,
    },
  ]
 
  // const programsArray = {
  //   program1: {
  //     'title': "Oanda",
  //     'subtitle': "Currency converter",
  //     'text': "Click to start",
  //     'background': bgimage1,
  //   },
  //   program2: {
  //     'title': "Parts search",
  //     'subtitle': "Find parts prices",
  //     'text': "Click to start",
  //     'background': bgimage2,
  //   },
  //   program3: {
  //     'title': "Revisions",
  //     'subtitle': "Update revisions",
  //     AMM'text': "Click to start",
  //     'background': bgimage3,
  //   },
  //   program4: {
  //     'title': "AMM",
  //     'subtitle': "Download manuals",
  //     'text': "Click to start",
  //     'background': bgimage4,
  //   },
  // }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }} mx='auto'>
      {/* <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px'> */}
      <SimpleGrid columns={1} spacing='24px'>
        <Box>
          {/* Payment Method */}
          
          <Card
          p='0px'
          gridArea={{ md: "1 / 1 / 2 / 3", "2xl": "auto" }}
          bgImage={medusa}
          bgSize='cover'
          bgPosition='50%'
          my='20px'>

          <CardBody w='100%' h='100%'>
            <Flex flexDirection={{ sm: "column", lg: "row" }} w='100%' h='100%'>
              <Flex
                flexDirection='column'
                h='100%'
                p='22px'
                minW='60%'
                lineHeight='1.6'>
                <Text fontSize='sm' color='gray.400' fontWeight='bold'>
                  Activate programs manually
                </Text>
                <Text fontSize='28px' color='#fff' fontWeight='bold' mb='18px'>
                  Programs
                </Text>
                {/* <Text
                  fontSize='12px'
                  color='gray.400'
                  fontWeight='normal'
                  mb='15px'>
                  Download exchangerate to Excel <br />
                </Text> */}
                <Spacer />
                {/* <Flex align='center'>
                  <Button
                    p='0px'
                    variant='no-hover'
                    bg='transparent'
                    my={{ sm: "1.5rem", lg: "0px" }}>
                    <Text
                      fontSize='12px'
                      color='#fff'
                      fontWeight='bold'
                      cursor='pointer'
                      transition='all .3s ease'
                      my={{ sm: "1.5rem", lg: "0px" }}
                      _hover={{ me: "4px" }}>
                      Instruction
                    </Text>
                    <Icon
                      as={BsArrowRight}
                      w='20px'
                      h='20px'
                      color='#fff'
                      fontSize='2xl'
                      transition='all .3s ease'
                      mx='.3rem'
                      cursor='pointer'
                      pt='4px'
                      _hover={{ transform: "translateX(20%)" }}
                    />
                  </Button>
                </Flex> */}
              </Flex>
              <Flex justify='right'
              align='top'
              minHeight='20px'
              w='100%'
              m='20px'>
                <Button
                  variant='brand'
                  // w='150px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "0px" }}
                  mt='58px'
                  onClick={() => {
                    setOverlay(<OverlayOne />)
                    onOpen1()
                  }}>
                  ADD NEW PROGRAM
                </Button>
                <Button
                  variant='brand'
                  // w='125px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "0px" }}
                  ml='12px'
                  mt='58px'
                  onClick={() => {
                    setOverlay(<OverlayOne />)
                    onOpen2()
                  }}>
                  MANAGE PROGRAMS
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
        </Box>
        {/* Invoices List */}
        </SimpleGrid>
        <Wrap spacing='2%'>
        {/* <SimpleGrid columns={4} spacing={10}> */}
        {/* <Flex justify='space-around'
              align='center'
              minHeight='60px'
              w='100%'
              flexWrap='wrap'> */}
          {programsArray.map((programItem) => (
            <div onClick={() => getTitle(programItem.title)}>
              <ProgramCard title={programItem.title} subtitle={programItem.subtitle} text='Click to start' background={programItem.background} onClick={() => getTitle(programItem.title)}></ProgramCard>
            </div>
          ))}
          {/* <div onClick={() => getTitle('Oanda')}>
            <ProgramCard title='Oanda' subtitle='Currency converter' text='Test text' background={bgimage1}></ProgramCard>
          </div> */}
          {/* <ProgramCard title='Oanda' subtitle='Currency converter' text='Test text' background={bgimage1} onClick={getTitle('Oanda')}></ProgramCard>
          <ProgramCard title='Parts finder' subtitle='Currency converter' text='Test text' background={bgimage2} onClick={getTitle()}></ProgramCard>
          <ProgramCard title='Manuals parser' subtitle='Currency converter' text='Test text' background={bgimage3} onClick={getTitle()}></ProgramCard>
          <ProgramCard title='Revisions update' subtitle='Currency converter' text='Test text' background={bgimage4} onClick={getTitle()}></ProgramCard>
          <ProgramCard title='Revisions update' subtitle='Currency converter' text='Test text' background={bgimage4} onClick={getTitle()}></ProgramCard>
          <ProgramCard title='Revisions update' subtitle='Currency converter' text='Test text' background={bgimage4} onClick={getTitle()}></ProgramCard>
          <ProgramCard title='Revisions update' subtitle='Currency converter' text='Test text' background={bgimage4} onClick={getTitle()}></ProgramCard> */}
        {/* </Flex> */}
        {/* </SimpleGrid> */}
        </Wrap>
      
      {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      {/* <div>{isPreview}</div> */}
      {/* ADD NEW PROGRAM */}
        <Modal size='6xl' isOpen={isOpen1} onClose={onClose1} >
        {overlay}
        <ModalContent bg='#1a202c'>
          <ModalHeader color='white' pb='none' p='0px'>
          <Box
          // mt='20px'
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				// px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				{/* Header */}
				<Card
					direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					justifyContent={{ sm: 'center', md: 'space-between' }}
					align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
					<Flex align='center' direction={{ sm: 'column', md: 'row' }}>
						<Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
						{/* <Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}> */}
							<Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
								<Text
                  align='top'
									fontSize={{ sm: 'lg', lg: 'xl' }}
									color='#fff'
									fontWeight='bold'
									ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
									Add new program
								</Text>
								{/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
							</Flex>
						</Flex>
						<Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiFillSave size='20px' />}
                onClick={() => {
                  setOverlay(<OverlayOne />)
                  onOpen1()
                }}>
                SAVE
              </Button>
              <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  setOverlay(<OverlayOne />)
                  onOpen1()
                }}>
                RESET
              </Button>
						</Flex>
					</Flex>
				</Card>
			</Box>
          </ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody color='white'>

                <ProgramsAdd></ProgramsAdd>

          </ModalBody>
          {/* <ModalFooter>
            <Button variant='brand' mr='10px' bg="#582CFF" fontSize='12px' color='white' onClick={onClose1}>Agree</Button>
            <Button variant='outline' _hover={{bg: 'brand.300'}}  borderColor='#582CFF' fontSize='12px' color='white' onClick={onClose1}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      {/* MANAGE PROGRAMS */}
        <Modal size='4xl' isOpen={isOpen2} onClose={onClose2} >
        {overlay}
        <ModalContent bg='#1a202c'>
        <ModalHeader color='white' pb='none' p='0px'>
          <Box
          // mt='20px'
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				// px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				{/* Header */}
				<Card
					direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					justifyContent={{ sm: 'center', md: 'space-between' }}
					align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
					<Flex align='center' direction={{ sm: 'column', md: 'row' }}>
						<Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
						{/* <Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}> */}
							<Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
								<Text
                  align='top'
									fontSize={{ sm: 'lg', lg: 'xl' }}
									color='#fff'
									fontWeight='bold'
									ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
									Manage programs
								</Text>
								{/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
							</Flex>
						</Flex>
						<Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiFillSave size='20px' />}
                onClick={() => {
                  setOverlay(<OverlayOne />)
                  onOpen1()
                }}>
                SAVE
              </Button>
              <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  setOverlay(<OverlayOne />)
                  onOpen1()
                }}>
                RESET
              </Button>
						</Flex>
					</Flex>
				</Card>
			</Box>
          </ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody color='white'>
            <Flex gap='2' alignItems='center'>
                <ProgramsManage></ProgramsManage>
            </Flex>
          </ModalBody>
          <ModalFooter>
            {/* <Button bg="#582CFF" fontSize='12px' color='white'>Close</Button> */}
            <Button
                variant='band'
                _hover={{bg: 'brand.500'}}
                bg='#582CFF'
                color='white'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                // pl='1px'
                onClick={() => {
                  setOverlay(<OverlayOne />)
                  onOpen2()
                }}>
                {/* <Icon
                  as={BiReset}
                  // borderColor='#582CFF'
                  // color='#ddd4ff'
                  w='20px'
                  h='20px'
                  // color='#fff'
                  fontSize='2xl'
                  // transition='all .3s ease'
                  mr='.3rem'
                  cursor='pointer'
                  // pt='4px'
                  // verticalAlign='middle'
                  // _hover={{color: 'white'}}
                  // _hover={{ transform: "translateX(20%)" }}
                /> */}
                SAVE
              </Button>
            <Button
                variant='band'
                _hover={{bg: 'brand.500'}}
                bg='gray.700'
                color='white'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                // pl='1px'
                onClick={() => {
                  setOverlay(<OverlayOne />)
                  onOpen2()
                }}>
                {/* <Icon
                  as={BiReset}
                  // borderColor='#582CFF'
                  // color='#ddd4ff'
                  w='20px'
                  h='20px'
                  // color='#fff'
                  fontSize='2xl'
                  // transition='all .3s ease'
                  mr='.3rem'
                  cursor='pointer'
                  // pt='4px'
                  // verticalAlign='middle'
                  // _hover={{color: 'white'}}
                  // _hover={{ transform: "translateX(20%)" }}
                /> */}
                CLOSE
              </Button>
          </ModalFooter>
          {/* <ModalFooter>
            <Button variant='brand' mr='10px' bg="#582CFF" fontSize='12px' color='white' onClick={onClose1}>Agree</Button>
            <Button variant='outline' _hover={{bg: 'brand.300'}}  borderColor='#582CFF' fontSize='12px' color='white' onClick={onClose1}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Programs;
