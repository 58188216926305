/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Badge,
  Box,
  Button,
  DarkMode,
  Flex,
  Grid,
  Icon,
  Image,
  Spacer,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";

// Images
import productImage1 from "assets/img/product-page-1.png";
import bgimage1 from 'assets/img/programs/forex-stock-market-trade-concept-trading-floor-board-with-currency-exchange-rates-and-graph_r0glflrmg_thumbnail-1080_01.png'

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconBox from "components/Icons/IconBox";
import { HSeparator } from "components/Separator/Separator";

// Icons
import { MastercardIcon } from "components/Icons/Icons";
import { CgShapeCircle } from "react-icons/cg";
import { FaPencilAlt } from "react-icons/fa";

function ProgramsListItem(props) {
  const { title, subtitle, background, description, tag, roles, departments } = props;
  // Chakra color mode
  const textColor = "white";
  const bgColor =
    "linear-gradient(126.97deg, rgba(6, 11, 40, 0.74) 28.26%, rgba(10, 14, 35, 0.71) 91.2%)";
  const nameColor = "white";

  return (
    <>
            {/* <HSeparator my='22px' /> */}
            <Flex
              direction={{ sm: "column", md: "row" }}
              justify='space-between'
              w='100%'
              align='center'>
              <Flex align='center'>
                <Box
                  minW='110px'
                  h='110px'
                  me='30px'
                  alignSelf={{ sm: "flex-start", md: "auto" }}>
                  <Image
                    src={bgimage1}
                    w='128px'
                    h='110px'
                    borderRadius='12px'
                  />
                </Box>
                <Flex direction='column'>
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='6px'>
                    {title}
                  </Text>
                  <Text
                    color='gray.400'
                    fontSize='sm'
                    fontWeight='500'
                    mb='16px'>
                    {description}
                  </Text>
                  <Badge
                    color='#01B574'
                    bg='#C9FBD5'
                    w='95px'
                    h='28px'
                    borderRadius='8px'
                    display='flex'
                    alignItems='center'
                    alignSelf={{ sm: "center", md: "auto" }}
                    justifyContent='center'
                    mb={{ sm: "6px", md: "0px" }}>
                    AVAILABLE
                  </Badge>
                </Flex>
              </Flex>
              <Flex direction='column' align='flex-end'>
                {/* <Button
                  variant='no-hover'
                  bg='brand.200'
                  w='125px'
                  h='35px'
                  color='#fff'
                  fontSize='10px'
                  fontWeight='bold'
                  borderRadius='12px'
                  mb={{ sm: "12px", md: "36px" }}>
                  
                </Button> */}
                <Badge
                    color='#fff'
                    bg='brand.200'
                    w='125px'
                    h='30px'
                    borderRadius='8px'
                    fontSize='10px'
                    fontWeight='bold'
                    display='flex'
                    alignItems='center'
                    alignSelf={{ sm: "center", md: "auto" }}
                    justifyContent='center'
                    mb={{ sm: "12px", md: "36px" }}>
                    {tag}
                </Badge>
                <Flex>
                    <Text mr='10px' color='gray.400' fontSize='xs' fontWeight='500'>
                      Show in my programs
                    </Text>
                    <DarkMode>
                      <Switch  colorScheme='brand' defaultChecked />
                    </DarkMode>
                </Flex>
              </Flex>
            </Flex>
            <HSeparator my='22px' />
          </>
  );
}

export default ProgramsListItem;
