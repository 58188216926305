/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const PanelContainer = {
  baseStyle: {
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)",
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
