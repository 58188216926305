/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Profile addon
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  MenuGroup,
  MenuDivider,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";

// import {logout} from "../../network/ApiAxios";

import { verifyUserAPI } from '../../helpers/backend_helper';

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  //get user data
  const [user, setUser] = useState({});
  useEffect(() => {
      verifyUserAPI().then((userData) => {
          if (userData?.status === true) {
              setUser(userData?.data)
              console.log(userData.data)
          }
      })
  }, [])

  let name = `${user.name} ${user.surname}`
  console.log(user)
  // let username = user
  // let avatar14 = 'https://manifest-customs.s3.eu-central-1.amazonaws.com/Taxstick-storage/artjoms.kalinka%40gmail.com/c352ce6514b1cdd8-comete-coco.jpg'
  // let username = JSON.parse(localStorage.getItem("user")).name;
  // let userAvatar = JSON.parse(localStorage.getItem("user")).avatar;
  // let avatar13 = userAvatar.replace('assets/img/avatars/', '')
  // let avatar14 = avatar13.replace('.jpg', '')
  let history = useHistory()
  const logOut = async () => {
      const token = localStorage.getItem("taxstickToken");
      if (token) {
          // const response = await logout(token);
          // const { data } = response;
          // console.log(response)
          // if (data.success) {
          //     localStorage.removeItem("token");
          //     localStorage.removeItem("user");
          //     history.push("/auth/signin");
          // }
          localStorage.removeItem("taxstickToken");
          // localStorage.removeItem("user");
          history.push("/auth/signin/cover");
      }
  }

  // Chakra Color Mode
  let mainBrand = "white";
  let inputBg = "#0F1535";
  let mainText = "white";
  let navbarIcon = "white";
  let searchIcon = "white";

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'>
      <InputGroup
        color='gray.400'
        // bg='#0F1535'
        border='0.5px solid'
        borderColor='#E2E8F04D'
        cursor='pointer'
        bg={inputBg}
        borderRadius='15px'
        w={{
          sm: "128px",
          md: "200px",
        }}
        me={{ sm: "auto", md: "20px" }}
        _focus={{
          borderColor: { mainBrand },
        }}
        _active={{
          borderColor: { mainBrand },
        }}>
        <InputLeftElement
          children={
            <IconButton
              bg='inherit'
              borderRadius='inherit'
              _hover='none'
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={
                <SearchIcon color={searchIcon} w='15px' h='15px' />
              }></IconButton>
          }
        />
        <Input
          fontSize='xs'
          py='11px'
          color={mainText}
          placeholder='Type here...'
          borderRadius='inherit'
        />
      </InputGroup>
      {/* <NavLink to='/auth/signin'> */}
        {/* <Button
          ms='0px'
          px='0px'
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant='transparent-with-icon'
          leftIcon={
            <ProfileIcon
              color={navbarIcon}
              w='22px'
              color='white'
              h='22px'
              me='0px'
            />
          }>
          <Text display={{ sm: "none", md: "flex" }} color='white'>
            Sign In
          </Text>
        </Button> */}
      {/* </NavLink> */}
      <Menu>
        <MenuButton
          ms='0px'
          px='0px'
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          // variant='transparent-with-icon'
          // rightIcon={<ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />}
          >
        <div style={{ display: 'flex', flexDirection: 'row'}}>
          {/* <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' /> */}
          <Avatar
            // src={require(`assets/img/avatars/${avatar3}.jpg`)}
            src={user.profile_img}
            w='30px'
            h='30px'
            me='0px'
            borderRadius='15px'
            opacity='90%'
            background='none'>
          </Avatar>
          {/* <Avatar name='Dan Abrahmov' src={userAvatar} w='30px' h='30px' me='0px' background='none' /> */}
          <Text display={{ sm: "none", md: "flex" }} marginLeft='10px'>{name}</Text>
        </div>
        </MenuButton>
        <MenuList
          bgColor={inputBg}
          color='#ffffff'
          borderColor="#303030"
          borderRadius='0.6rem'
          >
          {/* <NavLink to='/admin/profile'><MenuItem _hover={{bg: '#3f20b3'}} _focus={{bg: '#3f20b3'}} fontSize='14px' onClick={() => history.push("/admin/pages/profile/overview")}> <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' marginRight='4px'/>My profile</MenuItem></NavLink> */}
          <MenuItem _hover={{bg: '#3f20b3'}} _focus={{bg: '#3f20b3'}} fontSize='14px' onClick={() => history.push("/admin/pages/profile/settings")}>Settings</MenuItem>
          <MenuItem _hover={{bg: '#3f20b3'}} _focus={{bg: '#3f20b3'}} fontSize='14px'>Activity</MenuItem>
          <MenuItem _hover={{bg: '#3f20b3'}} _focus={{bg: '#3f20b3'}} fontSize='14px'>FAQ</MenuItem>
          <MenuDivider />
          <MenuItem _hover={{bg: '#3f20b3'}} _focus={{bg: '#3f20b3'}} fontSize='14px' onClick={logOut}>Logout</MenuItem>
        </MenuList>
      </Menu>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
      <SettingsIcon
        cursor='pointer'
        me='16px'
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w='18px'
        h='18px'
      />
      <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w='18px' h='18px' />
        </MenuButton>
        <MenuList
          border='transparent'
          backdropFilter='blur(63px)'
          bg='linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)'
          borderRadius='20px'
          p='16px 8px'>
          <Flex flexDirection='column'>
            <MenuItem
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              borderRadius='8px'
              mb='10px'>
              <ItemContent
                time='13 minutes ago'
                info='from Alicia'
                boldInfo='New Message'
                aName='Alicia'
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              borderRadius='8px'
              mb='10px'>
              <ItemContent
                time='2 days ago'
                info='by Josh Henry'
                boldInfo='New Album'
                aName='Josh Henry'
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem
              _hover={{
                bg: "transparent",
              }}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              borderRadius='8px'>
              <ItemContent
                time='3 days ago'
                info='Payment succesfully completed!'
                boldInfo=''
                aName='Kara'
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
