/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Switch,
  Table,
  Tag,
  TagCloseButton,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  LightMode,
  DarkMode,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Spinner
} from "@chakra-ui/react";

// Images
import avatar11 from "assets/img/avatars/avatar11.png";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import { Element, Link } from "react-scroll";

import AvatarDragAndDrop from "components/Modals/AvatarDragAndDrop.js"

// Icons
import {
  AtlassianLogo,
  InvisionLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons";

import "../../../assets/css/animated-icons.css"

import { AiFillDelete } from "react-icons/ai";
import { BsArrowRight, BsCircleFill, BsToggleOn } from "react-icons/bs";
import { FaCube, FaUser, FaPencilAlt } from "react-icons/fa";
import { GiSmartphone } from "react-icons/gi";
import { IoIosArrowUp, IoIosRocket, IoMdNotifications } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { MdPowerSettingsNew } from "react-icons/md";
import { RiComputerLine, RiTabletLine } from "react-icons/ri";

import { verifyUserAPI, editUserAPI, userProfilePasswordChangeAPI } from '../../../helpers/backend_helper';

import IPAddress from "components/Network/IPAddress";


function Settings() {
  const bgActiveButton = "brand.200";
  const bgHoverLinks = "brand.200";
  const bgVerificationCard = "#0F1535";

  const toast = useToast()

  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [company, setCompany] = useState('');
  const [job, setJob] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [profileImg, setProfileImg] = useState('');


  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [userDeviceType, setUserDeviceType] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
      setIsLoading(true)
      verifyUserAPI().then((userData) => {
          if (userData?.status === true) {
              setUserId(userData?.data._id)
              setName(userData?.data.name)
              setSurname(userData?.data.surname)
              setCompany(userData?.data.company)
              setJob(userData?.data.job)
              setPhone(userData?.data.phone)
              setEmail(userData?.data.email)
              setProfileImg(userData?.data.profile_img)
              setUserDeviceType(getDeviceType())
              console.log(userData.data)
              setIsLoading(false)
          }
          else {
            setIsLoading(false)
          }
      })
  }, [])

  // Profile data
  // let username = JSON.parse(localStorage.getItem("user")).name;
  // let email = JSON.parse(localStorage.getItem("user")).email;
  // let userAvatar = JSON.parse(localStorage.getItem("user")).avatar;
  // let avatar13 = userAvatar.replace('assets/img/avatars/', '')
  // let avatar14 = avatar13.replace('.jpg', '')

  // Modals overlay
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(10deg)'
    />
  )
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)
  const btnRef = React.useRef(null)

  // sets the active button based on the current state
  const [activeButtons, setActiveButtons] = useState({
    messages: true,
    social: false,
    notifications: false,
    backup: false,
  });

  const [skills, setSkills] = useState([
    {
      name: "chakra-ui",
      id: 1,
    },
    {
      name: "react",
      id: 2,
    },
    {
      name: "javascript",
      id: 3,
    },
  ]);

  const [toggle, setToggle] = useState(false);

  // Creates the skills in the text area
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setSkills([
        ...skills,
        {
          name: e.target.value,
          id: skills.length === 0 ? 1 : skills[skills.length - 1].id + 1,
        },
      ]);
      e.target.value = "";
    }
  };

  const updateProfileFunc = (userId, name, surname, email, phone, company, job) => {
    console.log("test3")
    setIsLoading(true)
    let profileInfoArray = {name, surname, email, phone, company, job}
    const queryValue = { userId }
    // let dataB = Object.assign({}, dataA, {manualPathA: manualPathA, manualMdPathA: manualMdPathA})
    editUserAPI(profileInfoArray, queryValue).then(data => {
      if (data?.status === true) {
        setIsLoading(false)
        toast({
          title: `Profile updated`,
          // description: 'Please check connection to the external server',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        setIsLoading(false)
        toast({
          title: `Failed to update profile`,
          description: 'Please try again later or contact technical support',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  const updatePasswordFunc = (userId, currentPassword, password, confirmPassword) => {
    console.log("test3")
    setIsLoading(true)
    let passwordsArray = {userId, currentPassword, password, confirmPassword}
    // let dataB = Object.assign({}, dataA, {manualPathA: manualPathA, manualMdPathA: manualMdPathA})
    userProfilePasswordChangeAPI(passwordsArray).then(data => {
      if (data?.status === true) {
        setIsLoading(false)
        toast({
          title: `Password changed`,
          // description: 'Please check connection to the external server',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else if (data?.message == "New passwords dont match. Please check again!"){
        setIsLoading(false)
        toast({
          title: `Failed to change password`,
          description: "New passwords dont match. Please check again!",
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else if (data?.message == "Please fill all password fields!"){
        setIsLoading(false)
        toast({
          title: `Failed to change password`,
          description: "Please fill all password fields!",
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        setIsLoading(false)
        toast({
          title: `Failed to change password`,
          description: "Wrong current password!",
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  const getDeviceType = () => {
    const userAgent = navigator.userAgent;
    console.log(userAgent)
    if (/Mobi|Android/i.test(userAgent)) {
      return 'Mobile';
    } else if (/iPad|Tablet/i.test(userAgent)) {
      return 'Tablet';
    } else {
      return 'PC';
    }
  };

  // const updateProfileFunc = (userId, name, surname, email, phone, company, job) => {
  //   let profileInfoArray = {userId, name, surname, email, phone, company, job}
  //   editUserAPI(profileInfoArray, queryValue)
  //   // console.log(userId)
  // } 

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Box w='100%'>
        {/* Buttons */}
        <Stack
          direction={{ sm: "column", lg: "row" }}
          spacing={{ sm: "8px", lg: "38px" }}
          w={{ sm: "100%", lg: null }}>
          <Button
            borderRadius='12px'
            boxShadow={
              activeButtons.messages
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none"
            }
            bg={activeButtons.messages ? bgActiveButton : "transparent"}
            transition='all .5s ease'
            w={{ sm: "100%", lg: "135px" }}
            h='35px'
            _hover='none'
            _focus={{ boxShadow: "0px 2px 5.5px rgba(0, 0, 0, 0.06)" }}
            _active={{
              boxShadow: activeButtons.messages
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none",
            }}
            onClick={() =>
              setActiveButtons({
                messages: true,
                social: false,
                notifications: false,
                backup: false,
              })
            }>
            <Text color='#fff' fontSize='10px' fontWeight='bold'>
              MESSAGES
            </Text>
          </Button>
          <Button
            borderRadius='12px'
            boxShadow={
              activeButtons.social
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none"
            }
            bg={activeButtons.social ? bgActiveButton : "transparent"}
            transition='all .5s ease'
            w={{ sm: "100%", lg: "135px" }}
            h='35px'
            _hover='none'
            _focus={{ boxShadow: "0px 2px 5.5px rgba(0, 0, 0, 0.06)" }}
            _active={{
              boxShadow: activeButtons.social
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none",
            }}
            onClick={() =>
              setActiveButtons({
                messages: false,
                social: true,
                notifications: false,
                backup: false,
              })
            }>
            <Text color='#fff' fontSize='10px' fontWeight='bold'>
              SOCIAL
            </Text>
          </Button>
          <Button
            borderRadius='12px'
            boxShadow={
              activeButtons.notifications
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none"
            }
            bg={activeButtons.notifications ? bgActiveButton : "transparent"}
            transition='all .5s ease'
            w={{ sm: "100%", lg: "135px" }}
            h='35px'
            _hover='none'
            _focus={{ boxShadow: "0px 2px 5.5px rgba(0, 0, 0, 0.06)" }}
            _active={{
              boxShadow: activeButtons.notifications
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none",
            }}
            onClick={() =>
              setActiveButtons({
                messages: false,
                social: false,
                notifications: true,
                backup: false,
              })
            }>
            <Text color='#fff' fontSize='10px' fontWeight='bold'>
              NOTIFICATIONS
            </Text>
          </Button>
          <Button
            borderRadius='12px'
            boxShadow={
              activeButtons.backup
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none"
            }
            bg={activeButtons.backup ? bgActiveButton : "transparent"}
            transition='all .5s ease'
            w={{ sm: "100%", lg: "135px" }}
            h='35px'
            _hover='none'
            _focus={{ boxShadow: "0px 2px 5.5px rgba(0, 0, 0, 0.06)" }}
            _active={{
              boxShadow: activeButtons.backup
                ? "0px 2px 5.5px rgba(0, 0, 0, 0.06)"
                : "none",
            }}
            onClick={() =>
              setActiveButtons({
                messages: false,
                social: false,
                notifications: false,
                backup: true,
              })
            }>
            <Text color='#fff' fontSize='10px' fontWeight='bold'>
              BACKUP
            </Text>
          </Button>
        </Stack>
      </Box>
      {/* Menu */}
      <Card
        w={{ sm: "100%", lg: "262px", xl: "21%", "2xl": "23.4%" }}
        mt={{ sm: "30px", lg: "0px" }}
        position={{ lg: "fixed" }}
        top={{ lg: "180px" }}>
        <CardBody>
          <Stack direction='column' spacing='8px' w='100%' color='gray.500'>
            <Link to='profile' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoIosRocket}
                    me='12px'
                    w='18px'
                    h='18px'
                    color='#fff'
                  />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Profile
                  </Text>
                </Flex>
              </Button>
            </Link>
            <Link to='info' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoDocumentText}
                    me='12px'
                    w='18px'
                    h='18px'
                    color='#fff'
                  />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Basic Info
                  </Text>
                </Flex>
              </Button>
            </Link>
            <Link to='change-password' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon as={FaCube} me='12px' w='18px' h='18px' color='#fff' />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Change Password
                  </Text>
                </Flex>
              </Button>
            </Link>
            {/* <Link to='2fa' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={BsToggleOn}
                    me='12px'
                    w='18px'
                    h='18px'
                    color='#fff'
                  />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    2FA
                  </Text>
                </Flex>
              </Button>
            </Link>
            <Link to='accounts' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon as={FaUser} me='12px' w='18px' h='18px' color='#fff' />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Accounts
                  </Text>
                </Flex>
              </Button>
            </Link>
            <Link to='notifications' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={IoMdNotifications}
                    me='12px'
                    w='18px'
                    h='18px'
                    color='#fff'
                  />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Notifications
                  </Text>
                </Flex>
              </Button>
            </Link> */}
            <Link to='sessions' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={MdPowerSettingsNew}
                    me='12px'
                    w='18px'
                    h='18px'
                    color='#fff'
                  />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Sessions
                  </Text>
                </Flex>
              </Button>
            </Link>
            {/* <Link to='delete-account' spy={true} smooth={true} duration={500}>
              <Button
                variant='transparent-with-icon'
                borderRadius='15px'
                _hover={{ bg: bgHoverLinks }}
                w='100%'>
                <Flex align='center' justifySelf='flex-start' w='100%'>
                  <Icon
                    as={AiFillDelete}
                    me='12px'
                    w='18px'
                    h='18px'
                    color='#fff'
                  />
                  <Text color='#fff' fontWeight='500' fontSize='xs'>
                    Delete Account
                  </Text>
                </Flex>
              </Button>
            </Link> */}
          </Stack>
        </CardBody>
      </Card>
      <Stack
        direction='column'
        spacing='24px'
        mt='40px'
        align={{ lg: "flex-end" }}
        justify={{ lg: "flex-end" }}
        w='100%'>
        {/* Header */}
        <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf={{ lg: "flex-end" }}
          justifySelf={{ lg: "flex-end" }}>
          <Element id='profile' name='profile'>
            <CardBody>
              <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'>
                <Flex align='center'>
                {/* <Avatar me={{ md: '22px' }} src={avatar11} w='80px' h='80px' borderRadius='15px'>
								<AvatarBadge
									cursor='pointer'
									borderRadius='8px'
									border='transparent'
									bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
									boxSize='26px'
									backdropFilter='blur(120px)'>
									<Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
								</AvatarBadge>
							</Avatar> */}
                  <Avatar
                    // src={require(`assets/img/avatars/${avatar14}.jpg`)}
                    src={profileImg}
                    w='80px'
                    h='80px'
                    me='22px'
                    borderRadius='15px'
                    cursor='pointer'
                    onClick={() => {
                      setOverlay(<OverlayOne />)
                      onOpen1()
                    }}>
                    <AvatarBadge
                      cursor='pointer'
                      borderRadius='8px'
                      border='transparent'
                      bg='linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)'
                      boxSize='26px'
                      backdropFilter='blur(120px)'>
                      <Icon h='12px' w='12px' color='#fff' as={FaPencilAlt} />
                    </AvatarBadge>
                  </Avatar>
                  <Flex direction='column'>
                    <Text color='#fff' fontWeight='bold' fontSize='lg'>
                      {/* {username} */}
                      {name} {" "} {surname}
                    </Text>
                    <Text color='gray.400' fontWeight='500' fontSize='sm'>
                      {email}
                      {/* {email} */}
                    </Text>
                  </Flex>
                </Flex>
                {/* <Flex
                  align='center'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mt={{ sm: "16px", lg: null }}
                  ms={{ sm: "6px", lg: null }}>
                  <Text color='#fff' fontWeight='500' me='14px' fontSize='sm'>
                    Switch to {toggle ? "invisible" : "visible"}
                  </Text>
                  <DarkMode>
                    <Switch
                      colorScheme='brand'
                      onChange={() => setToggle(!toggle)}
                    />
                  </DarkMode>
                </Flex> */}
              </Flex>
            </CardBody>
          </Element>
        </Card>
        {/* Basic Info Card*/}
        <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf='flex-end'
          justifySelf='flex-end'>
          <Element id='info' name='info'>
            <CardHeader mb='40px'>
              <Text color='#fff' fontSize='lg' fontWeight='bold'>
                Basic Info
              </Text>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='20px' w='100%'>
                <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      First Name
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. Michael'
                      fontSize='xs'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Second name
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. Jackson'
                      fontSize='xs'
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Email Address
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. michael.jackson@avia-office.com'
                      fontSize='xs'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Phone Number
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. +40 941 353 292'
                      fontSize='xs'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                  {/* <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Your Location
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. Bucharest'
                      fontSize='xs'
                    />
                  </FormControl> */}
                                    <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Company name
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. Avia Office Ltd'
                      fontSize='xs'
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Job title
                    </FormLabel>
                    <Input
                      border='.5px solid'
                      borderColor='rgba(226, 232, 240, 0.3)'
                      _hover='none'
                      color='#fff'
                      bg='#0F1535'
                      borderRadius='15px'
                      placeholder='eg. Sales Manager'
                      fontSize='xs'
                      value={job}
                      onChange={(e) => setJob(e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <Button
                    variant='brand'
                    w='150px'
                    h='35px'
                    alignSelf='flex-end'
                    onClick={() => updateProfileFunc(userId, name, surname, email, phone, company, job)}>
                    <Text fontSize='10px' color='#fff' fontWeight='bold'>
                      UPDATE PROFILE
                    </Text>
                  </Button>
              </Stack>
            </CardBody>
          </Element>
        </Card>
        {/* Change Password Card */}
        <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf='flex-end'
          justifySelf='flex-end'>
          <Element id='change-password' name='change-password'>
            <CardHeader mb='40px'>
              <Text color='#fff' fontSize='lg' color='#fff' fontWeight='bold'>
                Change Password
              </Text>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='20px' w='100%'>
                <FormControl>
                  <FormLabel
                    fontWeight='bold'
                    color='#fff'
                    fontSize='xs'
                    mb='10px'>
                    Current Password
                  </FormLabel>
                  <Input
                    border='.5px solid'
                    borderColor='rgba(226, 232, 240, 0.3)'
                    _hover='none'
                    color='#fff'
                    bg='#0F1535'
                    borderRadius='15px'
                    placeholder='Current Password'
                    fontSize='xs'
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontWeight='bold'
                    color='#fff'
                    fontSize='xs'
                    mb='10px'>
                    New Password
                  </FormLabel>
                  <Input
                    border='.5px solid'
                    borderColor='rgba(226, 232, 240, 0.3)'
                    _hover='none'
                    color='#fff'
                    bg='#0F1535'
                    borderRadius='15px'
                    placeholder='New Password'
                    fontSize='xs'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontWeight='bold'
                    color='#fff'
                    fontSize='xs'
                    mb='10px'>
                    Confirm New Password
                  </FormLabel>
                  <Input
                    border='.5px solid'
                    borderColor='rgba(226, 232, 240, 0.3)'
                    _hover='none'
                    color='#fff'
                    bg='#0F1535'
                    borderRadius='15px'
                    placeholder='Confirm New Password'
                    fontSize='xs'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
                <Flex direction='column'>
                  <Text
                    color='#fff'
                    fontWeight='bold'
                    fontSize='lg'
                    mb='4px'
                    mt='40px'>
                    Password Requirements
                  </Text>
                  <Text color='gray.400' fontWeight='400' fontSize='sm'>
                    Please follow this guide for a strong password.
                  </Text>
                </Flex>
                <Flex
                  direction={{ sm: "column", lg: "row" }}
                  justify='space-between'
                  w='100%'>
                  <Stack
                    direction='column'
                    spacing='6px'
                    mb={{ sm: "12px", lg: "0px" }}>
                    <Flex align='center'>
                      <Icon
                        as={BsCircleFill}
                        w='6px'
                        h='6px'
                        color='#fff'
                        me='6px'
                      />
                      <Text color='gray.500' fontWeight='500' fontSize='xs'>
                        One special characters
                      </Text>
                    </Flex>
                    <Flex align='center'>
                      <Icon
                        as={BsCircleFill}
                        w='6px'
                        h='6px'
                        color='gray.500'
                        me='6px'
                      />
                      <Text color='gray.500' fontWeight='500' fontSize='xs'>
                        Min 6 characters
                      </Text>
                    </Flex>
                    <Flex align='center'>
                      <Icon
                        as={BsCircleFill}
                        w='6px'
                        h='6px'
                        color='gray.500'
                        me='6px'
                      />
                      <Text color='gray.500' fontWeight='500' fontSize='xs'>
                        One number (2 are recommended)
                      </Text>
                    </Flex>
                    <Flex align='center'>
                      <Icon
                        as={BsCircleFill}
                        w='6px'
                        h='6px'
                        color='gray.500'
                        me='6px'
                      />
                      <Text color='gray.500' fontWeight='500' fontSize='xs'>
                        Change it often
                      </Text>
                    </Flex>
                  </Stack>
                  <Button
                    variant='brand'
                    w='150px'
                    h='35px'
                    alignSelf='flex-end'
                    onClick={() => {updatePasswordFunc(userId, currentPassword, password, confirmPassword)}}>
                    <Text fontSize='10px' color='#fff' fontWeight='bold'>
                      UPDATE PASSWORD
                    </Text>
                  </Button>
                </Flex>
              </Stack>
            </CardBody>
          </Element>
        </Card>
        {/* Sessions Card */}
        <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf='flex-end'
          justifySelf='flex-end'>
          <Element id='sessions' name='sessions'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text
                  color='#fff'
                  fontSize='lg'
                  fontWeight='bold'
                  color='#fff'
                  mb='4px'>
                  Sessions
                </Text>
                <Text color='gray.400' fontWeight='400' fontSize='sm'>
                  This is a list of devices that have logged into your account.
                  Remove those that you do not recognize.
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='18px' w='100%'>
                <Flex
                  direction={{ sm: "column", md: "row" }}
                  justify='space-between'
                  w='100%'>
                  <Flex align='center'>
                    {userDeviceType === "Mobile" ? (
                      <Icon
                        as={GiSmartphone}
                        me='30px'
                        w='28px'
                        h='28px'
                        color='gray.400'
                      />
                    ) : userDeviceType === "Tablet" ? (
                      <Icon
                        as={RiTabletLine}
                        me='30px'
                        w='28px'
                        h='28px'
                        color='gray.400'
                      />
                    ) : (
                      <Icon
                        as={RiComputerLine}
                        me='30px'
                        w='28px'
                        h='28px'
                        color='gray.400'
                      />
                    )}
                    <Flex direction='column' mb={{ sm: "8px", lg: null }}>
                      <Text color='#fff' fontWeight='500' fontSize='sm'>
                        {/* Bucharest {IPAddress} */}
                        <IPAddress />
                      </Text>
                      <Text color='gray.400' fontSize='xs' fontWeight='500'>
                        Your current session: {userDeviceType}
                      </Text>
                    </Flex>
                  </Flex>
                  <Stack
                    direction='row'
                    spacing='24px'
                    align='center'
                    alignSelf={{ sm: "flex-end", lg: null }}
                    minW={{ lg: "280px" }}>
                    <LightMode>
                      <Badge colorScheme='green' borderRadius='12px' p='12px'>
                        ACTIVE
                      </Badge>
                    </LightMode>
                    <Text color='gray.400' fontSize='md' fontWeight='500'>
                      EU
                    </Text>
                    <Button variant='transparent-with-icon' color='brand.200'>
                      <Flex
                        align='center'
                        color='brand.200'
                        w='100%'
                        fontSize='sm'>
                        <Text
                          me='6px'
                          transition='all .3s ease'
                          _hover={{ me: "14px" }}
                          fontSize='xs'
                          color='#01B574'>
                          See more
                        </Text>
                        <Icon
                          as={BsArrowRight}
                          color='#01B574'
                          w='12px'
                          h='12px'
                          transition='all .3s ease'
                          _hover={{ transform: "translateX(50%)" }}
                        />
                      </Flex>
                    </Button>
                  </Stack>
                </Flex>
                {/* <HSeparator />
                <Flex
                  direction={{ sm: "column", md: "row" }}
                  justify='space-between'
                  w='100%'>
                  <Flex align='center'>
                    <Icon
                      as={RiComputerLine}
                      me='30px'
                      w='28px'
                      h='28px'
                      color='gray.400'
                    />
                    <Flex direction='column'>
                      <Text color='#fff' fontWeight='500' fontSize='sm'>
                        Chrome on macOS
                      </Text>
                    </Flex>
                  </Flex>
                  <Stack
                    direction='row'
                    spacing='24px'
                    align='center'
                    alignSelf={{ sm: "flex-end", lg: null }}
                    minW={{ lg: "185px" }}>
                    <Text color='gray.400' fontSize='md' fontWeight='500'>
                      US
                    </Text>
                    <Button variant='transparent-with-icon' color='brand.200'>
                      <Flex
                        align='center'
                        color='brand.200'
                        w='100%'
                        fontSize='sm'>
                        <Text
                          me='6px'
                          transition='all .3s ease'
                          _hover={{ me: "14px" }}
                          fontSize='xs'
                          color='#01B574'>
                          See more
                        </Text>
                        <Icon
                          as={BsArrowRight}
                          color='#01B574'
                          w='12px'
                          h='12px'
                          transition='all .3s ease'
                          _hover={{ transform: "translateX(50%)" }}
                        />
                      </Flex>
                    </Button>
                  </Stack>
                </Flex>
                <HSeparator />
                <Flex
                  direction={{ sm: "column", md: "row" }}
                  justify='space-between'
                  w='100%'>
                  <Flex align='center'>
                    <Icon
                      as={GiSmartphone}
                      me='30px'
                      w='28px'
                      h='28px'
                      color='gray.400'
                    />
                    <Flex direction='column'>
                      <Text color='#fff' fontWeight='500' fontSize='sm'>
                        Safari on iPhone
                      </Text>
                    </Flex>
                  </Flex>
                  <Stack
                    direction='row'
                    spacing='24px'
                    align='center'
                    alignSelf={{ sm: "flex-end", lg: null }}
                    justifySelf='flex-end'
                    minW={{ lg: "185px" }}>
                    <Text color='gray.400' fontSize='md' fontWeight='500'>
                      US
                    </Text>
                    <Button variant='transparent-with-icon' color='brand.200'>
                      <Flex
                        align='center'
                        color='brand.200'
                        w='100%'
                        fontSize='sm'>
                        <Text
                          me='6px'
                          transition='all .3s ease'
                          _hover={{ me: "14px" }}
                          fontSize='xs'
                          color='#01B574'>
                          See more
                        </Text>
                        <Icon
                          as={BsArrowRight}
                          color='#01B574'
                          w='12px'
                          h='12px'
                          transition='all .3s ease'
                          _hover={{ transform: "translateX(50%)" }}
                        />
                      </Flex>
                    </Button>
                  </Stack>
                </Flex> */}
              </Stack>
            </CardBody>
          </Element>
        </Card>
      </Stack>
      <Modal isCentered isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent bg='#1a202c'>
          <ModalHeader color='white' pb='none'>Upload your avatar image</ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody>
            <AvatarDragAndDrop onClose1={onClose1} ></AvatarDragAndDrop>
          </ModalBody>
          <ModalFooter>
            {/* <Button bg="#582CFF" fontSize='12px' color='white'>Close</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isLoading} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent  bg="rgba(26, 32, 44, 0)" boxShadow='none'>
          <ModalBody display="flex" alignItems="center" justifyContent="center" minHeight="400px" opacity='0.6'>
            <Spinner style={{ "--spinner-size": "14rem" }} color='white' thickness='3px'/>
            {/* <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
              /> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Settings;
