/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const columnsData1 = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "POSITION",
    accessor: "position",
  },
  {
    Header: "OFFICE",
    accessor: "office",
  },
  {
    Header: "AGE",
    accessor: "age",
  },
  {
    Header: "START DATE",
    accessor: "date",
  },
  {
    Header: "SALARY",
    accessor: "salary",
  },
];

export const columnsDataPartsPrices = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "P/N",
    accessor: "pn",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "SUPPLIER",
    accessor: "supplier",
  },
  {
    Header: "NEW",
    accessor: "priceNew",
  },
  {
    Header: "REPAIRED",
    accessor: "priceRepaired",
  },
  {
    Header: "OVERHAULED",
    accessor: "priceOverhauled",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataAdminPanelConnections = [
  {
    Header: "TIME",
    accessor: "createdAt",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "IP",
    accessor: "ip",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "DEVICE",
    accessor: "device",
  },
];

export const columnsData2 = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "CUSTOMER",
    accessor: "customer",
  },
  {
    Header: "PRODUCT",
    accessor: "product",
  },
  {
    Header: "REVENUE",
    accessor: "revenue",
  },
];
