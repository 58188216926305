import React, { useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { Form, Row, Col } from 'react-bootstrap';
import './AvatarDragAndDropStyles.scss';
// import { API_URL } from '../utils/constants';

import dragAndDropImage from 'assets/img/icons/dragAndDropImage.png'

// API URL
import config from './../../config.js'

import {
  Box,
  Button,
  // FormControl as Form,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  StylesProvider,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  // Form,
  // Row,
  // Col,
} from "@chakra-ui/react";

// const API_URL = 'http://localhost:5100'
// const API_URL = WS_BASE_URL
const API_URL = config.WS_BASE_URL

const AvatarDragAndDrop = (props, {onClose1}) => {
  const [file, setFile] = useState(null); // state for storing actual image
  const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage
  const [state, setState] = useState({
    title: '',
    description: ''
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
  const dropRef = useRef(); // React ref for managing the hover state of droppable area

  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const onDrop = (files) => {
    const [uploadedFile] = files;
    setFile(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
    dropRef.current.style.border = '2px dashed #e9ebeb';
  };

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000';
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb';
    }
  };

  const handleOnSubmit = async (event) => {
    let username = JSON.parse(localStorage.getItem("user"))._id;
    let userVar = JSON.parse(localStorage.getItem("user"));
    userVar.avatar = `assets/img/avatars/${username}.jpg`
    localStorage.setItem("user", JSON.stringify(userVar))
    event.preventDefault();

    try {
      // const { title, description } = state;
      if (file) {
        // let username = JSON.parse(localStorage.getItem("user"))._id;
        const formData = new FormData();
        formData.append('username', username)
        formData.append('file', file);

        setErrorMsg('');
        await axios.post(`${API_URL}/upload`, formData, {
          headers: {
            // 'Content-Type': 'multipart/form-sssssssssssssssssssssssssssssssdata'
            'Content-Type': 'multipart/form-data'
            // 'Content-Type': 'application/json'
          }
        });
        // console.log('HAHAHA')
        // onClose1()
        // props.history.push('/list');
      } else {
        setErrorMsg('Please select a file to add.');
      }
    } catch (error) {
      error.response && setErrorMsg(error.response.data);
    }
  };

  return (
    <React.Fragment>
      <Form className="search-form" onSubmit={handleOnSubmit}>
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        {/* <Row>
          <Col>
            <Form.Group controlId="title">
              <Form.Control
                type="text"
                name="title"
                value={state.title || ''}
                placeholder="Enter title"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="description">
              <Form.Control
                type="text"
                name="description"
                value={state.description || ''}
                placeholder="Enter description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Col>
        </Row> */}
        <div className="upload-section">
          <Dropzone
            onDrop={onDrop}
            onDragEnter={() => updateBorder('over')}
            onDragLeave={() => updateBorder('leave')}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
                <input {...getInputProps()} />
                <img
                    src={dragAndDropImage}
                    height={100}
                    width={100}
                  ></img>
                <Text fontSize='xs' color='#fff' fontWeight='bold'>
                  Drag and drop a file OR click here to select a file
                </Text>
                {/* <p>Drag and drop a file OR click here to select a file</p> */}
                {file && (
                  <div>
                    <strong>Selected file:</strong> {file.name}
                  </div>
                )}
              </div>
            )}
          </Dropzone>
          {previewSrc ? (
            isPreviewAvailable ? (
              <div className="image-preview">
                <img className="preview-image" src={previewSrc} alt="Preview"/>
              </div>
            ) : (
              <div className="preview-message">
                <Text fontSize='xs' color='#fff' fontWeight='bold'>
                  No preview available for this file
                </Text>
                {/* <p>No preview available for this file</p> */}
              </div>
            )
          ) : (
            <div className="preview-message">
              <Text fontSize='xs' color='#fff' fontWeight='bold'>
                Image preview will be shown here after selection
              </Text>
              {/* <p>Image preview will be shown here after selection</p> */}
            </div>
          )}
        </div>
        {/* <Button variant="primary" type="submit">
          Submit
        </Button> */}
        <Button
          borderRadius='12px'
          bg='brand.200'
          _hover={{ opacity: "0.8" }}
          _active={{ opacity: "0.9" }}
          me={{ base: "none", lg: "20px" }}
          mt='2%'
          type="submit"
          // leftIcon={<Icon color='white' as={FaCube} me='6px' />}
          >
          <Text fontSize='xs' color='#fff' fontWeight='bold'>
            SUBMIT
          </Text>
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default AvatarDragAndDrop;