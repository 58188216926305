import React, { useState, useEffect } from 'react';
import axios from 'axios';

const IPAddress = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org/?format=json');
        setIpAddress(response.data.ip);
        fetchCountry(response.data.ip);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchCountry = async (ipAddress) => {
      try {
        const response = await axios.get(`https://api.geoapify.com/v1/ipinfo?ip=${ipAddress}&apiKey=1afb1683ac3a4713a0c335c8dd6b5e72`);
        setCountry(response.data.country.name);
      } catch (error) {
        console.log(error);
      }
    };

    fetchIpAddress();
  }, []);

  return (
    <div>
      <p>{ipAddress} / Country: {country}</p>
    </div>
  );
};

export default IPAddress;