/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import BannerLayout from "layouts/Banner.js";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AuthRoutes from "./components/PrivateRoute/AuthRoutes";

// Modified paddings by me. To fix modals opening.
document.body.style.padding = "10px 10px 10px 10px";
ReactDOM.render(
  <HashRouter>
    <Switch>
      <AuthRoutes path={`/auth`} component={AuthLayout} />
      <PrivateRoute path={`/admin`} component={AdminLayout} />
      <PrivateRoute path={`/rtl`} component={RTLLayout} />
      <AuthRoutes path={`/banner`} component={BannerLayout} />
      <Redirect from={`/`} to='/admin/dashboard' />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);