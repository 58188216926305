import React, { useState } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export function SearchBar(props) {
  const { variant, children, value, onChange, placeholderText, isDisabledVar, onSearch, ...rest } = props;
  const [inputValue, setInputValue] = useState(value || "");

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleSearch = () => {
    if (onSearch) {
      onSearch(inputValue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  let mainTeal, searchIconColor, inputBg;

  if (isDisabledVar === true) {
    mainTeal = useColorModeValue("grey", "grey");
    searchIconColor = useColorModeValue("grey", "grey");
    inputBg = useColorModeValue("brand.900", "brand.500");
  } else {
    mainTeal = useColorModeValue("brand.300", "brand.300");
    searchIconColor = useColorModeValue("brand.300", "brand.300");
    inputBg = useColorModeValue("brand.900", "brand.500");
  }


  return (
    <InputGroup
      bg={inputBg}
      borderRadius="15px"
      borderColor="brand.500"
      isDisabled={isDisabledVar}
      w="300px"
      _focus={{
        borderColor: { mainTeal },
      }}
      _active={{
        borderColor: { mainTeal },
      }}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            isDisabled={isDisabledVar}
            _hover="none"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
            onClick={handleSearch}
          ></IconButton>
        }
      />
      <Input
        fontSize="xs"
        py="11px"
        placeholder={placeholderText}
        borderRadius="inherit"
        value={inputValue}
        onChange={handleInputChange}
        isDisabled={isDisabledVar} // Add the isDisabled prop to disable the input
        onKeyPress={handleKeyPress}
      />
    </InputGroup>
  );
}