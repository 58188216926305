/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";

export default function Footer(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "15px", md: "30px" }}
      pb='20px'>
      <Text
        fontSize='sm'
        color='white'
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        &copy; {1900 + new Date().getYear()},{" "}
        <Text as='span'>
          {document.documentElement.dir === "rtl"
            ? "(In progress)"
            : "(In progress)"}
        </Text>
        {/* <Link href='https://www.artjoms.com' target='_blank'>
          {document.documentElement.dir === "rtl"
            ? "Artjoms "
            : "Artjoms "}
        </Link> */}
      </Text>
      <List display='flex'>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link color='white' fontSize='sm' href='https://avia-office.com/'>
            {document.documentElement.dir === "rtl" ? "Avia-Office" : "Avia-Office"}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            color='white'
            fontSize='sm'
            href='https://avia-office.com/'>
            {document.documentElement.dir === "rtl" ? "Technical support" : "Technical support"}
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            color='white'
            fontSize='sm'
            href='https://avia-office.com/'>
            {document.documentElement.dir === "rtl" ? "Instruction" : "Instruction"}
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
