/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import { Box, useStyleConfig } from "@chakra-ui/react";
import './ProgramCard.css';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const ProgramCard = (props) => {
    const { title, subtitle, text, image, background, link } = props;

    // const notify = (programName) => {
    //   toast.info(`Program ${programName} started`, {
    //     theme: "dark"
    //   })
    // }

    const name=title

    // let userId = JSON.parse(localStorage.getItem("user"))._id;

    const params = useParams();
    var valA = 0
    // <ProgramsPopupA title={title} subtitle={subtitle} text={text}></ProgramsPopupA>

    useEffect(() => {
      async function fetchData() {
      }
 
      fetchData();
    
      return;
    }, [params.id]);

    function updateForm(value) {
      return setForm((prev) => {
        return { ...prev, ...value };
      });
    }

    async function onSubmit(e) {
      e.preventDefault();
      const programParams = {
        _id: valA,
        program_name: title,
        status: 1
      };
      // console.log(programParams)
      // notify(title)

      // await fetch(`http://localhost:5000/update/${valA}`, {
      //   method: "POST",
      //   body: JSON.stringify(editedPerson),
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      // });
    }

    //-------------
    // Animated cards
    //-------------
    var demoButtons;

    function start () {
    
    // Add event "click" to "demo buttons"
    demoButtons = document.querySelectorAll ('.js-modify');
    for (var i = 0; i < demoButtons.length; i++) {
        demoButtons[i].addEventListener ('click', toggleEffect);
    }
    
    // Add event "click" to "save buttons"
    var saveButtons = document.querySelectorAll ('.js-save');
    for (var i = 0; i < saveButtons.length; i++) {
        saveButtons[i].addEventListener ('click', toggleActive);
    }
    
    }

    // Toggle "effect" classes
    function toggleEffect () {
    var target = document.querySelector (this.dataset.target);
        target.dataset.effect = this.dataset.effect;
    
    for (var i= 0; i < demoButtons.length; i++) {
        demoButtons[i].classList.remove ('active');
    }
    
    toggleActive.call (this);
    }

    // Toggle "active" class
    function toggleActive () {
    this.classList.toggle ('active');
    }

    // Invoke "start ()" function
    window.addEventListener ('load', start);
    //-------------

  return (
    <div>
        <div className="card" data-effect="zoom" onClick={onSubmit}>
          <button className="card__save  js-save" type="button">
            <i className="fa  fa-bookmark" />
          </button>
          <figure className="card__image">
            <img src={background} alt="Short description" />
          </figure>
          <div className="card__header">
            <figure className="card__profile">
              {/* <img src="https://upload.wikimedia.org/wikipedia/commons/1/1c/Neil_Armstrong.jpg" alt="Short description" /> */}
            </figure>
          </div>
          <div className="card__body">
            <h3 className="card__name">{title}</h3>
            <p className="card__job">{subtitle}</p>
            <p className="card__bio">{text}</p>
          </div>
          <div className="card__footer">
            {/* <p className="card__date">Feb 10 2018</p>
            <p className /> */}
          </div>
        </div>
    </div>
  );
}

export default ProgramCard;
