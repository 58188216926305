import React, { useState, useEffect, useRef } from 'react';
import xml2js from 'xml2js';
import FetchButton from 'components/Other/ManualsProgram/FetchButton';

import axios from "axios";

import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Input,
  ListItem,
  UnorderedList,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  MenuGroup,
  MenuDivider,
  Box,
  useToast,
  Stack,
  FormControl,
  FormLabel,
  Select,
  Tabs,
  TabList,
  Tab,
  Icon,
  TabPanels,
  TabPanel,
  TabIndicator,
  Checkbox,
  Grid,
  Spinner,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure
} from "@chakra-ui/react";

import ListGroup from 'react-bootstrap/ListGroup';

// import TreeMenu from 'react-simple-tree-menu';
import TreeMenu from 'react-simple-tree-menu';
// import TreeNode from 'react-simple-tree-menu';
import './main.css';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { BiReset } from "react-icons/bi";
import { AiFillSave, AiOutlineSave, AiOutlineLink, AiOutlineReload, AiOutlineFullscreen } from "react-icons/ai";
import { SearchIcon } from "@chakra-ui/icons";

import { Document, Page, pdfjs } from 'react-pdf';

import { getAircraftTypes, authorizeOnServer, resetAuthorization, showTreeManuals, showManuals, showTreeCategories, searchInfoManuals } from '../../helpers/backend_helper';

import PdfViewer from "components/PDF/pdfViewer"
import PdfViewer2 from "components/PDF/pdfViewer2"
import PdfViewer3 from "components/PDF/pdfViewer3"

import pdfURL from "./testPDF2.pdf"

import { saveAs } from 'file-saver';

// Icons
import { RocketIcon } from "components/Icons/Icons";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { FaCube } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";

import ConnectionErrorImage from "./../../assets/img/icons/noConnection.png";


import NameList from "./../../components/Manuals/List";
import ManualCategoriesList from "./../../components/Manuals/List2";
import DocumentViewer from "./../../components/Manuals/DocumentViewer";
import { HSeparator } from 'components/Separator/Separator';
import { SearchBar } from 'components/Navbars/SearchBar/SearchBar';
// import MyTreeComponent from "./../../components/Manuals/TreeMenuMy";

// import { verifyUserAPI } from '../../helpers/backend_helper';

// let pdfURL = 'http://localhost:3000/#/admin/other/Manuals'

const MenuTree = () => {
  const textColor = "white";
  const bgPrevButton = "white";

  const names = ["Challenger 300",
    "Challenger 350",
    "Challenger 601",
    "Challenger 604",
    "Challenger 605",
    "Challenger 650",
    "Challenger 850",
    "Global 5000",
    "Global 5500",
    "Global 6000",
    "Global 6500",
    "Global 7500",
    "Global Express",
    "Global Express XRS",
    "Learjet 25",
    "Learjet 35",
    "Learjet 40",
    "Learjet 45",
    "Learjet 55",
    "Learjet 75",
    "Challenger 604 Flight Manuals"
  ];

  const toast = useToast()
  const [pdfRoute, setpdfRoute] = useState('https://projects.wojtekmaj.pl/react-pdf/assets/sample-8bb8af10.pdf');
  const [pdfBinary, setpdfBinary] = useState('');
  // const [urlPDFA, setUrlPdfA] = useState('./testPDF2.pdf');
  const [urlPDFA, setUrlPdfA] = useState('');
  const [fileType, setFileType] = useState('');

  const [tabIndex, setTabIndex] = useState(0)
  const [secondTabDisabled, setSecondTabDisabled] = useState(true);
  const [thirdTabDisabled, setThirdTabDisabled] = useState(true);
  const [aircraftType, setAircraftType] = useState('');

  const [manualTypeHeader1, setManualTypeHeader1] = useState('');
  const [manualTypeHeader2, setManualTypeHeader2] = useState('');
  const [manualTypeHeader3, setManualTypeHeader3] = useState('');
  const [manualTypeHeader4, setManualTypeHeader4] = useState('');

  const [manualCategories1, setManualCategories1] = useState('');
  const [manualCategories2, setManualCategories2] = useState('');
  const [manualCategories3, setManualCategories3] = useState('');
  const [manualCategories4, setManualCategories4] = useState('');

  const [manualsTree, setManualsTree] = useState('');
  const [manualPath, setManualPath] = useState('');
  const [manualMdPath, setManualMdPath] = useState('');
  const [manualFldrName, setManualFldrName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [isDisabledVar, setIsDisabledVar] = useState(true);
  const [foundSearchData, setFoundSearchData] = useState(true);

  const [firstElementData, setFirstElementData] = useState(null);

  const [selectedItems, setSelectedItems] = useState([]);

  // Set active bullets based on current state
  const [activeBullets, setActiveBullets] = useState({
    about: true,
    account: false,
    address: false,
  });

  const [checkboxes, setCheckboxes] = useState({
    design: false,
    code: false,
    develop: false,
  });

  // Tabs references
  const aboutTab = useRef();
  const accountTab = useRef();
  const addressTab = useRef();

  const OverlayOne = () => (
    <ModalOverlay
      bg='rgba(0, 0, 0, 0.8)'
      backdropFilter='hue-rotate(10deg)'
    >
    </ModalOverlay>
  )

  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure()
  const { isOpen: isOpen4, onOpen: onOpen4, onClose: onClose4 } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)

  const [parentRoute, setParentRoute] = useState(null);

  // User info
  // const [user, setUser] = useState({});
  // useEffect(() => {
  //     verifyUserAPI().then((userData) => {
  //         if (userData?.status === true) {
  //             setUser(userData?.data)
  //             console.log(userData.data)
  //         }
  //     })
  // }, [])

  // let userID = `${user.ID}`
  // setUrlPdfA(`./storage/manuals/manual${userID}.pdf`)

  // useEffect(() => {
  //   // fetch('https://avia-office.com/api', {
  //   fetch('https://smartpubs.aero.bombardier.com/viewer/api/resources/cover-page/CH350MM/ATA-100/CH350/AMM/CH350MM_AMM.pdf', {
  //     // 'Content-Type':  'application/json',
  //     // 'Access-Control-Allow-Origin': '*',
  //     // 'Access-Control-Allow-Credentials': 'true'
  //   })
  //     .then(response => response.json())
  //     .then(data => console.log(data))
  //     .catch(error => console.error(error));
  // }, []);
  const treeData3 = [
    {
      "key": "node-1",
      "label": "AMM Cover Page",
      "route": "CH604MM_AMM.pdf",
      "icon": "book",
      "nodes": []
    },
    {
      "key": "node-2",
      "label": "Publication Front Matter",
      "route": "",
      "icon": "folder_special",
      "nodes": [
        {
          "key": "node-3",
          "label": "AMM Transmittal Letter",
          "route": "00/XMIT.full.pdf",
          "icon": "description",
          "nodes": []
        },
        {
          "key": "node-11",
          "label": "AMM List of Service Bulletins",
          "route": "00/AMMSB.full.pdf",
          "icon": "description",
          "nodes": []
        }
      ]
    },
    {
      "key": "node-12",
      "label": "05 - Time Limits/Maintenance Checks",
      "route": "",
      "icon": "folder",
      "nodes": [
        {
          "key": "node-13",
          "label": "Chapter Front Matter",
          "route": "",
          "icon": "folder_special",
          "nodes": [
            {
              "key": "node-14",
              "label": "AMM 05 - List of Effective Pages",
              "route": "05/CLEP-05.full.pdf",
              "icon": "description",
              "nodes": []
            },
            {
              "key": "node-15",
              "label": "AMM 05 - Table of Contents",
              "route": "05/CTOC-05.full.pdf",
              "icon": "description",
              "nodes": []
            }
          ]
        }
      ]
    }
  ]

  const treeData5 = [
    {
      uid: 1,
      pid: 0,
      rootHash: '-483070841',
      urlHash: '1047137892',
      iconName: 'book',
      title: 'AMM Cover Page',
      chg: '',
      active: false,
      disabled: false,
      expanded: false,
      chapter: 'cover_page',
      file: 'CH300MM_AMM.pdf',
      route: 'CH300MM_AMM.pdf',
    },
    {
      rootHash: '-483070841',
      urlHash: '950184179',
      uid: 2,
      pid: 0,
      iconName: 'folder_special',
      title: 'Publication Front Matter',
      chg: '',
      active: false,
      disabled: false,
      expanded: false,
      chapter: '00',
      file: '',
      section: '',
      route: '',
      children: [
        {
          uid: 3,
          pid: 2,
          rootHash: '-483070841',
          urlHash: '1639491546',
          iconName: 'description',
          title: 'AMM Transmittal Letter',
          chg: 'R',
          active: false,
          disabled: false,
          expanded: false,
          chapter: '00',
          file: 'XMIT.full.pdf',
          section: '',
          route: '00/XMIT.full.pdf',
        },
        // ...rest of the data
      ],
    },
    // ...rest of the data
  ];

  const convertTreeData = (data, parentRoute = "") => {
    if (!Array.isArray(data)) {
      return []; // Return an empty array if data is not an array
    }

    return data.map((item) => {
      const { uid, title, route, children, iconName } = item;

      const convertedItem = {
        key: uid.toString(),
        label: title,
        route,
        icon: iconName,
        parentRoute,
      };

      if (children && children.length > 0) {
        convertedItem.nodes = convertTreeData(children, route); // Pass the current node's route as the parent's route for children
      }

      return convertedItem;
    });
  };

  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter(selected => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleDownloadClick = () => {
    selectedItems.forEach(item => {
      const { route, label } = item;
      const pdfUrl = `${manualPath}/${route}`;
      saveAs(pdfUrl, label);
    });
  };

  const convertedTreeData = convertTreeData(manualsTree);
  // console.log(convertedTreeData)

  // const renderTreeNode = (node) => (
  //   <TreeNode key={node.uid} label={node.title}>
  //     {node.children && node.children.map(renderTreeNode)}
  //   </TreeNode>
  // );

  function formatDate(dateString) {
    // Array of month names in English
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Convert dateString to a string
    dateString = String(dateString);

    // Extract year, month, and day from the input string
    const year = dateString.substr(0, 4);
    const month = Number(dateString.substr(4, 2)) - 1; // Subtract 1 to match array index
    const day = dateString.substr(6, 2);

    // Format the date as "day-Month-Year"
    const formattedDate = `${day}-${monthNames[month]}-${year}`;

    return formattedDate;
  }

  const handleDownload = async () => {
    showManuals(dataA).then(data => {
      // const response = await axios.get('/api/download-pdf', {
      //   responseType: 'blob', // set the response type to blob
      // });

      // create a new Blob object from the response data
      // const blob = new Blob([data], { type: 'application/pdf' });
      const blob = new File([data], { type: 'application/pdf' });

      // save the file using FileSaver.js
      saveAs(blob, 'my-file.pdf');
    })
  };

  const ShowPDF = async (dataA, manualPathA, manualMdPathA) => {
    setIsLoading(true)
    // console.log(dataA)
    // let dataB = Object.assign({}, dataA, {manualPathA: manualPathA, manualMdPathA: manualMdPathA})
    let dataB = { dataA: dataA, manualPathA: manualPathA, manualMdPathA: manualMdPathA, manualFldrNameA: manualFldrName }
    // console.log(dataB)
    showManuals(dataB).then(data => {
      if (data?.status === true) {
        setIsLoading(false)
        // console.log(data)
        // console.log(data.data)
        // console.log(data.data.blob)

        const pdfData = atob(data.data.blob);
        const pdfFilename = data.data.filename;

        const pdfArray = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          pdfArray[i] = pdfData.charCodeAt(i);
        }
        if (dataA.endsWith('.pdf')) {
          const blob = new Blob([pdfArray], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          // console.log(url)
          setUrlPdfA(url);
          setFileType(dataA);
        }
        else if (dataA.endsWith('.svg')) {
          const blob = new Blob([pdfArray], { type: 'image/svg+xml' });
          const url = URL.createObjectURL(blob);
          // console.log(url)
          setUrlPdfA(url);
          setFileType(dataA);
        }
        else if (dataA.endsWith('.html')) {
          const htmlContent = new TextDecoder().decode(pdfArray);
          const blob = new Blob([pdfArray], { type: 'text/html' });
          const url = URL.createObjectURL(blob);
          setUrlPdfA(url);
          setFileType(dataA);
          // return <iframe src={url} width="100%" height="500px" title="HTML File" />;
        }

        // save the file using FileSaver.js
        // saveAs(blob, 'my-file.pdf');
        // setUrlPdfA(blob)
        // setUrlPdfA(url1)
        // toast({
        //   title: `PDF loaded`,
        //   description: `PDF ${pdfFilename} loaded succesfully`,
        //   status: 'success',
        //   variant: 'left-accent',
        //   position: 'bottom-right',
        //   isClosable: true,
        // })
      } else {
        setIsLoading(false)
        toast({
          title: `Failed to load PDF`,
          description: 'Please check connection to the external server',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
    // setpdfRoute(`https://smartpubs.aero.bombardier.com/viewer/api/resources/file/GL6000MM/ATA-100/GL6000/AMM/${dataA}`)
    // setpdfRoute(dataA)
  }

  function findParent(data, label) {
    for (const item of data) {
      if (item.label === label) {
        return null; // If the item itself is the target label, there is no parent
      }

      if (item.nodes) {
        for (const child of item.nodes) {
          if (child.label === label) {
            return item;
          }
        }
      }

      if (item.nodes) {
        const parent = findParent(item.nodes, label);
        if (parent) {
          return parent;
        }
      }
    }

    return null; // If the parent is not found
  }


  const ConnectToServer = async () => {
    setIsLoading(true)
    resetData()
    authorizeOnServer().then(data => {
      // console.log(data)
      if (data?.status === true) {
        setIsLoading(false)
        toast({
          title: `Connection success`,
          description: 'Connected to the server',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        setIsLoading(false)
        toast({
          title: `Connection error`,
          description: 'Failed to connect to the server',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  const resetData = async () => {
    // setIsLoading(true)
    resetAuthorization().then(data => {
      if (data?.status === true) {
        // setIsLoading(false)
        toast({
          title: `Data successfully reseted`,
          description: 'Connection data successfully reseted',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        // setIsLoading(false)
        toast({
          title: `Reset error`,
          description: 'Failed to reset server data',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  //   const refreshData = async () => {
  //     showManuals().then(data => {
  //       if (data?.status === true) {
  //         console.log(data?.data)
  //         toast({
  //           title: `Data successfully received`,
  //           description: 'Manual received',
  //           status: 'success',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       } else {
  //         toast({
  //           title: `Reset error`,
  //           description: 'Failed to reset server data',
  //           status: 'error',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       }
  //     })
  //   }

  const showTreeCategoriesFunc = async (name) => {
    setIsLoading(true);
    setManualTypeHeader1("")
    setManualTypeHeader2("")
    setManualTypeHeader3("")
    setManualTypeHeader4("")
    setManualCategories1("")
    setManualCategories2("")
    setManualCategories3("")
    setManualCategories4("")
    showTreeCategories(name).then(data => {
      if (data?.status === true) {
        setIsLoading(false);
        console.log(data?.data)
        // const numChapters = data?.data.length;
        // console.log(numChapters)
        let chapter
        if (name == "Challenger 650" || name == "Challenger 604 Flight Manuals") {
          chapter = 1
        } else {
          chapter = 0
        }
        setManualTypeHeader1(data?.data[chapter].level2s[0].title)
        setManualTypeHeader2(data?.data[chapter]?.level2s[1]?.title)
        if (data?.data[1]?.level2s[0]?.title) {
          setManualTypeHeader3(data?.data[1].level2s[0].title);
        }
        if (data?.data[2]?.level2s[0]?.title) {
          setManualTypeHeader4(data?.data[2].level2s[0].title);
        }

        setManualPath(data?.data[chapter].path)
        setManualMdPath(data?.data[chapter].mdpath)
        let manualCategoryArray1 = data?.data[chapter].level2s[0].level3s.map((item) => {
          let tsn = item.tsn;
          let revdate = item.revdate;
          if (item.tsn === -1) {
            tsn = "-";
          }
          if (item.revdate === 11111111) {
            revdate = "-";
          }
          else { revdate = formatDate(revdate) }
          return {
            title: item.title,
            tsn: tsn,
            fldrName: item.fldrName,
            revdate: revdate,
            coverPage: item.coverPage,
            fileName: item.fileName
          };
        });
        setManualCategories1(manualCategoryArray1)
        let manualCategoryArray2 = data?.data[chapter]?.level2s[1]?.level3s.map((item) => {
          let tsn = item.tsn;
          let revdate = item.revdate;
          if (item.tsn === -1) {
            tsn = "-";
          }
          if (item.revdate === 11111111) {
            revdate = "-";
          }
          else { revdate = formatDate(revdate) }
          return {
            title: item.title,
            tsn: tsn,
            fldrName: item.fldrName,
            revdate: revdate,
            coverPage: item.coverPage,
            fileName: item.fileName
          };
        });
        setManualCategories2(manualCategoryArray2)
        if (data?.data[1]?.level2s[0]?.title) {
          let manualCategoryArray3 = data?.data[1].level2s[0].level3s.map((item) => {
            let tsn = item.tsn;
            let revdate = item.revdate;
            if (item.tsn === -1) {
              tsn = "-";
            }
            if (item.revdate === 11111111) {
              revdate = "-";
            }
            else { revdate = formatDate(revdate) }
            return {
              title: item.title,
              tsn: tsn,
              fldrName: item.fldrName,
              revdate: revdate,
              coverPage: item.coverPage,
              fileName: item.fileName
            };
          });
          setManualCategories3(manualCategoryArray3)
        }
        if (data?.data[2]?.level2s[0]?.title) {
          let manualCategoryArray4 = data?.data[2].level2s[0].level3s.map((item) => {
            let tsn = item.tsn;
            let revdate = item.revdate;
            if (item.tsn === -1) {
              tsn = "-";
            }
            if (item.revdate === 11111111) {
              revdate = "-";
            }
            else { revdate = formatDate(revdate) }
            return {
              title: item.title,
              tsn: tsn,
              fldrName: item.fldrName,
              revdate: revdate,
              coverPage: item.coverPage,
              fileName: item.fileName
            };
          });
          setManualCategories4(manualCategoryArray4)
        }

      } else {
        setIsLoading(false);
        toast({
          title: `Connection error`,
          description: 'Please reconnect to the manuals server.',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
        onOpen2()
      }
    })
  }

  const showManualsTreeFunc = async (name) => {
    setIsLoading(true)
    // console.log(name)
    showTreeManuals(name).then(data => {
      console.log('RRR')
      console.log(data)
      if (data?.status === true) {
        setIsLoading(false)
        // console.log('OOO')
        // console.log(data?.data)
        setManualsTree(data?.data)
        // setFirstElementData(data?.data[0].route)
        // console.log(firstElementData)
        // ShowPDF(firstElementData, manualPath, manualMdPath);
      } else {
        setIsLoading(false)
        toast({
          title: `Connection error`,
          description: 'Please reconnect to the manuals server..',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
        onOpen2()
      }
    })
  }

  const handleNameClick = (name) => {
    setSecondTabDisabled(false)
    setTabIndex(1)
    setAircraftType(name)
    showTreeCategoriesFunc(name)
    console.log("Clicked name:", name);
  };

  const handleNameClick2 = (name) => {
    setThirdTabDisabled(false)
    if (name.title == 'Global 7500 SFP') {
      name = Object.assign({}, name, { path: 'GV7500MM', mdpath: 'GV7500' })
      setManualPath('GV7500')
      console.log('222')
    } else if (name.title == '00 - Aircraft maintenance publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Aircraft non-destructive testing publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Aircraft recovery publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Aircraft structure repair publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Airport planning publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Ground handling and service information publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Illustrated parts data publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Illustrated tool and equipment publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Maintenance facilities and equipment planning publication') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else if (name.title == '00 - Maintenance review board report') {
      name = Object.assign({}, name, { path: 'GV7500-S1000D', mdpath: 'GV7500-S1000D' });
      setManualPath('GV7500-S1000D');
      console.log('333');
    } else {
      name = Object.assign({}, name, { path: manualPath, mdpath: manualMdPath })
      console.log('444')
    }
    setTabIndex(2)
    // setAircraftType(name)
    showManualsTreeFunc(name)
    setManualFldrName(name.fldrName)
    // console.log("Clicked name:", name);
    setIsDisabledVar(false)
  };

  // const handleNameClick2 = (name) => {
  //   // Modify the 'name' object
  //   let updatedName;

  //   if (name.title === 'Global 7500 SFP') {
  //     updatedName = { ...name, path: 'GV7500', mdpath: manualMdPath };
  //     setManualPath('GV7500');
  //   } else if (name.title === 'Global 7500 Maintenance - S1000D') {
  //     updatedName = { ...name, path: 'GV7500-S1000D', mdpath: manualMdPath };
  //     setManualPath('GV7500-S1000D');
  //   } else {
  //     updatedName = { ...name, path: manualPath, mdpath: manualMdPath };
  //   }

  //   // Update state synchronously
  //   setThirdTabDisabled(false);
  //   setTabIndex(2);
  //   showManualsTreeFunc(updatedName);
  //   setManualFldrName(updatedName.fldrName);
  //   setIsDisabledVar(false);

  //   // Log the updated 'name' object
  //   console.log("Clicked name:", updatedName);
  // };

  const searchInsideManualsFunc = async (searchValueText) => {
    setIsLoading(true)
    let combinedSearchObject = {
      0: searchValueText,
      1: manualPath,
      2: manualMdPath,
      3: manualFldrName
    };
    searchInfoManuals(combinedSearchObject).then(data => {
      if (data?.status === true) {
        setFoundSearchData(data?.data)
        try {
          console.log(foundSearchData['data']['result'])
        } catch {

        }
        onOpen3()
        // console.log(data?.data)
        setIsLoading(false)
        // setManualsTree(data?.data)
      } else {
        setIsLoading(false)
        toast({
          title: `Search failed`,
          description: 'Please reconnect to the manuals server..',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
        onOpen2()
      }
    })
  }

  const cutRoute = (routeVariable) => {
    let pathStep1 = routeVariable.split("/");
    let pathStep2 = pathStep1.slice(-2).join("/");
    return pathStep2
  }

  useEffect(() => {
    // console.log('HELLO')
    // console.log(manualsTree)
    if (manualsTree.length > 0) {
      setFirstElementData(manualsTree[0].route);
      console.log(manualsTree[0].route)
    }
  }, [manualsTree]);

  useEffect(() => {
    if (firstElementData) {
      ShowPDF(firstElementData, manualPath, manualMdPath);
    }
  }, [firstElementData]);

  return (
    <div style={{ marginTop: '70px', color: 'white' }}>
      <Box
        // mt='20px'
        mb={'8px'}
        borderRadius='15px'
        // px='0px'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        align='center'>
        {/* Header */}
        <Card
          direction={{ sm: 'column', md: 'row' }}
          mx='auto'
          maxH='330px'
          w={{ sm: '90%', xl: '100%' }}
          justifyContent={{ sm: 'center', md: 'space-between' }}
          align='center'
          p='10px'
          borderRadius='0px'
          // borderTopLeftRadius='6px'
          // borderTopRightRadius='6px'>
          borderTopRadius='6px'>
          <Flex align='center' direction={{ sm: 'column', md: 'row' }}>
            <Flex
              align='center'
              mb={{ sm: '10px', md: '0px' }}
              direction={{ sm: 'column', md: 'row' }}
              w={{ sm: '100%' }}
              textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
              {/* <Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}> */}
              <Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
                <Text
                  align='top'
                  fontSize={{ sm: 'lg', lg: 'xl' }}
                  color='#fff'
                  fontWeight='bold'
                  ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
                  Manuals page
                </Text>
                {/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
              </Flex>
            </Flex>
            {/* <Stack
                direction={{ sm: "column", lg: "row" }}
                spacing='24px'
                mr='600px'>
                <FormControl width='180px'>
                  <Select
                    bg='#0F1535'
                    border='0.5px solid'
                    borderColor='#E2E8F04D'
                    borderRadius='15px'
                    placeholder='Aircraft type...'
                    fontSize='xs'
                    cursor='pointer'
                    color='gray.400'>
                    <option>Challenger 300</option>
                    <option>Challenger 350</option>
                    <option>Challenger 601</option>
                    <option>Challenger 604</option>
                    <option>Challenger 605</option>
                    <option>Challenger 650</option>
                    <option>Global 5000</option>
                    <option>Global 5500</option>
                    <option>Global 6000</option>
                    <option>Global 6500</option>
                    <option>Global 7500</option>
                    <option>Global XRS</option>
                    <option>Learjet 75</option>
                  </Select>
                </FormControl>
              </Stack> */}
            <Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
              {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineReload size='20px' />}
                onClick={() => {
                  refreshData()
                }}>
                REFRESH
            </Button> */}
              {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  ConnectToServer()
                }}>
                CONNECT
              </Button> */}
              {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  onOpen2()
                }}>
                TEST
              </Button> */}
              {/* <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  resetData()
                }}>
                RESET
              </Button> */}
              {isDisabledVar == false && (
                <Button
                  variant='outline'
                  _hover={{ bg: 'brand.500', color: 'white' }}
                  borderColor='brand.500'
                  color='brand.200'
                  // color='white'
                  // w='150px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "6px", md: "0px" }}
                  // mt='38px'
                  ml='10px'
                  mr='14px'
                  leftIcon={<AiOutlineFullscreen size='20px' />}
                  // pl='1px'
                  onClick={() => {
                    // resetData()
                    onOpen4()
                  }}>
                  FULL SCREEN
                </Button>
              )}
              <SearchBar value={searchValue} placeholderText="Manuals search..." isDisabledVar={isDisabledVar} onChange={(searchBarValue) => setSearchValue(searchBarValue)} onSearch={(searchValueText) => searchInsideManualsFunc(searchValueText)} />
            </Flex>
          </Flex>
        </Card>
      </Box>
      <Tabs index={tabIndex}>
        {/* <Tabs position="relative" variant="unstyled" index={tabIndex}> */}
        <TabList borderColor={'brand.500'}>
          <Tab style={{ boxShadow: 'none' }} _selected={{ color: 'white', bg: 'brand.600' }} onClick={() => { setTabIndex(0) }}>Aircraft type</Tab>
          {/* <Tab isDisabled={secondTabDisabled}>Manual type</Tab> */}
          <Tab style={{ boxShadow: 'none' }} _selected={{ color: 'white', bg: 'brand.600' }} isDisabled={secondTabDisabled} onClick={() => { setTabIndex(1) }}>Manual type</Tab>
          <Tab style={{ boxShadow: 'none' }} _selected={{ color: 'white', bg: 'brand.600' }} isDisabled={thirdTabDisabled} onClick={() => { setTabIndex(2) }}>Manual viewer</Tab>
        </TabList>
        {/* <TabIndicator
        mt="-1.5px"
        height="2px"
        bg="blue.500"
        borderRadius="1px"
      /> */}
        <TabPanels>
          <TabPanel>
            {/* <p>one!</p> */}
            <NameList names={names} onNameClick={handleNameClick} />
          </TabPanel>
          <TabPanel>
            {manualTypeHeader1 && (
              <div>
                <Box py={1} bg="brand.500">
                  <Text ml='10px'>{manualTypeHeader1}</Text>
                </Box>
                {manualCategories1 && (
                  <ManualCategoriesList
                    names={manualCategories1}
                    onNameClick={handleNameClick2}
                  />
                )}
              </div>
            )}
            {manualTypeHeader2 && (
              <div>
                <Box py={1} bg="brand.500">
                  <Text ml='10px'>{manualTypeHeader2}</Text>
                </Box>
                {manualCategories2 && (
                  <ManualCategoriesList
                    names={manualCategories2}
                    onNameClick={handleNameClick2}
                  />
                )}
              </div>
            )}
            {manualTypeHeader3 && (
              <div>
                <Box py={1} bg="brand.500">
                  <Text ml='10px'>{manualTypeHeader3}</Text>
                </Box>
                {manualCategories3 && (
                  <ManualCategoriesList
                    names={manualCategories3}
                    onNameClick={handleNameClick2}
                  />
                )}
              </div>
            )}
            {manualTypeHeader4 && (
              <div>
                <Box py={1} bg="brand.500">
                  <Text ml='10px'>{manualTypeHeader4}</Text>
                </Box>
                {manualCategories4 && (
                  <ManualCategoriesList
                    names={manualCategories4}
                    onNameClick={handleNameClick2}
                  />
                )}
              </div>
            )}
            {/* <Button onClick={() => console.log(manualTypeHeader3)}>TestHeader</Button>
            <Button onClick={() => console.log(manualCategories3)}>TestCategories</Button> */}
          </TabPanel>
          <TabPanel>
            <Card p='0px'>
              {/* <Button onClick={() => console.log(manualsTree)}>manualsTree</Button>
              <Button onClick={() => console.log(convertedTreeData)}>convertedTreeData</Button>
              <Button onClick={() => console.log(manualPath)}>manualPath</Button>
              <Button onClick={() => console.log(manualMdPath)}>manualMdPath</Button> */}
              <Flex>
                <div style={{ width: '350px' }}>
                  {/* <FetchButton></FetchButton> */}
                  {convertedTreeData && (
                    <TreeMenu
                      data={convertedTreeData}
                      onClickItem={(item) => {
                        const { key, label, parent, parentRoute, ...props } = item;
                        // let allItems = logAllData(convertedTreeData);
                        // console.log('Test' + parentRoute)
                        setParentRoute(parentRoute);
                        if (props.icon === "description" || props.icon === "book" || props.icon === "graphic_eq") {
                          // if ()
                          ShowPDF(props.route, manualPath, manualMdPath);
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          });
                        } else if (props.icon === "label_important") {
                          ShowPDF(parentRoute, manualPath, manualMdPath);
                          window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                          });
                        }
                      }}
                    />
                    // <div>
                    //   {/* Render your TreeMenu component with checkboxes */}
                    //   <TreeMenu
                    //     data={convertedTreeData}
                    //     onClickItem={(item) => {
                    //       const { key, label, parent, parentRoute, ...props } = item;

                    //       if (props.icon === "description" || props.icon === "book" || props.icon === "graphic_eq") {
                    //         handleCheckboxChange(item);
                    //       } else if (props.icon === "label_important") {
                    //         // Handle other cases if needed
                    //       }

                    //       // ... Other code ...
                    //     }}
                    //   />

                    //   {/* Render checkboxes and download button */}
                    //   {selectedItems.length > 0 && (
                    //     <div>
                    //       {selectedItems.map((item, index) => (
                    //         <label key={index}>
                    //           <input
                    //             type="checkbox"
                    //             checked={selectedItems.includes(item)}
                    //             onChange={() => handleCheckboxChange(item)}
                    //           />
                    //           {item.label}
                    //         </label>
                    //       ))}
                    //       <button onClick={handleDownloadClick}>Download Selected</button>
                    //     </div>
                    //   )}
                    // </div>

                  )}





                  {/* <TreeMenu data={convertedTreeData} /> */}
                  {/* <p>Parent Route: {parentRoute}</p> */}



                  {/* <TreeMenu>
                {treeData.map(renderTreeNode)}
              </TreeMenu> */}
                </div>
                <div>
                  {/* <iframe src={urlPDFA} type='application/pdf' title="PDF Viewer" width="100%" height="100%" /> */}
                  <DocumentViewer urlPDF={urlPDFA} fileType={fileType}></DocumentViewer>
                </div>
              </Flex>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal isOpen={isLoading} onClose={() => { }}>
        <ModalOverlay />
        <ModalContent bg="rgba(26, 32, 44, 0)" boxShadow='none'>
          <ModalBody display="flex" alignItems="center" justifyContent="center" minHeight="400px" opacity='0.6'>
            <Spinner style={{ "--spinner-size": "14rem" }} color='white' thickness='3px' />
            {/* <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
            /> */}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
        {overlay}
        <ModalContent bg='#1a202c'>
          <ModalHeader color='white' pb='none'>Connection failed</ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody>
            <Flex gap='2' alignItems='center'>
              <Box pl='2'>
                <img src={ConnectionErrorImage} style={{ width: '60px', height: '60px' }} alt="Connection Error" />
              </Box>
              <Box flex='1' p='2'>
                <Text color='white'>Please reconnect</Text>
              </Box>
              <Button
                variant='outline'
                _hover={{ bg: 'brand.500', color: 'white' }}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='14px'
                ml='2px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  // setOverlay(<OverlayOne />)
                  // onOpen2()
                  // saveMaintenanceDataSettings()
                  onClose2()
                  ConnectToServer()
                }}>
                RECONNECT
              </Button>
            </Flex>
            <HSeparator mt="10px"></HSeparator>
            <Box flex='1' pt='1px' pl='20px' pr='20px'>
              <Text color='grey' fontSize='12px'>In case if connection still will be failed, please wait from 2 to 24 hours and try again.</Text>
            </Box>
          </ModalBody>
          {/* <ModalFooter>
          <Button bg="#582CFF" fontSize='12px' color='white' onClick={onClose}>Close</Button>
        </ModalFooter> */}
        </ModalContent>
      </Modal>


      <Modal size='full' isCentered isOpen={isOpen4} onClose={onClose4}>
        {overlay}
        <ModalContent bg='#1a202c'>
          <ModalCloseButton color='white' />
          <ModalBody>
            <DocumentViewer urlPDF={urlPDFA} fileType={fileType}></DocumentViewer>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal size='2xl' isCentered isOpen={isOpen3} onClose={onClose3}>
        {overlay}
        {/* <ModalContent bg='#1a202c' overflowY="auto" maxHeight="90vh"> */}
        <ModalContent
          bg='#1a202c'
          css={{
            overflowY: 'auto',
            maxHeight: '70vh',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#1a202c',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#353d4a',
              borderRadius: '10px',
            },
          }}
        >
          <ModalHeader color='white' pb='none' p='0px'>
            <Box
              // mt='20px'
              mb={{ sm: '24px', md: '50px', xl: '20px' }}
              borderRadius='15px'
              // px='0px'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              align='center'>
              {/* Header */}
              <Card
                direction={{ sm: 'column', md: 'row' }}
                mx='auto'
                maxH='330px'
                w={{ sm: '90%', xl: '100%' }}
                justifyContent={{ sm: 'center', md: 'space-between' }}
                align='center'
                p='10px'
                borderRadius='0px'
                // borderTopLeftRadius='6px'
                // borderTopRightRadius='6px'>
                borderTopRadius='6px'>
                <Flex align='center' direction={{ sm: 'column', md: 'row' }}>
                  <Flex
                    align='center'
                    mb={{ sm: '10px', md: '0px' }}
                    direction={{ sm: 'column', md: 'row' }}
                    w={{ sm: '100%' }}
                    textAlign={{ sm: 'center', md: 'start' }}
                    height='73px'
                    alignItems='center'>
                    {/* <Flex
                  align='center'
                  mb={{ sm: '10px', md: '0px' }}
                  direction={{ sm: 'column', md: 'row' }}
                  w={{ sm: '100%' }}
                  textAlign={{ sm: 'center', md: 'start' }}> */}
                    <Flex direction='row' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
                      <SearchIcon mt='6px' mr='8px'></SearchIcon>
                      <Text
                        align='top'
                        fontSize={{ sm: 'lg', lg: 'xl' }}
                        color='#fff'
                        fontWeight='bold'
                        ms={{ sm: '8px', md: '0px' }}
                        ml='20px'>
                        Search results
                      </Text>
                      {/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
                      Fill all empty fields
                    </Text> */}
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody>
            <Grid
              templateColumns='1fr'
              gap='22px'
              mb='24px'>
              {foundSearchData.data && foundSearchData.data.result && foundSearchData.data.result.map((item, index) => (
                <div key={index}>
                  <Card
                    p='16px'
                    maxH={{ md: '410px' }}
                    maxW={{ sm: '325px', md: '725px', lg: '980px' }}
                    gridArea={{ xl: '1 / 2 / 2 / 3', '2xl': 'auto' }}
                    onClick={() => {
                      ShowPDF(cutRoute(item.item.path), manualPath, manualMdPath);
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                      onClose3();
                    }}
                    _hover={{ backgroundColor: "#4f5f80", cursor: 'pointer' }}>
                    <CardHeader p='0px 5px 12px 5px' >
                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                        {item.item.pageBlock}
                      </Text>
                    </CardHeader>
                    <HSeparator mb='10px' />
                    <CardBody px='5px'>
                      <Flex direction='column'>
                        <Flex align='center' mb='6px'>
                          <Text fontSize='sm' color={'gray.400'} me='10px'>
                            Publication:{' '}
                          </Text>
                          <Text fontSize='sm' color='#fff' fontWeight='400'>
                            {item.item.manual}
                          </Text>
                        </Flex>
                        <Flex align='center' mb='6px'>
                          <Text fontSize='sm' color={'gray.400'} me='10px'>
                            Chapter:{' '}
                          </Text>
                          <Text fontSize='sm' color='#fff' fontWeight='400'>
                            {item.item.chapter}
                          </Text>
                        </Flex>
                        <Flex align='center' mb='6px'>
                          <Text fontSize='sm' color={'gray.400'} me='10px'>
                            Section:{' '}
                          </Text>
                          <Text fontSize='sm' color='#fff' fontWeight='400'>
                            {item.item.section}
                          </Text>
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                  {/* <hr /> */}
                </div>
              ))}
            </Grid>
          </ModalBody>
          {/* <ModalFooter>
          <Button bg="#582CFF" fontSize='12px' color='white' onClick={onClose}>Close</Button>
        </ModalFooter> */}
        </ModalContent>
      </Modal>
    </div>
  )
}

export default MenuTree;
