import { Box, Text } from '@chakra-ui/react';

import RedHat from './red_hat.png'
import Fireworks from './Fireworks.png'
import ChristmasLights from './christmas_lights.png'

const NewYearEffect = () => {
  const sidebarWidth = 275; // Replace this with your actual sidebar width
  const logoText = 'Avia Office'; // Change this to your desired New Year message

  return (
    <Box
      position='relative'
      bg='linear-gradient(97.89deg, #FFFFFF 70.67%, rgba(117, 122, 140, 0) 108.55%)'
      bgClip='text'
    >
      {/* Fireworks SVG */}
      <Box position='absolute' top='4' left='-1'>
        {/* Replace 'fireworks.svg' with the actual path to your fireworks SVG file */}
        <img src={ChristmasLights} alt='Fireworks' width='100' height='100' style={{ opacity: '60%' }} />
        {/* <img src={Fireworks} alt='Fireworks' width='100' height='100' style={{ filter: 'grayscale(40%)', opacity: '60%' }} /> */}
      </Box>

      {/* New Year Hat SVG */}
      <Box position='absolute' top='10%' left='0%' transform='translate(-50%, -50%)'>
        {/* Replace 'new_year_hat.svg' with the actual path to your New Year hat SVG file */}
        <img src={RedHat} alt='New Year Hat' width='40' height='40' style={{ filter: 'grayscale(10%)', opacity: '80%' }} />
      </Box>

      {/* Text */}
      <Text
        fontWeight='800'
        fontSize='sm'
        mt='3px'
        display={sidebarWidth === 275 ? 'block' : 'none'}
        color='transparent'
        letterSpacing='0.18em'
      >
        {logoText}
      </Text>
    </Box>
  );
};

export default NewYearEffect;
