/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useRef, useState } from "react";

// Chakra imports
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDropzone } from "react-dropzone";

// Images
import avatar11 from "assets/img/avatars/avatar11.png";

// Icons
import { RocketIcon } from "components/Icons/Icons";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { FaCube } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import addNolan from "assets/svg/add-nolan.svg";
import listNolan from "assets/svg/list-nolan.svg";
import sourceCodeNolan from "assets/svg/source-code-nolan.svg";

// ApiAxios
import { addProgramType } from "network/ApiAxios";

function ProgramsAdd() {
  const textColor = "white";
  const bgPrevButton = "white";

  const { getRootProps, getInputProps } = useDropzone();

  // Set active bullets based on current state
  const [activeBullets, setActiveBullets] = useState({
    about: true,
    account: false,
    address: false,
  });

  const [checkboxes, setCheckboxes] = useState({
    selectA: false,
    selectB: false,
    selectC: false,
  });

  // Tabs references
  const selectTab = useRef();
  const addProgramTab = useRef();
  const resultTab = useRef();

  // const [showToast, setShowToast] = useState(false);
  // const [toastMessage, setToastMessage] = useState("Email sent! Check it to reset your password.");

  // const registerUser = async () => {
  //   if (!(name && email && password && userType && checkbox==true)) {
  //       toast({
  //         title: `Not registered`,
  //         description: 'Make sure to fill all the inputs and agree to the privacy policy',
  //         status: 'error',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //       // setError("Make sure to fill all the inputs and agree to the privacy policy");
  //       return;
  // }

  // Toast notification
  const toast = useToast()

  // Requests to server

  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [background, setBackground] = useState('');
  const [description, setDescription] = useState('');
  const [tag, setTag] = useState('');
  const [roles, setRoles] = useState('');
  const [departments, setDepartments] = useState('');

  const addProgramType2 = async (programId) => {
    const { data } = await addProgramType(title, subtitle, background, description, tag, roles, departments);
    console.log('test3')
    console.log(data)
  };

  return (
    <Flex
      direction='column'
      // minH='1vh'
      align='center'
      // pt={{ sm: "125px", lg: "75px" }}
      >
      <Flex
        direction='column'
        textAlign='center'
        // mb={{ sm: "25px", md: "45px" }}
        >
        {/* <Text
          color={textColor}
          fontSize={{ sm: "2xl", md: "3xl", lg: "4xl" }}
          fontWeight='bold'
          mb='8px'>
          Build your profile
        </Text>
        <Text color='white' fontWeight='500' fontSize={{ sm: "sm", md: "lg" }}>
          This information will let us know more about you.
        </Text> */}
      </Flex>
      <Tabs variant='unstyled' display='flex' flexDirection='column'>
        {" "}
        <TabList display='flex' align='center' justifyContent='center'>
          <Tab
            ref={selectTab}
            _focus='none'
            w={{ sm: "120px", md: "250px", lg: "300px" }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: false,
                address: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: "120px", md: "250px", lg: "300px" },
                height: "3px",
                bg: activeBullets.account ? "#fff" : "#182058",
                left: { sm: "12px", md: "32px" },
                top: { sm: activeBullets.about ? "8px" : "6px", md: null },
                position: "absolute",
                bottom: activeBullets.about ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}>
              <Icon
                as={BsCircleFill}
                color={activeBullets.about ? "#fff" : "brand.200"}
                w='20px'
                h='20px'
                border={activeBullets.about ? "none" : "1px solid"}
                borderColor={activeBullets.about ? "#fff" : "#182058"}
                borderRadius='50%'
                mb='8px'
              />
              <Text
                color='#fff'
                fontWeight={activeBullets.about ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}>
                Select
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={addProgramTab}
            _focus='none'
            w={{ sm: "120px", md: "250px", lg: "305px" }}
            onClick={() =>
              setActiveBullets({
                about: true,
                account: true,
                address: false,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'
              _before={{
                content: "''",
                width: { sm: "120px", md: "250px", lg: "300px" },
                height: "3px",
                bg: activeBullets.address ? "#fff" : "#182058",
                left: { sm: "12px", md: "32px" },
                top: { sm: activeBullets.account ? "8px" : "8px", md: null },
                position: "absolute",
                bottom: activeBullets.account ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}>
              <Icon
                as={BsCircleFill}
                color={activeBullets.account ? "#fff" : "brand.200"}
                w='20px'
                h='20px'
                border={activeBullets.account ? "none" : "1px solid"}
                borderColor={activeBullets.account ? "#fff" : "#182058"}
                borderRadius='50%'
                mb='8px'
              />
              <Text
                color='#fff'
                fontWeight={activeBullets.account ? "bold" : "normal"}
                transition='all .3s ease'
                _hover={{ color: "#fff" }}
                display={{ sm: "none", md: "block" }}>
                Form
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={resultTab}
            _focus='none'
            w={{ sm: "120px", md: "250px", lg: "300px" }}
            onClick={() => 
              setActiveBullets({
                about: true,
                account: true,
                address: true,
              })
            }>
            <Flex
              direction='column'
              justify='center'
              align='center'
              position='relative'>
              <Icon
                as={BsCircleFill}
                color={activeBullets.address ? "#fff" : "brand.200"}
                w='20px'
                h='20px'
                border={activeBullets.address ? "none" : "1px solid"}
                borderColor={activeBullets.address ? "#fff" : "#182058"}
                borderRadius='50%'
                mb='8px'
              />
              <Text
                color='#fff'
                fontWeight={activeBullets.address ? "bold" : "normal"}
                transition='all .3s ease'
                _hover={{ color: "#fff" }}
                display={{ sm: "none", md: "block" }}>
                Result
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
        <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx='auto'>
            <Card bg='linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)'>
              <CardHeader mb='40px'>
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  textAlign='center'
                  w='80%'
                  mx='auto'>
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='4px'>
                    What do you want to do?
                  </Text>
                  <Text color='gray.400' fontWeight='500' fontSize='sm'>
                    Please select which operation do you want to do.
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack
                    direction={{ sm: "column", md: "row" }}
                    spacing={{ sm: "20px", lg: "35px" }}
                    alignSelf='center'
                    justifySelf='center'
                    mb='24px'>
                    <Flex direction='column' align='center'>
                      <FormLabel w='150px' h='150px' cursor='pointer' mb='16px'>
                        <Flex
                          w='100%'
                          h='100%'
                          borderRadius='12px'
                          justify='center'
                          transition='.5s all ease'
                          border='2px solid'
                          borderColor='brand.200'
                          align='center'
                          bg={checkboxes.selectA ? "brand.700" : "transparent"}
                          _hover={{ opacity: "0.7" }}>
                          <Checkbox
                            onChange={() =>
                              setCheckboxes((prevCheckboxes) => {
                                return {
                                  ...prevCheckboxes,
                                  selectA: !prevCheckboxes.selectA,
                                  selectB: false,
                                  selectC: false
                                };
                              })
                            }
                            display='none'
                          />
                          {/* <Icon
                            as={AiFillSetting}
                            w='54px'
                            h='54px'
                            color='#fff'
                          /> */}
                          <Image src={addNolan} w='100px' h='100px' alignSelf='center' justifySelf='center' />
                        </Flex>
                      </FormLabel>
                      <Text color={textColor} fontWeight='bold' fontSize='md'>
                        Create
                      </Text>
                    </Flex>
                    <Flex direction='column' align='center'>
                      <FormLabel w='150px' h='150px' cursor='pointer' mb='16px'>
                        <Flex
                          w='100%'
                          h='100%'
                          borderRadius='12px'
                          justify='center'
                          transition='.5s all ease'
                          border='2px solid'
                          borderColor='brand.200'
                          align='center'
                          bg={checkboxes.selectB ? "brand.700" : "transparent"}
                          _hover={{ opacity: "0.7" }}>
                          <Checkbox
                            onChange={() =>
                              setCheckboxes((prevCheckboxes) => {
                                return {
                                  ...prevCheckboxes,
                                  selectA: false,
                                  selectB: !prevCheckboxes.selectB,
                                  selectC: false
                                };
                              })
                            }
                            display='none'
                          />
                          {/* <Icon as={FaCube} w='54px' h='54px' color='#fff' /> */}
                          <Image src={listNolan} w='100px' h='100px' alignSelf='center' justifySelf='center' />
                        </Flex>
                      </FormLabel>
                      <Text color={textColor} fontWeight='bold' fontSize='md'>
                        Select
                      </Text>
                    </Flex>
                    <Flex direction='column' align='center'>
                      <FormLabel w='150px' h='150px' cursor='pointer' mb='16px'>
                        <Flex
                          w='100%'
                          h='100%'
                          borderRadius='12px'
                          justify='center'
                          transition='.5s all ease'
                          border='2px solid'
                          borderColor='brand.200'
                          align='center'
                          bg={checkboxes.selectC ? "brand.700" : "transparent"}
                          _hover={{ opacity: "0.7" }}>
                          <Checkbox
                            onChange={() =>
                              setCheckboxes((prevCheckboxes) => {
                                return {
                                  ...prevCheckboxes,
                                  selectA: false,
                                  selectB: false,
                                  selectC: !prevCheckboxes.selectC
                                };
                              })
                            }
                            display='none'
                          />
                          {/* <Icon
                            as={RocketIcon}
                            w='54px'
                            h='54px'
                            color='#fff'
                          /> */}
                          <Image src={sourceCodeNolan} w='100px' h='100px' alignSelf='center' justifySelf='center' />
                        </Flex>
                      </FormLabel>
                      <Text color={textColor} fontWeight='bold' fontSize='md'>
                        Develop
                      </Text>
                    </Flex>
                  </Stack>
                  <Button
                    variant='brand'
                    alignSelf='flex-end'
                    mt='24px'
                    w={{ sm: "75px", lg: "100px" }}
                    h='35px'
                    onClick={() => {
                      if (checkboxes.selectA == true) {
                        addProgramTab.current.click()
                      } else if (checkboxes.selectB == true) {
                        // addProgramTab.current.click()
                        toast({
                          title: `In development`,
                          description: 'This feature is currently still in development',
                          status: 'warning',
                          variant: 'left-accent',
                          position: 'bottom-right',
                          isClosable: true,
                        })
                      } else if (checkboxes.selectC == true) {
                        // addProgramTab.current.click()
                        toast({
                          title: `In development`,
                          description: 'This feature is currently still in development',
                          status: 'warning',
                          variant: 'left-accent',
                          position: 'bottom-right',
                          isClosable: true,
                        })
                      } else {
                      }
                    }}>
                    <Text fontSize='xs' color='#fff' fontWeight='bold'>
                      NEXT
                    </Text>
                  </Button>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx='auto'>
            <Card>
              <CardHeader mb='40px'>
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  textAlign='center'
                  w='80%'
                  mx='auto'>
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='4px'>
                    Add new program
                  </Text>
                  <Text color='gray.400' fontWeight='500' fontSize='sm'>
                    Information about programs you can find in programs list(previous page).
                    Please fill all empty fields to add new program.
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    w='100%'
                    mb='24px'>
                    <Box
                      position='relative'
                      minW={{ sm: "110px", xl: "150px" }}
                      h={{ sm: "110px", xl: "150px" }}
                      mx={{ sm: "auto", md: "40px", xl: "85px" }}
                      mb={{ sm: "25px" }}>
                      <Avatar
                        src={avatar11}
                        w='100%'
                        h='100%'
                        borderRadius='12px'>
                      </Avatar>
                    </Box>
                    <Stack direction='column' spacing='20px' w='100%'>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'>
                          Title
                        </FormLabel>
                        <Input
                          color='gray.400'
                          bg='#0F1535'
                          border='0.5px solid'
                          borderColor='#E2E8F04D'
                          borderRadius='15px'
                          placeholder='Example: Converter'
                          fontSize='xs'
                          value={title}
                          onChange={e => setTitle(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'>
                          Subtitle
                        </FormLabel>
                        <Input
                          color='gray.400'
                          bg='#0F1535'
                          border='0.5px solid'
                          borderColor='#E2E8F04D'
                          borderRadius='15px'
                          placeholder='Example: Convert currency'
                          fontSize='xs'
                          value={subtitle}
                          onChange={e => setSubtitle(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'>
                          Description
                        </FormLabel>
                        <Input
                          color='gray.400'
                          bg='#0F1535'
                          border='0.5px solid'
                          borderColor='#E2E8F04D'
                          borderRadius='15px'
                          placeholder='Example: Currency converter program with our exchange rates and fees.'
                          fontSize='xs'
                          value={description}
                          onChange={e => setDescription(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'>
                          Tag
                        </FormLabel>
                        <Input
                          color='gray.400'
                          bg='#0F1535'
                          border='0.5px solid'
                          borderColor='#E2E8F04D'
                          borderRadius='15px'
                          placeholder='Example: FINANCIAL'
                          fontSize='xs'
                          value={tag}
                          onChange={e => setTag(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'>
                          Roles
                        </FormLabel>
                        <Input
                          color='gray.400'
                          bg='#0F1535'
                          border='0.5px solid'
                          borderColor='#E2E8F04D'
                          borderRadius='15px'
                          placeholder='Example: staff'
                          fontSize='xs'
                          value={roles}
                          onChange={e => setRoles(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize='xs'
                          fontWeight='bold'>
                          Departments
                        </FormLabel>
                        <Input
                          color='gray.400'
                          bg='#0F1535'
                          border='0.5px solid'
                          borderColor='#E2E8F04D'
                          borderRadius='15px'
                          placeholder='Example: Accounting'
                          fontSize='xs'
                          value={departments}
                          onChange={e => setDepartments(e.target.value)}
                        />
                      </FormControl>
                      <FormControl mt='20px'>
                        <Flex direction='column' w='100%'>
                          <Text
                            color={textColor}
                            fontSize='sm'
                            fontWeight='bold'
                            mb='12px'>
                            Background image
                          </Text>
                          <Flex
                            align='center'
                            justify='center'
                            border='0.5px dashed #E2E8F04D'
                            borderRadius='15px'
                            w='100%'
                            minH='130px'
                            cursor='pointer'
                            {...getRootProps({ className: "dropzone" })}>
                            <Input
                              color='gray.400'
                              bg='#0F1535'
                              border='0.5px solid'
                              borderColor='#E2E8F04D'
                              borderRadius='15px'
                              {...getInputProps()}
                            />
                            <Button variant='no-hover'>
                              <Text color='gray.400' fontWeight='500'>
                                Drop files here to upload
                              </Text>
                            </Button>
                          </Flex>
                        </Flex>
                      </FormControl>
                    </Stack>
                  </Flex>
                  <Flex justify='space-between'>
                    <Button
                      variant='no-hover'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'
                      onClick={() => selectTab.current.click()}>
                      <Text fontSize='xs' color='#313860' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'
                      onClick={() => {
                        resultTab.current.click()
                        toast({
                          title: `Not registered`,
                          description: 'Make sure to fill all the inputs and agree to the privacy policy',
                          status: 'error',
                          variant: 'left-accent',
                          position: 'bottom-right',
                          isClosable: true,
                        })
                        addProgramType2()
                      }}>
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        NEXT
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx='auto'>
            <Card>
              <CardHeader mb='40px'>
                <Flex
                  direction='column'
                  align='center'
                  justify='center'
                  textAlign='center'
                  w='80%'
                  mx='auto'>
                  <Text
                    color={textColor}
                    fontSize='lg'
                    fontWeight='bold'
                    mb='4px'>
                    In development
                  </Text>
                  <Text color='gray.400' fontWeight='500' fontSize='sm'>
                    This functionality is still in development and will be available soon.
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    {/* <Text>Test</Text> */}
                  </Stack>
                  <Flex justify='space-between'>
                    <Button
                      variant='no-hover'
                      bg={bgPrevButton}
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'
                      onClick={() => addProgramTab.current.click()}>
                      <Text fontSize='xs' color='#313860' fontWeight='bold'>
                        PREV
                      </Text>
                    </Button>
                    <Button
                      variant='no-hover'
                      bg='brand.200'
                      alignSelf='flex-end'
                      mt='24px'
                      w={{ sm: "75px", lg: "100px" }}
                      h='35px'>
                      <Text fontSize='xs' color='#fff' fontWeight='bold'>
                        SEND
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default ProgramsAdd;
