/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useRef, useState } from "react";

import { CSSTransition } from 'react-transition-group';

// Chakra imports
import {
  Avatar,
  AvatarBadge,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Checkbox,
  CloseButton,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  ScaleFade,
  Select,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useDropzone } from "react-dropzone";
import ProgramsListItem from "components/Programs/ProgramsListItem";
import { HSeparator } from "components/Separator/Separator";

// Images
import avatar11 from "assets/img/avatars/avatar11.png";
import bgimage1 from 'assets/img/programs/forex-stock-market-trade-concept-trading-floor-board-with-currency-exchange-rates-and-graph_r0glflrmg_thumbnail-1080_01.png'
import bgimage2 from 'assets/img/programs/istockphoto-1085649282-612x612.jpg'
import bgimage3 from 'assets/img/programs/document_shutterstock_1416401996.jpg'
import bgimage4 from 'assets/img/programs/3073697-account_black-and-white_business_commerce_data_documents_files_finance_information_list_numbers_office_papers_research.jpg'
import bgimage5 from 'assets/img/programs/addJobCards.jpg'

// Icons
import { RocketIcon } from "components/Icons/Icons";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { FaCube } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import addNolan from "assets/svg/add-nolan.svg";
import listNolan from "assets/svg/list-nolan.svg";
import sourceCodeNolan from "assets/svg/source-code-nolan.svg";


function ProgramsAdd() {
  const textColor = "white";
  const bgPrevButton = "white";

  const { getRootProps, getInputProps } = useDropzone();

  // Set active bullets based on current state
  const [activeBullets, setActiveBullets] = useState({
    about: true,
    account: false,
    address: false,
  });

  const [checkboxes, setCheckboxes] = useState({
    design: false,
    code: false,
    develop: false,
  });

  // Tabs references
  const aboutTab = useRef();
  const accountTab = useRef();
  const addressTab = useRef();

  const programTypesArray = [
    {
      'title': "Oanda",
      'subtitle': "Currency converter",
      'background': bgimage1,
      'description': "Currency converter program with our exchange rates and fees.",
      'tag': "FINANCES",
      'roles': ["admin", "member"],
      'departments': "All"
    },
    {
      'title': "Parts search",
      'subtitle': "Find parts prices",
      'background': bgimage2,
      'description': "Currency converter program with our exchange rates and fees.",
      'tag': "FINANCES",
      'roles': ["admin", "member"],
      'departments': "All"
    },
    {
      'title': "Revisions",
      'subtitle': "Update revisions",
      'background': bgimage3,
      'description': "Currency converter program with our exchange rates and fees.",
      'tag': "FINANCES",
      'roles': ["admin", "member"],
      'departments': "All"
    },
    {
      'title': "AMM",
      'subtitle': "Download manuals",
      'background': bgimage4,
      'description': "Currency converter program with our exchange rates and fees.",
      'tag': "FINANCES",
      'roles': ["admin", "member"],
      'departments': "All"
    },
    {
      'title': "Add Job Cards",
      'subtitle': "Add tasks to Blue Eye",
      'background': bgimage5,
      'description': "Currency converter program with our exchange rates and fees.",
      'tag': "FINANCES",
      'roles': ["admin", "member"],
      'departments': "All"
    },
  ]

  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true })

  return (
    <Flex justify='center' mx='auto'>
      <Flex align='center' direction='column'>
        {/* use after some time ScaleFade component */}
        {isVisible ? 
          <DarkMode>
            <Alert status="info" transition='2s'>
              <AlertIcon />
              <AlertTitle mr={2}>Info:</AlertTitle>
              <AlertDescription>In development!</AlertDescription>
              <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
            </Alert>
          </DarkMode>
        : null}
        {/* <DarkMode>
          <Alert status="info" visibility={!isVisible ? "hidden" : "visible"}>
            <AlertIcon />
            <AlertTitle mr={2}>Info:</AlertTitle>
            <AlertDescription>In development!</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
          </Alert>
        </DarkMode> */}
        <Card
          bg='linear-gradient(126.97deg, rgba(6, 11, 40, 0.74) 28.26%, rgba(10, 14, 35, 0.71) 91.2%)'
          maxW={{ lg: "800px" }}
          mt='20px'>
                    <Flex direction='column' maxWidth='100%'>
            <Text align='center' fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
            Select programs that you want to use
          </Text>
        </Flex>
          <CardBody>
            <Flex direction='column' w='100%'>
              <HSeparator my='22px' />
                {programTypesArray.map((programItem) => (
                  <div onClick={() => getTitle(programItem.title)}>
                    <ProgramsListItem title={programItem.title} subtitle={programItem.subtitle} background={programItem.background} description={programItem.description} tag={programItem.tag} roles={programItem.roles} departments={programItem.departments} onClick={() => getTitle(programItem.title)}></ProgramsListItem>
                  </div>
                ))}
              {/* <HSeparator my='22px' /> */}
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Flex>
  );
}

export default ProgramsAdd;
