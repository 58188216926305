import React, { useState, useEffect } from 'react';
import xml2js from 'xml2js';
import FetchButton from 'components/Other/ManualsProgram/FetchButton';

import axios from "axios";

import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Input,
  Icon,
  Image,
  ListItem,
  UnorderedList,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  MenuGroup,
  MenuDivider,
  Box,
  useToast,
  Stack,
  LightMode,
  DarkMode,
  Switch,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Checkbox,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  FormControl,
  FormLabel,
  Grid
} from "@chakra-ui/react";

import ListGroup from 'react-bootstrap/ListGroup';

import TreeMenu from 'react-simple-tree-menu';
import './main.css';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import { BiReset } from "react-icons/bi";
import { AiFillSave, AiOutlineSetting, AiOutlineLink, AiOutlineReload, AiOutlineSearch, AiOutlinePicture, AiOutlineDatabase, AiOutlineDownload } from "react-icons/ai";
import { Element, Link } from "react-scroll";
import SearchTablePartsPrices from "components/Tables/SearchTablePartsPrices";

import { getMaintenanceDataFromBombardier, postEmbraerAuthDB, getEmbraerAuthDB, getMaintenanceDataFromGulfstream, postGulfstreamAuthDB, getGulfstreamAuthDB } from '../../helpers/backend_helper';

import Spreadsheet from "react-spreadsheet";
import styled from 'styled-components';

// Icons
import {
  AtlassianLogo,
  InvisionLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons";

// Images
import AerospheresImage from "assets/img/parts_prices/aerospheres.png";
import BoeingImage from "assets/img/parts_prices/boeing.png";
import BombardierImage from "assets/img/parts_prices/bombardier.png";
import EmbraerImage from "assets/img/parts_prices/embraer.png";
import GulfstreamImage from "assets/img/parts_prices/gulfstream.png";
import LasImage from "assets/img/parts_prices/las.png";
import SilmidImage from "assets/img/parts_prices/silmid.png";
import DasiImage from "assets/img/parts_prices/dasi.png";

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

// Data
import { columnsDataPartsPrices } from "variables/columnsData";
import tableDataPartsPrices from "variables/tableDataPartsPrices.json";


const MaintenanceData = () => {

  const DarkSpreadsheet = styled(Spreadsheet)`
    // background-color: #222;
    background-color:rgba(0, 0, 0, 0.5);
    // border-color: #a8a8a8;
    // color: #e8e8e8;
    // background-opacity: 0;

    .Spreadsheet__header {
      // background-color: #130740;
      background-color: #222;
      border-color: #5c5c5c;
      color: #e8e8e8;
    }

    .Spreadsheet__cell {
      background-color: #444;
      border-color: #5c5c5c;
      color: #e8e8e8;
    }

    .Spreadsheet__cell--selected {
      background-color: #666;
    }
  `;
  const bgActiveButton = "brand.200";
  const bgHoverLinks = "brand.200";
  const bgVerificationCard = "#0F1535";

  const [isLoading, setIsLoading] = useState(false);

  const OverlayOne = () => (
    <ModalOverlay
    bg='rgba(0, 0, 0, 0.8)'
    backdropFilter='hue-rotate(10deg)'
  >
  </ModalOverlay>
  )
  // const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure()
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)

  const [switchBombardier, setSwitchBombardier] = useState(true);
  const [switchEmbraer, setSwitchEmbraer] = useState(false);
  const [switchGulfstream, setSwitchGulfstream] = useState(false);
  const [switchScreenshots, setSwitchScreenshots] = useState(false);

  const [embraerCookie1, setEmbraerCookie1] = useState();
  const [embraerCookie2, setEmbraerCookie2] = useState();
  const [embraerCookie3, setEmbraerCookie3] = useState();
  const [embraerCookie4, setEmbraerCookie4] = useState();
  const [embraerCookie5, setEmbraerCookie5] = useState();
  const [embraerCookie6, setEmbraerCookie6] = useState();
  const [embraerCookie7, setEmbraerCookie7] = useState();

  const [gulfstreamCookie1, setGulfstreamCookie1] = useState();
  const [gulfstreamCookie2, setGulfstreamCookie2] = useState();
  const [gulfstreamCookie3, setGulfstreamCookie3] = useState();
  const [gulfstreamCookie4, setGulfstreamCookie4] = useState();
  const [gulfstreamCookie5, setGulfstreamCookie5] = useState();
  const [gulfstreamCookie6, setGulfstreamCookie6] = useState();


  const handleSwitchBombardierChange = () => {
    setSwitchBombardier(!switchBombardier);
  }
  const handleSwitchEmbraerChange = () => {
    setSwitchEmbraer(!switchEmbraer);
  }
  const handleSwitchGulfstreamChange = () => {
    setSwitchGulfstream(!switchGulfstream);
  }
  const handleSwitchScreenshotsChange = () => {
    setSwitchScreenshots(!switchScreenshots);
  }
  
  const resetSuppliersSwitches = () => {
    setSwitchBombardier(false);
    setSwitchEmbraer(false);
    setSwitchGulfstream(false);
  }

  const saveToLocalStorage = () => {
    const listOfSwitches = {
      switchBombardier,
      switchEmbraer,
      switchGulfstream,
    };

    localStorage.setItem('maintenance_data_settings', JSON.stringify(listOfSwitches));
    // You can replace 'myData' with your preferred key name
  };

  const loadFromLocalStorage = () => {
    const savedData = localStorage.getItem('maintenance_data_settings');
    // You can replace 'myData' with your preferred key name

    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setSwitchBombardier(parsedData.switchBombardier);
      setSwitchEmbraer(parsedData.switchEmbraer);
      setSwitchGulfstream(parsedData.switchGulfstream);
    }
  };

  const toast = useToast()

  function formatDate(dateString) {
    // Array of month names in English
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    // Convert dateString to a string
    dateString = String(dateString);
  
    // Extract year, month, and day from the input string
    const year = dateString.substr(0, 4);
    const month = Number(dateString.substr(4, 2)) - 1; // Subtract 1 to match array index
    const day = dateString.substr(6, 2);
  
    // Format the date as "day-Month-Year"
    const formattedDate = `${day}-${monthNames[month]}-${year}`;
  
    return formattedDate;
  }

  // const [dataSpreadsheet_CH300, setDataSpreadsheet_CH300] = useState([
  //   [{ value: "No" },{ value: "Description of Maintenance Data",
  //   DataViewer: ({ cell }) => (
  //     <div
  //       style={{
  //         backgroundColor: "red",
  //         height: "100%",
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center"
  //       }}
  //     >
  //       {cell.value}
  //     </div>
  //   ) },
  //   { value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  //   [{ value: "" },{ value: "" },{ value: "" },{ value: "" },{ value: "" },{ value: "" },{ value: "" },{ value: "" }],
  // ]);


  // Bombardier
  const [dataSpreadsheet_CH300, setDataSpreadsheet_CH300] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_CH350, setDataSpreadsheet_CH350] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_CH601, setDataSpreadsheet_CH601] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_CH604, setDataSpreadsheet_CH604] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_CH605, setDataSpreadsheet_CH605] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_CH650, setDataSpreadsheet_CH650] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_CH850, setDataSpreadsheet_CH850] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_GL5000, setDataSpreadsheet_GL5000] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_GL5500, setDataSpreadsheet_GL5500] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_GL6000, setDataSpreadsheet_GL6000] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_GL6500, setDataSpreadsheet_GL6500] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_GL7500, setDataSpreadsheet_GL7500] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_GLXRS, setDataSpreadsheet_GLXRS] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  // Gulfstream
  const [dataSpreadsheet_GIV, setDataSpreadsheet_GIV] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_G550, setDataSpreadsheet_G550] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_G450, setDataSpreadsheet_G450] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_G650, setDataSpreadsheet_G650] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);
  const [dataSpreadsheet_G200, setDataSpreadsheet_G200] = useState([
    [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
  ]);

  // let testArray = [
  //   {
  //     id: "#4",
  //     pn: "MS9068-0082",
  //     description: "PACKING, PERFORMED, O-RING",
  //     supplier: "Bombardier",
  //     priceNew: "$2.64",
  //     priceRepaired: "",
  //     priceOverhauled: "",
  //     date: "04/05/2023"
  //   },
  //   {
  //     id: "#2",
  //     pn: "MS35769-92",
  //     description: "GASKET",
  //     supplier: "Bombardier",
  //     priceNew: "$3.51",
  //     priceRepaired: "",
  //     priceOverhauled: "",
  //     date: "03/05/2023"
  //   },
  //   {
  //     id: "#1",
  //     pn: "100-200-342",
  //     description: "HSTA",
  //     supplier: "Bombardier",
  //     priceNew: "$73,146.00",
  //     priceRepaired: "$21,146.00",
  //     priceOverhauled: "$23,146.00",
  //     date: "03/05/2023"
  //   }
  // ]

  // const [tableDataPartsPricesList, setTableDataPartsPricesList] = useState(testArray);
  const [tableDataPartsPricesList, setTableDataPartsPricesList] = useState([]);

  const ConnectToServer = async () => {
    setIsLoading(true);
    const promises = [];
  
    if (switchBombardier) {
      promises.push(authorizeBombardier());
    }
  
    try {
      const results = await Promise.all(promises);
  
      results.forEach((data, index) => {
        const serviceName = getServiceName(index);
  
        if (data?.status === true) {
          toast({
            title: `Connection success`,
            description: `Connected to the ${serviceName}`,
            status: 'success',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          });
        } else {
          toast({
            title: `Connection error`,
            description: `Failed to connect to the ${serviceName}`,
            status: 'error',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          });
        }
      });
  
      setIsLoading(false);
    } catch (error) {
      console.error('Error occurred:', error);
      setIsLoading(false);
    }
  };

  // const ConnectToServer = async () => {
  //   // setIsLoading(true)
  //   if (switchBombardier == true) {
  //     authorizeBombardier().then(data => {
  //       if (data?.status === true) {
  //         toast({
  //           title: `Connection success`,
  //           description: 'Connected to the Bombardier',
  //           status: 'success',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       } else {
  //         toast({
  //           title: `Connection error`,
  //           description: 'Failed to connect to the Bombardier',
  //           status: 'error',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       }
  //     })
  //   } else {}
  //   if (switchLas == true) {
  //     authorizeLas().then(data => {
  //       if (data?.status === true) {
  //         toast({
  //           title: `Connection success`,
  //           description: 'Connected to the Las',
  //           status: 'success',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       } else {
  //         toast({
  //           title: `Connection error`,
  //           description: 'Failed to connect to the Las',
  //           status: 'error',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       }
  //     })
  //   } else {}
  //   if (switchSilmid == true) {
  //     authorizeSilmid().then(data => {
  //       if (data?.status === true) {
  //         toast({
  //           title: `Connection success`,
  //           description: 'Connected to the Silmid',
  //           status: 'success',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       } else {
  //         toast({
  //           title: `Connection error`,
  //           description: 'Failed to connect to the Silmid',
  //           status: 'error',
  //           variant: 'left-accent',
  //           position: 'bottom-right',
  //           isClosable: true,
  //         })
  //       }
  //     })
  //   } else {}
  // }

  // const ConnectToServer = async () => {
  //   const dataSpreadsheet1 = dataSpreadsheet.map(innerArray =>
  //     innerArray.map(item => item === undefined ? { value: '' } : item)
  //   );
  //   getOnePartPrice(JSON.stringify(dataSpreadsheet1)).then(data => {
  //     setDataSpreadsheet(data?.data)
  //     console.log(data?.data)
  //     if (data?.status === true) {
  //       toast({
  //         title: `Connection success`,
  //         description: 'Connected to the server',
  //         status: 'success',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //     } else {
  //       toast({
  //         title: `Connection error`,
  //         description: 'Failed to connect to the server',
  //         status: 'error',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //     }
  //   })
  // }

  const resetData = async () => {
    setIsLoading(true)
    resetPortalsAuthorization().then(data => {
      if (data?.status === true) {
        setIsLoading(false)
        toast({
          title: `Data successfully reseted`,
          description: 'Connection data successfully reseted',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        setIsLoading(false)
        toast({
          title: `Reset error`,
          description: 'Failed to reset server data',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }


  const createPartsPricesFunc = async () => {
    setIsLoading(true)
    const dataSpreadsheet2 = dataSpreadsheet.map(innerArray =>
      innerArray.map(item => item === undefined ? { value: '' } : item)
    );
    createPartsPrices(dataSpreadsheet2).then(data => {
      if (data?.status === true) {
        setIsLoading(false)
        toast({
          title: `Data successfully reseted`,
          description: 'Connection data successfully reseted',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        setIsLoading(false)
        toast({
          title: `Reset error`,
          description: 'Failed to reset server data',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
    setTimeout(() => { getPartsPricesFunc() }, 1000)
  }

  const saveMaintenanceDataSettings = async () => {
    setIsLoading(true)
    postEmbraerAuthDBFunc()
    postGulfstreamAuthDBFunc()
    saveToLocalStorage()
    toast({
      title: `Settings saved`,
      description: 'Your preferred settings saved',
      status: 'success',
      variant: 'left-accent',
      position: 'bottom-right',
      isClosable: true,
    })
    setIsLoading(false)
  }

  const postEmbraerAuthDBFunc = async () => {
    // setIsLoading(true)
    let postData = {
      embraerCookie1,
      embraerCookie2,
      embraerCookie3,
      embraerCookie4,
      embraerCookie5,
      embraerCookie6,
      embraerCookie7
    }
    postEmbraerAuthDB(postData).then(data => {
      if (data?.status === true) {
        // setIsLoading(false)
        toast({
          title: `Data successfully reseted`,
          description: 'Connection data successfully reseted',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        // setIsLoading(false)
        toast({
          title: `Reset error`,
          description: 'Failed to reset server data',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  const postGulfstreamAuthDBFunc = async () => {
    // setIsLoading(true)
    let postData = {
      gulfstreamCookie1,
      gulfstreamCookie2,
      gulfstreamCookie3,
      gulfstreamCookie4,
      gulfstreamCookie5,
      gulfstreamCookie6
    }
    postGulfstreamAuthDB(postData).then(data => {
      if (data?.status === true) {
        // setIsLoading(false)
        toast({
          title: `Data successfully reseted`,
          description: 'Connection data successfully reseted',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        // setIsLoading(false)
        toast({
          title: `Reset error`,
          description: 'Failed to reset server data',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  const clearImportFields = async () => {
    // Bombardier
    setDataSpreadsheet_CH300([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_CH350([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_CH601([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_CH604([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_CH605([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_CH650([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_CH850([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_GL5000([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_GL5500([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_GL6000([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_GL6500([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_GL7500([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_GLXRS([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    // Gulfstream
    setDataSpreadsheet_GIV([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_G550([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_G450([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_G650([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    setDataSpreadsheet_G200([
      [{ value: "No" },{ value: "Description of Maintenance Data"},{ value: "Document No"},{ value: "Applicability"},{ value: "Issue No"},{ value: "Issue Date"},{ value: "Revision No"},{ value: "Revision Date"}],
    ])
    toast({
      title: `Fields cleared`,
      description: 'Fields succesfully cleared',
      status: 'success',
      variant: 'left-accent',
      position: 'bottom-right',
      isClosable: true,
    })
  }

  const getMaintenanceDataFunc = async () => {
    // setIsLoading(true);
    if (switchBombardier == true ) {
      getMaintenanceDataFromBombardier().then(data => {
        if (data?.status === true) {
          // setIsLoading(false);
          // console.log(data?.data)
          const dataA1 = {}
  
          for (const aircraftShortType in data?.data) {
  
            dataA1[aircraftShortType] = []
            const headerRow = [
              { value: 'No' },
              { value: 'Description of Maintenance Data' },
              { value: 'Document No' },
              { value: 'Applicability' },
              { value: 'Issue No' },
              { value: 'Issue Date' },
              { value: 'Revision No' },
              { value: 'Revision Date' },
            ];
            dataA1[aircraftShortType].push(headerRow);
  
            // Iterate over each level3 item of the aircraft
            let chapter
            if (aircraftShortType == "CH650MM") {
              chapter = 1
            } else {
              chapter = 0
            }
            data?.data[aircraftShortType][chapter].level2s[0].level3s.forEach(
              (level3, index) => {
                const row = [
                  { value: index + 1 }, // No
                  { value: level3.title }, // Description of Maintenance Data
                  { value: "-" }, // Document No (using aircraftShortType as Document No)
                  { value: "-" }, // Applicability
                  { value: level3.issueNumber }, // Issue No
                  { value: "-" }, // Issue Date
                  { value: level3.tsn }, // Revision No
                  { value: formatDate(level3.revdate) }, // Revision Date
                ];
                dataA1[aircraftShortType].push(row);
              }
            );
          }
          console.log(dataA1)
          setDataSpreadsheet_CH300(dataA1.CH350MM)
          setDataSpreadsheet_CH350(dataA1.CH350MM)
          setDataSpreadsheet_CH601(dataA1.CH601MM)
          setDataSpreadsheet_CH604(dataA1.CH604MM)
          setDataSpreadsheet_CH605(dataA1.CH605MM)
          setDataSpreadsheet_CH650(dataA1.CH650MM)
          setDataSpreadsheet_CH850(dataA1.CH850MM)
          setDataSpreadsheet_GL5000(dataA1.GL5000MM)
          setDataSpreadsheet_GL5500(dataA1.GV5500MM)
          setDataSpreadsheet_GL6000(dataA1.GL6000MM)
          setDataSpreadsheet_GL6500(dataA1.GV6500MM)
          setDataSpreadsheet_GL7500(dataA1.GV7500MM)
          setDataSpreadsheet_GLXRS(dataA1.GLXRSMM)
  
          // setIsLoading(false);
          toast({
            title: `Maintenance Data updated`,
            description: 'Maintenance Data update done successfully',
            status: 'success',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          })
  
        } else {
          // setIsLoading(false);
          toast({
            title: `Maintenance Data update failed`,
            description: 'Failed to update Maintenance Data',
            status: 'error',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          })
        }
      })
    }
    if (switchGulfstream == true) {
      getMaintenanceDataFromGulfstream().then(data => {
        if (data?.status === true) {
          // setIsLoading(false);
          console.log(data?.data)
          const dataA1 = {}
  
          for (const aircraft of data?.data) {
            const aircraftShortType = aircraft.id;
      
            const headerRow = [
              { value: "No" },
              { value: "Description of Maintenance Data" },
              { value: "Document No" },
              { value: "Applicability" },
              { value: "Issue No" },
              { value: "Issue Date" },
              { value: "Revision No" },
              { value: "Revision Date" },
            ];
            const rows = aircraft.rows.map((rowA, index) => [
              { value: index + 1 }, // No
              { value: rowA.column0 }, // Description of Maintenance Data
              { value: "-" }, // Document No (using aircraftShortType as Document No)
              { value: "-" }, // Applicability
              { value: "-" }, // Issue No
              { value: "-" }, // Issue Date
              { value: rowA.column1 }, // Revision No
              { value: rowA.column2 }, // Revision Date
            ]);
      
            dataA1[aircraftShortType] = [headerRow, ...rows];
          }
          console.log(dataA1)
          setDataSpreadsheet_GIV(dataA1['GIV-Maint'])
          setDataSpreadsheet_G550(dataA1['G550-Maint'])
          setDataSpreadsheet_G450(dataA1['G450-Maint'])
          setDataSpreadsheet_G650(dataA1['G650-Maint'])
          setDataSpreadsheet_G200(dataA1['G200-Maint'])
          // setIsLoading(false);
          toast({
            title: `Maintenance Data updated`,
            description: 'Maintenance Data update done successfully',
            status: 'success',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          })
  
        } else {
          // setIsLoading(false);
          toast({
            title: `Maintenance Data update failed`,
            description: 'Failed to update Maintenance Data',
            status: 'error',
            variant: 'left-accent',
            position: 'bottom-right',
            isClosable: true,
          })
        }
      })
    }
    
  }

  // const searchPricesFunc = async () => {
  //   setIsLoading(true)
  //   // console.log(dataSpreadsheet)
  //   const dataSpreadsheet1 = dataSpreadsheet.map(innerArray =>
  //     innerArray.map(item => item === undefined ? { value: '' } : item)
  //   );
  //   searchPrices([{
  //     switchAerospheres: switchAerospheres,
  //     switchBoeing: switchBoeing,
  //     switchBombardier: switchBombardier,
  //     switchEmbraer: switchEmbraer,
  //     switchGulfstream: switchGulfstream,
  //     switchLas: switchLas,
  //     switchSilmid: switchSilmid,
  //     switchDasi: switchDasi,
  //     switchScreenshots: switchScreenshots
  //   }, JSON.stringify(dataSpreadsheet1)]).then(data => {
  //     if (data?.status === true) {
  //       setIsLoading(false)
  //       setDataSpreadsheet(data?.data)
  //       console.log(data?.data)
  //       toast({
  //         title: `Maintenance Data updated`,
  //         description: 'Maintenance Data update done successfully',
  //         status: 'success',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //     } else {
  //       setIsLoading(false)
  //       toast({
  //         title: `Maintenance Data update failed`,
  //         description: 'Failed to update Maintenance Data',
  //         status: 'error',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //     }
  //   })
  // }

  const refreshData = async () => {
    onOpen2()
    getEmbraerAuthDB().then(data => {
      if (data?.status === true) {
        setEmbraerCookie1(data?.data['ZNPCQ00331393900'])
        setEmbraerCookie2(data?.data['MYSAPSSO2'])
        setEmbraerCookie3(data?.data['IPCZQX03c0b23e12'])
        setEmbraerCookie4(data?.data['ZNPCQ00333343200'])
        setEmbraerCookie5(data?.data['ZNPCQ00333303700'])
        setEmbraerCookie6(data?.data['ZNPCQ00331363000'])
        setEmbraerCookie7(data?.data['JSESSIONID'])
        console.log(data?.data)
        // setIsLoading(false)
      } else {
        // setIsLoading(false)
      }
    })
    getGulfstreamAuthDB().then(data => {
      if (data?.status === true) {
        setGulfstreamCookie1(data?.data['IPCZQX03a61afd44'])
        setGulfstreamCookie2(data?.data['siteSubCookie'])
        setGulfstreamCookie3(data?.data['ZNPCQ00333373300'])
        setGulfstreamCookie4(data?.data['AAAA03a61afd44'])
        setGulfstreamCookie5(data?.data['ZNPCQ00332393500'])
        setGulfstreamCookie6(data?.data['tandc'])
        console.log(data?.data)
        // setIsLoading(false)
      } else {
        // setIsLoading(false)
      }
    })
  }

  const testFunc = async () => {
    setTableDataPartsPricesList(
      [
        {
          id: "#4",
          pn: "MS9068-0081",
          description: "PACKING, PERFORMED, O-RING",
          supplier: "Bombardier",
          priceNew: "$2.64",
          priceRepaired: "",
          priceOverhauled: "",
          date: "04/05/2023"
        },
        {
          id: "#2",
          pn: "MS35769-91",
          description: "GASKET",
          supplier: "Bombardier",
          priceNew: "$3.51",
          priceRepaired: "",
          priceOverhauled: "",
          date: "03/05/2023"
        },
        {
          id: "#1",
          pn: "100-200-341",
          description: "HSTA",
          supplier: "Bombardier",
          priceNew: "$73,146.00",
          priceRepaired: "$21,146.00",
          priceOverhauled: "$23,146.00",
          date: "03/05/2023"
        }
      ]
    )
  }

  // const getPartsPricesFunc = async () => {
  //   getPartsPrices().then(data => {
  //     if (data?.status === true) {
  //       setTableDataPartsPricesList(data?.data)
  //       console.log(data?.data)
  //       console.log(data?.data[2])
  //       toast({
  //         title: `Data successfully loaded`,
  //         description: 'Data successfully loaded',
  //         status: 'success',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //     } else {
  //       toast({
  //         title: `Data load error`,
  //         description: 'Failed to load server data',
  //         status: 'error',
  //         variant: 'left-accent',
  //         position: 'bottom-right',
  //         isClosable: true,
  //       })
  //     }
  //   })
  // }
  
  // useEffect (() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     getPartsPricesFunc()
  //     loadFromLocalStorage();
  //   }
  //   return () => {
  //     isMounted = false;
  //   }
  //   // getPartsPrices()
  // }, [])

  return (
    <div style={{marginTop: '70px', color: 'white'}}>
      <Box
          // mt='20px'
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				// px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				{/* Header */}
				<Card
					direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					justifyContent={{ sm: 'center', md: 'space-between' }}
					align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
					<Flex align='center' direction={{ sm: 'column', md: 'row' }}>
						<Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
						{/* <Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}> */}
							<Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
								<Text
                  align='top'
									fontSize={{ sm: 'lg', lg: 'xl' }}
									color='#fff'
									fontWeight='bold'
									ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
									Form 46 - Maintenance Data
								</Text>
								{/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
							</Flex>
						</Flex>
						<Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlinePicture size='20px' />}
                onClick={() => {
                  handleSwitchScreenshotsChange()
                }}>
                SCREENSHOTS
                  {/* <DarkMode>
                    <Switch value={switchScreenshots} isChecked={switchScreenshots} onChange={handleSwitchScreenshotsChange} colorScheme='brand' ml='10px' />
                  </DarkMode> */}
                <Checkbox
                  // me='16px'
                  colorScheme='brand'
                  borderColor='brand.500'
                  size='lg'
                  ml='10px'
                  value={switchScreenshots}
                  isChecked={switchScreenshots}
                  // onChange={() => {
                  //   handleSwitchScreenshotsChange()
                  // }}
                />
            </Button>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineSetting size='20px' />}
                onClick={() => {
                  refreshData()
                }}>
                SETTINGS
            </Button>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineSearch size='20px' />}
                onClick={() => {
                  getMaintenanceDataFunc()
                }}>
                SEARCH
              </Button>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  ConnectToServer()
                }}>
                CONNECT
              </Button>
              <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  resetData()
                }}>
                RESET
              </Button>
						</Flex>
					</Flex>
				</Card>
			</Box>
      <Box
          // mt='20px'
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				// px='0px'
				display='flex'
				// flexDirection='column'
				// justifyContent='center'
				// align='center'
        >
				{/* Header */}
				<Card
					// direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					// maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					// justifyContent={{ sm: 'center', md: 'space-between' }}
					// align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
        {/* <div 
          style={{
          width: '100%',
          overflow: 'auto',
          backgroundColor: '#333'
          }}
        > */}
          {/* <Text fontSize='24px' margin='20px 6px 6px 14px' >Bombardier</Text> */}
          <Box width='200px'>
            <Flex align='center'>
              <Image src={BombardierImage} w='34px' h='34px' me='14px' />
              <Flex direction='column'>
                <Text fontSize='24px' color='#fff' fontWeight='bold'>
                  Bombardier
                </Text>
                {/* <Text color='gray.400' fontWeight='400' fontSize='xs'>
                  Main Bombardier parts platform
                </Text> */}
              </Flex>
            </Flex>
            <HSeparator mt='10px'></HSeparator>
          </Box>
          <Text fontSize='18px' margin='6px 6px 6px 14px' >Challenger 300</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH300} onChange={setDataSpreadsheet_CH300}/>
        {/* </div> */}
          {/* <HSeparator mt='20px'></HSeparator> */}
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Challenger 350</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH350} onChange={setDataSpreadsheet_CH350}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Challenger 601</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH601} onChange={setDataSpreadsheet_CH601}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Challenger 604</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH604} onChange={setDataSpreadsheet_CH604}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Challenger 605</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH605} onChange={setDataSpreadsheet_CH605}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Challenger 650</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH650} onChange={setDataSpreadsheet_CH650}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Challenger 850</Text>
          <DarkSpreadsheet data={dataSpreadsheet_CH850} onChange={setDataSpreadsheet_CH850}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Global 5000</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GL5000} onChange={setDataSpreadsheet_GL5000}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Global 5500</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GL5500} onChange={setDataSpreadsheet_GL5500}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Global 6000</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GL6000} onChange={setDataSpreadsheet_GL6000}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Global 6500</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GL6500} onChange={setDataSpreadsheet_GL6500}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Global 7500</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GL7500} onChange={setDataSpreadsheet_GL7500}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >Global XRS</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GLXRS} onChange={setDataSpreadsheet_GLXRS}/>
        </Card>
      </Box>
      <Box
          // mt='20px'
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				// px='0px'
				display='flex'
				// flexDirection='column'
				// justifyContent='center'
				// align='center'
        >
				{/* Header */}
				<Card
					// direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					// maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					// justifyContent={{ sm: 'center', md: 'space-between' }}
					// align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
        {/* <div 
          style={{
          width: '100%',
          overflow: 'auto',
          backgroundColor: '#333'
          }}
        > */}
          {/* <Text fontSize='24px' margin='20px 6px 6px 14px' >Bombardier</Text> */}
          <Box width='200px'>
            <Flex align='center'>
              <Image src={GulfstreamImage} w='34px' h='34px' me='14px' filter='auto' invert='100%'/>
              <Flex direction='column'>
                <Text fontSize='24px' color='#fff' fontWeight='bold'>
                  Gulfstream
                </Text>
                {/* <Text color='gray.400' fontWeight='400' fontSize='xs'>
                  Main Bombardier parts platform
                </Text> */}
              </Flex>
            </Flex>
            <HSeparator mt='10px'></HSeparator>
          </Box>
          <Text fontSize='18px' margin='6px 6px 6px 14px' >GIV</Text>
          <DarkSpreadsheet data={dataSpreadsheet_GIV} onChange={setDataSpreadsheet_GIV}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >G550</Text>
          <DarkSpreadsheet data={dataSpreadsheet_G550} onChange={setDataSpreadsheet_G550}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >G450</Text>
          <DarkSpreadsheet data={dataSpreadsheet_G450} onChange={setDataSpreadsheet_G450}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >G650</Text>
          <DarkSpreadsheet data={dataSpreadsheet_G650} onChange={setDataSpreadsheet_G650}/>
          <Text fontSize='18px' margin='20px 6px 6px 14px' >G200</Text>
          <DarkSpreadsheet data={dataSpreadsheet_G200} onChange={setDataSpreadsheet_G200}/>
        </Card>
      </Box>
      {/* Reserve for input fields */}
      {/* <Card
          w={{ sm: "100%", lg: "70%" }}
          alignSelf='flex-end'
          justifySelf='flex-end'>
          <Element to='accounts' name='accounts'>
            <CardHeader mb='40px'>
              <Flex direction='column'>
                <Text color='#fff' fontSize='lg' fontWeight='bold' mb='4px'>
                  Accounts
                </Text>
                <Text color='gray.400' fontWeight='400' fontSize='sm'>
                  Here you can setup and manage your integration settings
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='22px' w='100%'>
                <Flex direction='column'>
                  <Flex justify='space-between' mb='16px'>
                    <Flex align='center'>
                      <Icon as={SlackLogo} w='34px' h='34px' me='14px' />
                      <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                          Slack
                        </Text>
                        <Flex align='center'>
                          <Text
                            color='gray.400'
                            fontWeight='500'
                            fontSize='xs'
                            me='6px'>
                            Show Less
                          </Text>
                          <Icon as={IoIosArrowUp} color='gray.400' />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex align='center'>
                      <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                      </Text>
                      <DarkMode>
                        <Switch colorScheme='brand' defaultIsChecked />
                      </DarkMode>
                    </Flex>
                  </Flex>
                  <Stack direction='column' w='100%' spacing='18px' ps='50px'>
                    <Text color='gray.400' fontWeight='400' fontSize='sm'>
                      You haven't added your Slack yet or you aren't authorized.
                      Please add our Slack Bot to your account by clicking on
                      here. When you've added the bot, send your verification
                      code that you have received.
                    </Text>
                    <Flex
                      direction={{ sm: "column", md: "row" }}
                      justify='space-between'
                      bg={bgVerificationCard}
                      borderRadius='12px'
                      w='100%'
                      align='center'
                      p='10px 12px'>
                      <Text
                        color='gray.500'
                        fontSize='sm'
                        mb={{ sm: "8px", lg: null }}>
                        Verification Code
                      </Text>
                      <Tooltip
                        hasArrow
                        label='Copy!'
                        bg='brand.200'
                        placement='top'>
                        <Input
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          // color='#fff'
                          // bg='#0F1535'
                          // borderRadius='15px'
                          value='1172913'
                          bg='#fff'
                          maxW='200px'
                          borderRadius='12px'
                          color='gray.400'
                        />
                      </Tooltip>
                    </Flex>
                    <Flex
                      direction={{ sm: "column", md: "row" }}
                      // align='flex-start'
                      justify='space-between'
                      bg={bgVerificationCard}
                      borderRadius='12px'
                      w='100%'
                      align='center'
                      p='10px 12px'>
                      <Text color='gray.500' fontSize='sm'>
                        Connected Account
                      </Text>
                      <Flex
                        align='center'
                        direction={{ sm: "column", md: "row" }}
                        justify={{ sm: "center", lg: null }}>
                        <Text
                          color='gray.500'
                          fontSize='sm'
                          me='18px'
                          my={{ sm: "6px", lg: null }}>
                          hello@microsoft-ui.com
                        </Text>
                        <LightMode>
                          <Button
                            variant='solid'
                            colorScheme='red'
                            w='100px'
                            h='35px'
                            fontSize='10px'>
                            DELETE
                          </Button>
                        </LightMode>
                      </Flex>
                    </Flex>
                  </Stack>
                </Flex>
                <HSeparator />
                <Flex justify='space-between'>
                  <Flex align='center'>
                    <Icon as={SpotifyLogo} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                        Spotify
                      </Text>
                      <Text color='gray.400' fontWeight='400' fontSize='xs'>
                        Music
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align='center'>
                    <Text
                      color='gray.500'
                      fontWeight='500'
                      me='17px'
                      fontSize='xs'>
                      Enabled
                    </Text>
                    <DarkMode>
                      <Switch colorScheme='brand' defaultIsChecked />
                    </DarkMode>
                  </Flex>
                </Flex>
                <HSeparator />
                <Flex justify='space-between'>
                  <Flex align='center'>
                    <Icon as={AtlassianLogo} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                        Atlassian
                      </Text>
                      <Text color='gray.400' fontWeight='400' fontSize='xs'>
                        Payment Vendor
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align='center'>
                    <Text
                      color='gray.500'
                      fontWeight='500'
                      me='17px'
                      fontSize='xs'>
                      Enabled
                    </Text>
                    <DarkMode>
                      <Switch colorScheme='brand' defaultIsChecked />
                    </DarkMode>
                  </Flex>
                </Flex>
                <HSeparator />
                <Flex justify='space-between'>
                  <Flex align='center'>
                    <Icon as={InvisionLogo} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                        Invision
                      </Text>
                      <Text color='gray.400' fontWeight='400' fontSize='xs'>
                        Design Better
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex align='center'>
                    <DarkMode>
                      <Switch colorScheme='brand' defaultIsChecked />
                    </DarkMode>
                  </Flex>
                </Flex>
              </Stack>
            </CardBody>
          </Element>
        </Card> */}
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
          <Box
            // mt='20px'
          mb={{ sm: '24px', md: '50px', xl: '20px' }}
          borderRadius='15px'
          // px='0px'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          align='center'>
          {/* Header */}
          <Card
            direction={{ sm: 'column', md: 'row' }}
            mx='auto'
            maxH='330px'
            w={{ sm: '90%', xl: '100%' }}
            justifyContent={{ sm: 'center', md: 'space-between' }}
            align='center'
            p='10px'
            borderRadius='0px'
            // borderTopLeftRadius='6px'
            // borderTopRightRadius='6px'>
            borderTopRadius='6px'>
            <Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
              {/* <Text
                align='top'
                fontSize={{ sm: 'lg', lg: 'xl' }}
                color='#fff'
                fontWeight='bold'
                ms={{ sm: '8px', md: '0px' }}
                ml='20px'>
                Parts prices search
              </Text> */}
              <Flex
              direction={{ sm: "column", md: "row" }}
              justify='space-between'
              align='center'
              w='100%'
              mb='10px'
              mt='10px'>
                <Flex
                  align='left'>
                {/* <Button
                  variant='brand'
                  w='145px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "12px", md: "0px" }}
                  leftIcon={<AiOutlineDatabase size='20px' />}>
                  SAVE TO DATABASE
                </Button>
                <Button
                  variant='brand'
                  w='195px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mb={{ sm: "12px", md: "0px" }}
                  ml='20px'>
                  DOWNLOAD SCREENSHOTS
                </Button> */}
                  {/* <Button
                    variant='outline'
                    _hover={{bg: 'brand.500', color: 'white'}}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<AiOutlineDatabase size='20px' />}
                    onClick={() => {
                      testFunc()
                    }}>
                    TEST
                </Button> */}
                  <Button
                    variant='outline'
                    _hover={{bg: 'brand.500', color: 'white'}}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<AiOutlineDatabase size='20px' />}
                    onClick={() => {
                      createPartsPricesFunc()
                    }}>
                    SAVE TO DATABASE
                </Button>
                <Button
                    variant='outline'
                    _hover={{bg: 'brand.500', color: 'white'}}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<AiOutlineDownload size='20px' />}
                    onClick={() => {
                      // refreshData()
                    }}>
                    DOWNLOAD SCREENSHOTS
                </Button>
                <Button
                    variant='outline'
                    _hover={{bg: 'brand.500', color: 'white'}}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<BiReset size='20px' />}
                    onClick={() => {
                      clearImportFields()
                    }}>
                    CLEAR IMPORT FIELDS
                </Button>
              </Flex>
            <Stack
              direction='row'
              spacing='10px'
              alignSelf={{ sm: "flex-start", lg: "auto" }}>
              <Menu>
                <MenuButton
                  as={Button}
                  variant='outlineWhite'
                  w='125px'
                  h='35px'
                  fontSize='xs'
                  fontWeight='bold'
                  rightIcon={<IoIosArrowDown />}>
                  FILTERS
                </MenuButton>
                <MenuList
                  border='transparent'
                  backdropFilter='blur(63px)'
                  bg='linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)'
                  borderRadius='20px'>
                  <MenuItem color='gray.500'>Status: Paid</MenuItem>
                  <MenuItem color='gray.500'>Status: Refunded</MenuItem>
                  <MenuItem color='gray.500'>Status: Canceled</MenuItem>
                  <MenuDivider />
                  <MenuItem color='red.300'>Remove filter</MenuItem>
                </MenuList>
              </Menu>
              <Button
                variant='outlineWhite'
                w='100px'
                h='35px'
                borderRadius='12px'
                fontSize='10px'
                fontWeight='bold'>
                EXPORT EXCEL
              </Button>
            </Stack>
              </Flex>
            </Flex>
          </Card>
        </Box>
        </Flex>





        {/* MANAGE PROGRAMS */}
        <Modal size='4xl' isOpen={isOpen2} onClose={onClose2} >
        {overlay}
        <ModalContent bg='#1a202c'>
        <ModalHeader color='white' pb='none' p='0px'>
          <Box
          // mt='20px'
				mb={{ sm: '24px', md: '50px', xl: '20px' }}
				borderRadius='15px'
				// px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				{/* Header */}
				<Card
					direction={{ sm: 'column', md: 'row' }}
					mx='auto'
					maxH='330px'
					w={{ sm: '90%', xl: '100%' }}
					justifyContent={{ sm: 'center', md: 'space-between' }}
					align='center'
					p='10px'
					borderRadius='0px'
					// borderTopLeftRadius='6px'
					// borderTopRightRadius='6px'>
					borderTopRadius='6px'>
					<Flex align='center' direction={{ sm: 'column', md: 'row' }}>
						<Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
						{/* <Flex
							align='center'
							mb={{ sm: '10px', md: '0px' }}
							direction={{ sm: 'column', md: 'row' }}
							w={{ sm: '100%' }}
							textAlign={{ sm: 'center', md: 'start' }}> */}
							<Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
								<Text
                  align='top'
									fontSize={{ sm: 'lg', lg: 'xl' }}
									color='#fff'
									fontWeight='bold'
									ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
									Maintenance Data settings
								</Text>
								{/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
							</Flex>
						</Flex>
						<Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
            <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiFillSave size='20px' />}
                onClick={() => {
                  // setOverlay(<OverlayOne />)
                  // onOpen2()
                  saveMaintenanceDataSettings()
                }}>
                SAVE
              </Button>
              <Button
                variant='outline'
                _hover={{bg: 'brand.500', color: 'white'}}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  resetSuppliersSwitches()
                }}>
                RESET
              </Button>
						</Flex>
					</Flex>
				</Card>
			</Box>
          </ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody color='white'>
            <Flex gap='2' alignItems='center' direction='column'>
                      {/* Accounts Card */}
                      <Card
                          w={{ sm: "100%" }}
                          alignSelf='flex-end'
                          justifySelf='flex-end'>
                          <Element to='accounts' name='accounts'>
                            <CardHeader mb='40px'>
                              <Flex direction='column'>
                                <Text color='#fff' fontSize='lg' fontWeight='bold' mb='4px'>
                                  Platforms
                                </Text>
                                <Text color='gray.400' fontWeight='400' fontSize='sm'>
                                  Choose platforms to get their revisions
                                </Text>
                              </Flex>
                            </CardHeader>
                            <CardBody>
                              <Stack direction='column' spacing='22px' w='100%'>
                                <Flex justify='space-between'>
                                  <Flex align='center'>
                                    <Image src={BombardierImage} w='34px' h='34px' me='14px' />
                                    <Flex direction='column'>
                                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                                        Bombardier
                                      </Text>
                                      <Text color='gray.400' fontWeight='400' fontSize='xs'>
                                        Main Bombardier parts platform
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex align='center'>
                                    <Text
                                      color='gray.500'
                                      fontWeight='500'
                                      me='17px'
                                      fontSize='xs'>
                                      Enabled
                                    </Text>
                                    <DarkMode>
                                      <Switch value={switchBombardier} isChecked={switchBombardier} onChange={handleSwitchBombardierChange} colorScheme='brand' />
                                    </DarkMode>
                                  </Flex>
                                </Flex>
                                <HSeparator />
                                <Flex justify='space-between'>
                                  <Flex align='center'>
                                    <Image src={EmbraerImage} w='34px' h='34px' me='14px' />
                                    <Flex direction='column'>
                                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                                        Embraer
                                      </Text>
                                      <Text color='gray.400' fontWeight='400' fontSize='xs'>
                                        Main Embraer parts platform
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex align='center'>
                                    <Text
                                      color='gray.500'
                                      fontWeight='500'
                                      me='17px'
                                      fontSize='xs'>
                                      Enabled
                                    </Text>
                                    <DarkMode>
                                      <Switch value={switchEmbraer} isChecked={switchEmbraer} onChange={handleSwitchEmbraerChange} colorScheme='brand' />
                                    </DarkMode>
                                  </Flex>
                                </Flex>
                                <HSeparator />
                                <Flex justify='space-between'>
                                  <Flex align='center'>
                                    <Image src={GulfstreamImage} w='34px' h='34px' me='14px' filter='auto' invert='100%' />
                                    <Flex direction='column'>
                                      <Text fontSize='md' color='#fff' fontWeight='bold'>
                                        Gulfstream
                                      </Text>
                                      <Text color='gray.400' fontWeight='400' fontSize='xs'>
                                        Main Gulfstream parts platform
                                      </Text>
                                    </Flex>
                                  </Flex>
                                  <Flex align='center'>
                                    <Text
                                      color='gray.500'
                                      fontWeight='500'
                                      me='17px'
                                      fontSize='xs'>
                                      Enabled
                                    </Text>
                                    <DarkMode>
                                      <Switch value={switchGulfstream} isChecked={switchGulfstream} onChange={handleSwitchGulfstreamChange} colorScheme='brand' />
                                    </DarkMode>
                                  </Flex>
                                </Flex>
                              </Stack>
                            </CardBody>
                          </Element>
                        </Card>

                        <HSeparator marginTop='20px'/>
                        <HSeparator />
                      {/* Manually enter data */}
                      <Card
                          w={{ sm: "100%" }}
                          alignSelf='flex-end'
                          justifySelf='flex-end'
                          marginTop='20px'>
                          <Element to='accounts' name='accounts'>
                            <CardHeader mb='40px'>
                              <Flex direction='column'>
                                <Text color='#fff' fontSize='lg' fontWeight='bold' mb='4px'>
                                  Connection data
                                </Text>
                                <Text color='gray.400' fontWeight='400' fontSize='sm'>
                                  Enter connection data manually in case of errors
                                </Text>
                              </Flex>
                            </CardHeader>
                            <CardBody>
                              {/* <Stack direction='column' spacing='22px' w='100%'> */}
                              <Accordion
                                allowToggle
                                w={{ sm: "300px", md: "650px", xl: "100%" }}
                                mb='16px'>
                                <AccordionItem border='none'>
                                  <AccordionButton _focus='none' _hover='none' p='4px 0px 4px 0px'>
                                    <Box flex='1' textAlign='left'>
                                      {/* <Text color='#fff' fontWeight='bold' fontSize='md'>
                                        How do I order?
                                      </Text> */}
                                      <Flex direction='row'>
                                        <Image src={EmbraerImage} w='24px' h='24px' me='14px' />
                                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                                          Embraer
                                        </Text>
                                      </Flex>
                                    </Box>
                                    <AccordionIcon color='#fff' />
                                  </AccordionButton>
                                  <AccordionPanel p='18px 0px 40px 0px'>
                                    <Text
                                      color='gray.400'
                                      fontSize='sm'
                                      textAlign='left'
                                      alignSelf='flex-start'
                                      justifySelf='flex-start'>
                                      Please enter cookies data and tap Save button. More info...
                                    </Text>
                                    <Flex direction='column' w='100%' mt='20px'>
                                      <Grid
                                        // templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                                        templateColumns={{ sm: "1fr", md: "1fr" }}
                                        templateRows={{ md: "repeat(2, 1fr)" }}
                                        gap='20px'>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            ZNPCQ003-31393900
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie1(e.target.value)}
                                            value={embraerCookie1}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            MYSAPSSO2
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie2(e.target.value)}
                                            value={embraerCookie2}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            IPCZQX03c0b23e12
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie3(e.target.value)}
                                            value={embraerCookie3}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            ZNPCQ003-33343200
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie4(e.target.value)}
                                            value={embraerCookie4}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            ZNPCQ003-33303700
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie5(e.target.value)}
                                            value={embraerCookie5}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            ZNPCQ003-31363000
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie6(e.target.value)}
                                            value={embraerCookie6}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            JSESSIONID
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setEmbraerCookie7(e.target.value)}
                                            value={embraerCookie7}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Flex>
                                  </AccordionPanel>
                                </AccordionItem>
                                <HSeparator my='12px' />
                                <AccordionItem border='none'>
                                  <AccordionButton _focus='none' _hover='none' p='4px 0px 4px 0px'>
                                    <Box flex='1' textAlign='left'>
                                      {/* <Text color='#fff' fontWeight='bold' fontSize='md'>
                                        How do I order?
                                      </Text> */}
                                      <Flex direction='row'>
                                        <Image src={GulfstreamImage} w='24px' h='24px' me='14px' filter='auto' invert='100%' />
                                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                                          Gulfstream
                                        </Text>
                                      </Flex>
                                    </Box>
                                    <AccordionIcon color='#fff' />
                                  </AccordionButton>
                                  <AccordionPanel p='18px 0px 40px 0px'>
                                    <Text
                                      color='gray.400'
                                      fontSize='sm'
                                      textAlign='left'
                                      alignSelf='flex-start'
                                      justifySelf='flex-start'>
                                      Please enter cookies data and tap Save button. More info...
                                    </Text>
                                    <Flex direction='column' w='100%' mt='20px'>
                                      <Grid
                                        // templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                                        templateColumns={{ sm: "1fr", md: "1fr" }}
                                        templateRows={{ md: "repeat(2, 1fr)" }}
                                        gap='20px'>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            IPCZQX03a61afd44
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setGulfstreamCookie1(e.target.value)}
                                            value={gulfstreamCookie1}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            siteSubCookie
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setGulfstreamCookie2(e.target.value)}
                                            value={gulfstreamCookie2}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            ZNPCQ003-33373300
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setGulfstreamCookie3(e.target.value)}
                                            value={gulfstreamCookie3}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            AAAA03a61afd44
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setGulfstreamCookie4(e.target.value)}
                                            value={gulfstreamCookie4}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            ZNPCQ003-32393500
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setGulfstreamCookie5(e.target.value)}
                                            value={gulfstreamCookie5}
                                          />
                                        </FormControl>
                                        <FormControl>
                                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                            tandc
                                          </FormLabel>
                                          <Input
                                            bg='#0F1535'
                                            border='.5px solid'
                                            borderColor='rgba(226, 232, 240, 0.3)'
                                            _hover='none'
                                            color='#fff'
                                            borderRadius='15px'
                                            placeholder='Please enter cookie value'
                                            fontSize='xs'
                                            onChange={e => setGulfstreamCookie6(e.target.value)}
                                            value={gulfstreamCookie6}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Flex>
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>
                              {/* </Stack> */}
                            </CardBody>
                          </Element>
                        </Card>
            </Flex>
          </ModalBody>
          <ModalFooter>
            {/* <Button bg="#582CFF" fontSize='12px' color='white'>Close</Button> */}
          </ModalFooter>
          {/* <ModalFooter>
            <Button variant='brand' mr='10px' bg="#582CFF" fontSize='12px' color='white' onClick={onClose1}>Agree</Button>
            <Button variant='outline' _hover={{bg: 'brand.300'}}  borderColor='#582CFF' fontSize='12px' color='white' onClick={onClose1}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      <Modal isOpen={isLoading} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent  bg="rgba(26, 32, 44, 0)" boxShadow='none'>
        <ModalBody display="flex" alignItems="center" justifyContent="center" minHeight="400px" opacity='0.6'>
          <Spinner style={{ "--spinner-size": "14rem" }} color='white' thickness='3px'/>
          {/* <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
            /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
    </div>
  )
}

export default MaintenanceData;