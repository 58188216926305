/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, {useState} from "react";
import axios from 'axios';

// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner
} from "@chakra-ui/react";

import { Link as LinkReact, useHistory} from "react-router-dom";

// Assets
import signInImage from "assets/img/signInImage.png";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";

// import {login} from "../../../network/ApiAxios";
import { directLoginAPI, getOneAboutContentAPI, userAccountLogin, verifyUserAPI, signInData } from '../../../helpers/backend_helper';

function SignInCover() {
  const titleColor = "white";
  const textColor = "gray.400";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Email sent! Check it to reset your password.");
  // const [userId, setUserId] = useState("");
  // const [device, setDevice] = useState("");
  // const [userIP, setUserIP] = useState('');
  // const [country, setCountry] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // Toast notification
  const toast = useToast()

  let history = useHistory();

  const OverlayOne = () => (
    <ModalOverlay
      bg='rgba(0, 0, 0, 0.8)'
      backdropFilter='hue-rotate(10deg)'
    >
    </ModalOverlay>
  )

  const tryLogin = () => {
    setIsLoading(true)
    // const response = await login(email, password);
    const values = {username: email, password: password}
    console.log(values)
    // when twilio service is off by admin
    directLoginAPI(values).then(data => {
      // console.log(data)
        if (data?.error === false) {
            if (data?.userStatus === 'active') {
                // message.info(data?.msg);
                localStorage.setItem("taxstickToken", data?.token);
                verifyUserAPI().then(async (data2) => {
                  console.log(data2);
                  if (data2?.status === true) {
                    let userId = data2?.data?._id;
                
                    try {
                      const response = await axios.get('https://api.ipify.org/?format=json');
                      const userIP = response?.data?.ip;
                      console.log(userIP);
                
                      const response2 = await axios.get(`https://api.geoapify.com/v1/ipinfo?ip=${userIP}&apiKey=1afb1683ac3a4713a0c335c8dd6b5e72`);
                      const country = response2.data.country.name;
                
                      const userAgent = navigator.userAgent;
                      let device;
                      if (/Mobi|Android/i.test(userAgent)) {
                        device = 'Mobile';
                      } else if (/iPad|Tablet/i.test(userAgent)) {
                        device = 'Tablet';
                      } else {
                        device = 'PC';
                      }
                
                      let signInDataArray = { userId, email, userIP, country, device };
                      signInData(signInDataArray).then((data3) => {
                        console.log(data3);
                        if (data2?.data?.role === 'admin') {
                          history.push(`/dashboard/`);
                        } else if (data2?.data?.role === 'accountant') {
                          history.push(`/dashboard/`);
                        } else {
                          history.push(`/dashboard/`);
                        }
                        setIsLoading(false)
                      });
                    } catch (error) {
                      console.log(error);
                      setIsLoading(false)
                    }
                  } else {
                    message.warning(data?.message);
                    setIsLoading(false)
                  }
                });
            } else {
                // toast.warning("Please check your email to active A/C");
                toast({
                  title: `Wait until activation`,
                  description: 'Please wait until your account actiavion from server administrator',
                  status: 'error',
                  variant: 'left-accent',
                  position: 'bottom-right',
                  isClosable: true,
                })
                setIsLoading(false)
            }
        } else {
            // message.error(data?.msg);
            toast({
              title: `User not found`,
              description: 'Please register your account',
              status: 'error',
              variant: 'left-accent',
              position: 'bottom-right',
              isClosable: true,
            })
            setIsLoading(false)
        }
    })
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      tryLogin();
    }
  };
  
  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json');
      setUserIP(response.data.ip);
      fetchCountry(response.data.ip);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCountry = async (userIP) => {
    try {
      const response = await axios.get(`https://api.geoapify.com/v1/ipinfo?ip=${userIP}&apiKey=1afb1683ac3a4713a0c335c8dd6b5e72`);
      setCountry(response.data.country.name);
    } catch (error) {
      console.log(error);
    }
  };


  // const tryLogin = async () => {
  //   const response = await login(email, password);
  //   const {data} = response;
  //   if (data.success) {
  //       setError("");
  //       localStorage.setItem("token", data.token);
  //       localStorage.setItem("user", JSON.stringify(data.user));
  //       history.push("/");
  //       // window.location.reload(false);
  //       // handleSignIn()
  //   } else {
  //       setPassword("");
  //       setError(data.msg);
  //   }
  // }

  return (
    <>
      <Flex position='relative'>
        <Flex
          minH='100vh'
          h={{ base: "120vh", lg: "fit-content" }}
          w='100%'
          maxW='1044px'
          mx='auto'
          pt={{ sm: "100px", md: "0px" }}
          flexDirection='column'
          me={{ base: "auto", lg: "50px", xl: "auto" }}>
          <Flex
            alignItems='center'
            justifyContent='start'
            style={{ userSelect: "none" }}
            mx={{ base: "auto", lg: "unset" }}
            ms={{ base: "auto", lg: "auto" }}
            w={{ base: "100%", md: "50%", lg: "450px" }}
            px='50px'>
            <Flex
              direction='column'
              w='100%'
              background='transparent'
              mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
              mb={{ base: "60px", lg: "95px" }}>
              <Heading color={titleColor} fontSize='32px' mb='10px'>
                Nice to see you!
              </Heading>
              <Text
                mb='36px'
                ms='4px'
                color={textColor}
                fontWeight='bold'
                fontSize='14px'>
                Enter your email and password to sign in
              </Text>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='normal'
                  color='white'>
                  Email
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color='white'
                    bg='rgb(19,21,54)'
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    h='46px'
                    placeholder='Your email address'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </GradientBorder>
              </FormControl>
              <FormControl>
                <FormLabel
                  ms='4px'
                  fontSize='sm'
                  fontWeight='normal'
                  color='white'>
                  Password
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color='white'
                    bg='rgb(19,21,54)'
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    type='password'
                    placeholder='Your password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </GradientBorder>
              </FormControl>
              <FormControl display='flex' alignItems='center'>
                <DarkMode>
                  <Switch id='remember-login' colorScheme='brand' me='10px' />
                </DarkMode>
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  ms='1'
                  fontWeight='normal'
                  color='white'>
                  Remember me
                </FormLabel>
              </FormControl>
              <Button
                variant='brand'
                fontSize='10px'
                type='submit'
                w='100%'
                maxW='350px'
                h='45'
                mb='20px'
                mt='20px'
                // onClick={tryLogin}>
                onClick={tryLogin}>
                SIGN IN
              </Button>
              {error ?
                <div style={{ color: 'red', fontStyle: 'italic' }}>
                    <small>
                        Try again:{" "}
                        <span style={{ color: 'white' }}>{error}</span>
                    </small>
                </div> : null
              }
              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={textColor} fontWeight='medium'>
                  Don't have an account?
                  <Link color={titleColor} ms='5px' fontWeight='bold' as={LinkReact} to='/auth/authentication/sign-up/cover'>
                    Sign Up
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box
            w={{ base: "335px", md: "450px" }}
            mx={{ base: "auto", lg: "unset" }}
            ms={{ base: "auto", lg: "auto" }}
            mb='80px'>
            <AuthFooter />
          </Box>
          <Box
            display={{ base: "none", lg: "block" }}
            overflowX='hidden'
            h='100%'
            maxW={{ md: "50vw", lg: "50vw" }}
            minH='100vh'
            w='960px'
            position='absolute'
            left='0px'>
            <Box
              bgImage={signInImage}
              w='100%'
              h='100%'
              bgSize='cover'
              bgPosition='50%'
              // position='absolute'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              position='absolute'>
              <Text
                textAlign='center'
                color='white'
                letterSpacing='8px'
                fontSize='20px'
                fontWeight='500'>
                INSPIRED BY THE FUTURE:
              </Text>
              <Text
                textAlign='center'
                color='transparent'
                letterSpacing='8px'
                fontSize='36px'
                fontWeight='bold'
                bgClip='text !important'
                bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
                THE AVIA OFFICE DASHBOARD
              </Text>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <Modal isOpen={isLoading} onClose={() => {}}>
        <ModalOverlay />
        <ModalContent  bg="rgba(26, 32, 44, 0)" boxShadow='none'>
          <ModalBody display="flex" alignItems="center" justifyContent="center" minHeight="400px" opacity='0.6'>
            <Spinner style={{ "--spinner-size": "14rem" }} color='white' thickness='3px'/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SignInCover;
