import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@chakra-ui/react';
import { BsPlus } from 'react-icons/bs';

const JsonFileReader = () => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          const jsonData = JSON.parse(reader.result);
          console.log('JSON data:', jsonData);
          // You can now use jsonData as needed, e.g., display it in the component state.
        } catch (error) {
          console.error('Error parsing JSON:', error.message);
        }
      };

      reader.readAsText(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/json',
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={4}
      borderWidth={2}
      borderRadius="lg"
      borderColor='gray.400'
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <BsPlus size="48px" color='gray.400' />
    </Box>
  );
};

export default JsonFileReader;
