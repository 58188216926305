import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import xml2js from 'xml2js';
import FetchButton from 'components/Other/ManualsProgram/FetchButton';

import axios from "axios";

import {
  Avatar,
  Button,
  Flex,
  IconButton,
  Input,
  Icon,
  Image,
  ListItem,
  UnorderedList,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  MenuGroup,
  MenuDivider,
  Box,
  useToast,
  Stack,
  LightMode,
  DarkMode,
  Switch,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Checkbox,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  FormControl,
  FormLabel,
  Grid,
  Textarea
} from "@chakra-ui/react";

import ListGroup from 'react-bootstrap/ListGroup';

import JsonFileReader from './components/JsonFileReader';

import TreeMenu from 'react-simple-tree-menu';
import './main.css';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import { BiReset } from "react-icons/bi";
import { AiFillSave, AiOutlineSetting, AiOutlineLink, AiOutlineReload, AiOutlineSearch, AiOutlinePicture, AiOutlineDatabase, AiOutlineDownload, AiOutlinePlusCircle } from "react-icons/ai";
import { Element, Link } from "react-scroll";
import SearchTablePartsPrices from "components/Tables/SearchTablePartsPrices";

import { getOnePartPrice, searchPrices, authorizeBombardier, authorizeLas, authorizeSilmid, resetPortalsAuthorization, createPartsPrices, getPartsPrices, authorizeDasiPart1, authorizeDasiPart2, saveAllCookies } from '../../helpers/backend_helper';

import Spreadsheet from "react-spreadsheet";
import styled from 'styled-components';

// Icons
import {
  AtlassianLogo,
  InvisionLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons";

// Images
import AerospheresImage from "assets/img/parts_prices/aerospheres.png";
import BoeingImage from "assets/img/parts_prices/boeing.png";
import BombardierImage from "assets/img/parts_prices/bombardier.png";
import EmbraerImage from "assets/img/parts_prices/embraer.png";
import GulfstreamImage from "assets/img/parts_prices/gulfstream.png";
import LasImage from "assets/img/parts_prices/las.png";
import SilmidImage from "assets/img/parts_prices/silmid.png";
import DasiImage from "assets/img/parts_prices/dasi.png";

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

// Data
import { columnsDataPartsPrices } from "variables/columnsData";
import tableDataPartsPrices from "variables/tableDataPartsPrices.json";

import TestInputs from './components/TestInputs';
import SettingsModal from './components/SettingsModal';
import LoadingModal from 'components/LoadingScreens/LoadingModal1';

const DarkSpreadsheet = styled(Spreadsheet)`
background-color: #222;
color: #e8e8e8;

.Spreadsheet__header {
  background-color: #222;
  border-color: #5c5c5c;
  color: #e8e8e8;
}

.Spreadsheet__cell {
  background-color: #444;
  border-color: #5c5c5c;
  color: #e8e8e8;
}

.Spreadsheet__cell--selected {
  background-color: #666;
}

.Spreadsheet__data-editor {
  background-color: #666;
}
`;


// Memorized components
const MemoizedSearchTablePartsPrices = memo(SearchTablePartsPrices);


const PartsPrices = () => {

  console.log('PartsPrices rerendered')

  const bgActiveButton = "brand.200";
  const bgHoverLinks = "brand.200";
  const bgVerificationCard = "#0F1535";

  const [isLoading, setIsLoading] = useState(false);

  const OverlayOne = () => (
    <ModalOverlay
      bg='rgba(0, 0, 0, 0.8)'
      backdropFilter='hue-rotate(10deg)'
    >
    </ModalOverlay>
  )
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure()
  const [overlay, setOverlay] = React.useState(<OverlayOne />)

  const [switchAerospheres, setSwitchAerospheres] = useState(false);
  const [switchBoeing, setSwitchBoeing] = useState(false);
  const [switchBombardier, setSwitchBombardier] = useState(true);
  const [switchEmbraer, setSwitchEmbraer] = useState(false);
  const [switchGulfstream, setSwitchGulfstream] = useState(false);
  const [switchLas, setSwitchLas] = useState(false);
  const [switchSilmid, setSwitchSilmid] = useState(false);
  const [switchDasi, setSwitchDasi] = useState(false);
  const [switchScreenshots, setSwitchScreenshots] = useState(false);

  const [suppliersCookies, setSuppliersCookies] = useState({
    bombardier: '',
    embraer: '',
    gulfstream: '',
    boeing: '',
    aerospheres: '',
    las: '',
    silmid: '',
    dasi: ''
  });

  const handleSwitchChange = useCallback((fieldName) => {
    setSuppliersCookies((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  }, []);

  const resetSuppliersSwitches = () => {
    setSwitchAerospheres(false);
    setSwitchBoeing(false);
    setSwitchBombardier(false);
    setSwitchEmbraer(false);
    setSwitchGulfstream(false);
    setSwitchLas(false);
    setSwitchSilmid(false);
    setSwitchDasi(false);
  }

  const saveToLocalStorage = () => {
    const listOfSwitches = {
      switchAerospheres,
      switchBoeing,
      switchBombardier,
      switchEmbraer,
      switchGulfstream,
      switchLas,
      switchSilmid,
      switchDasi,
    };

    localStorage.setItem('part_prices_settings', JSON.stringify(listOfSwitches));
    // You can replace 'myData' with your preferred key name
  };

  // *** Saves switches value to local storage ***
  useEffect(() => {
    // This effect will run after every render where switchAerospheres has changed
    saveToLocalStorage();
  }, [
    switchAerospheres,
    switchBoeing,
    switchBombardier,
    switchEmbraer,
    switchGulfstream,
    switchLas,
    switchSilmid,
    switchDasi
  ]);


  // const handleInputChange = useCallback((supplierName, newValue) => {
  //   setSuppliersCookies((prevSuppliersCookies) => ({
  //     ...prevSuppliersCookies,
  //     [supplierName]: newValue,
  //   }));
  // }, [suppliersCookies]);

  const handleInputChange = useCallback((fieldName) => {
    setSuppliersCookies((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  }, [setSuppliersCookies]);

  const toast = useToast()

  const [dataSpreadsheet, setDataSpreadsheet] = useState([
    [{ value: "P/N" }, { value: "Description" }, { value: "New" }, { value: "Rental" }, { value: "Repaired" }, { value: "Overhauled" }, { value: "Las-Price" }, { value: "Silmid-Price" }, { value: "Boeing-Price" }, { value: "Aviall-Price" }, { value: "Gulfstream-Price" }, { value: "Embraer-Price" },],
    [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
  ]);

  const addNewLine = () => {
    const newRow = { value: "" }
    setDataSpreadsheet((prevData) => [...prevData, newRow]);
  };

  // const [tableDataPartsPricesList, setTableDataPartsPricesList] = useState(testArray);
  const [tableDataPartsPricesList, setTableDataPartsPricesList] = useState([]);

  const refreshData = async () => {
    onOpen2()
    // console.log(data)
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      authorizeDasiFunc2();
    }
  };

  useEffect(() => {
    getPartsPricesFunc();
  }, []);

  useEffect(() => {
    loadFromLocalStorage();
  }, []);

  const BombardierDetails = useMemo(() => (
    <div>
      <Textarea
        // key={input.name}
        bg='#0F1535'
        border='.5px solid'
        borderColor='rgba(226, 232, 240, 0.3)'
        _hover='none'
        color='#fff'
        borderRadius='15px'
        placeholder='bombardier'
        fontSize='xs'
        value={suppliersCookies.bombardier}
        onChange={(e) => handleInputChange("bombardier", e.target.value)}
      //   style={{ color: 'black' }}
      />
    </div>
  ), [suppliersCookies.bombardier]);

  const EmbraerDetails = useMemo(() => (
    <div>
      <Textarea
        // key={input.name}
        bg='#0F1535'
        border='.5px solid'
        borderColor='rgba(226, 232, 240, 0.3)'
        _hover='none'
        color='#fff'
        borderRadius='15px'
        placeholder='embraer'
        fontSize='xs'
        value={suppliersCookies.embraer}
        onChange={(e) => handleInputChange("embraer", e.target.value)}
      //   style={{ color: 'black' }}
      />
    </div>
  ), [suppliersCookies.embraer]);

  const GulfstreamDetails = useMemo(() => (
    <div>
      <Textarea
        // key={input.name}
        bg='#0F1535'
        border='.5px solid'
        borderColor='rgba(226, 232, 240, 0.3)'
        _hover='none'
        color='#fff'
        borderRadius='15px'
        placeholder='gulfstream'
        fontSize='xs'
        value={suppliersCookies.gulfstream}
        onChange={(e) => handleInputChange("gulfstream", e.target.value)}
      //   style={{ color: 'black' }}
      />
    </div>
  ), [suppliersCookies.gulfstream]);

  const handleCloseModal = useCallback(() => {
    // Add any additional logic you need here
    setIsLoading(false);
  }, []);

  const getPartsPricesFunc = async () => {
    getPartsPrices().then(data => {
      if (data?.status === true) {
        setTableDataPartsPricesList(data?.data)
        console.log(data?.data)
        console.log(data?.data[2])
        toast({
          title: `Data successfully loaded`,
          description: 'Data successfully loaded',
          status: 'success',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      } else {
        toast({
          title: `Data load error`,
          description: 'Failed to load server data',
          status: 'error',
          variant: 'left-accent',
          position: 'bottom-right',
          isClosable: true,
        })
      }
    })
  }

  const loadFromLocalStorage = () => {
    const savedData = localStorage.getItem('part_prices_settings');
    // You can replace 'myData' with your preferred key name

    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setSwitchAerospheres(parsedData.switchAerospheres);
      setSwitchBoeing(parsedData.switchBoeing);
      setSwitchBombardier(parsedData.switchBombardier);
      setSwitchEmbraer(parsedData.switchEmbraer);
      setSwitchGulfstream(parsedData.switchGulfstream);
      setSwitchLas(parsedData.switchLas);
      setSwitchSilmid(parsedData.switchSilmid);
      setSwitchDasi(parsedData.switchDasi);
    }
  };

  return (
    <div style={{ marginTop: '70px', color: 'white' }}>
      <Box
        // mt='20px'
        mb={{ sm: '24px', md: '50px', xl: '20px' }}
        borderRadius='15px'
        // px='0px'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        align='center'>
        {/* Header */}
        <Card
          direction={{ sm: 'column', md: 'row' }}
          mx='auto'
          maxH='330px'
          w={{ sm: '90%', xl: '100%' }}
          justifyContent={{ sm: 'center', md: 'space-between' }}
          align='center'
          p='10px'
          borderRadius='0px'
          borderTopRadius='6px'>
          <Flex align='center' direction={{ sm: 'column', md: 'row' }}>
            <Flex
              align='center'
              mb={{ sm: '10px', md: '0px' }}
              direction={{ sm: 'column', md: 'row' }}
              w={{ sm: '100%' }}
              textAlign={{ sm: 'center', md: 'start' }}
              height='73px'
              alignItems='center'>
              <Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
                <Text
                  align='top'
                  fontSize={{ sm: 'lg', lg: 'xl' }}
                  color='#fff'
                  fontWeight='bold'
                  ms={{ sm: '8px', md: '0px' }}
                  ml='20px'>
                  Parts prices search
                </Text>
              </Flex>
            </Flex>
            <Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
              <Button
                variant='outline'
                _hover={{ bg: 'brand.500', color: 'white' }}
                borderColor='brand.500'
                color='brand.200'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlinePicture size='20px' />}
                onClick={() => {
                  handleSwitchScreenshotsChange()
                }}>
                SCREENSHOTS
                <Checkbox
                  colorScheme='brand'
                  borderColor='brand.500'
                  size='lg'
                  ml='10px'
                  value={switchScreenshots}
                  isChecked={switchScreenshots}
                />
              </Button>
              <Button
                variant='outline'
                _hover={{ bg: 'brand.500', color: 'white' }}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineSetting size='20px' />}
                onClick={() => {
                  refreshData()
                }}>
                SETTINGS
              </Button>
              <Button
                variant='outline'
                _hover={{ bg: 'brand.500', color: 'white' }}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineSearch size='20px' />}
                onClick={() => {
                  searchPricesFunc()
                }}>
                SEARCH
              </Button>
              <Button
                variant='outline'
                _hover={{ bg: 'brand.500', color: 'white' }}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<AiOutlineLink size='20px' />}
                onClick={() => {
                  ConnectToServer()
                }}>
                CONNECT
              </Button>
              <Button
                variant='outline'
                _hover={{ bg: 'brand.500', color: 'white' }}
                borderColor='brand.500'
                color='brand.200'
                // color='white'
                // w='150px'
                h='35px'
                fontSize='xs'
                fontWeight='bold'
                alignSelf={{ sm: "flex-start", lg: null }}
                mb={{ sm: "6px", md: "0px" }}
                mt='38px'
                ml='10px'
                leftIcon={<BiReset size='20px' />}
                // pl='1px'
                onClick={() => {
                  resetData()
                }}>
                RESET
              </Button>
            </Flex>
          </Flex>
        </Card>
      </Box>
      <div
        style={{
          width: '100%',
          overflow: 'auto',
        }}
      >
        <DarkSpreadsheet data={dataSpreadsheet} onChange={setDataSpreadsheet} />

        {/* Styled Box as a button with an icon */}
        <Box
          as="button"
          bgColor="#1a1a1a"
          color="#ffffff"
          border="none"
          borderRadius="4px"
          padding="8px"
          marginTop="10px"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={addNewLine}
          fontSize='sm'
          fontWeight='thin'
        >
          <Icon as={AiOutlinePlusCircle} boxSize={5} marginRight="2" />
          Add New Line
        </Box>
      </div>
      <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
        <Box
          // mt='20px'
          mb={{ sm: '24px', md: '50px', xl: '20px' }}
          borderRadius='15px'
          // px='0px'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          align='center'>
          {/* Header */}
          <Card
            direction={{ sm: 'column', md: 'row' }}
            mx='auto'
            maxH='330px'
            w={{ sm: '90%', xl: '100%' }}
            justifyContent={{ sm: 'center', md: 'space-between' }}
            align='center'
            p='10px'
            borderRadius='0px'
            borderTopRadius='6px'>
            <Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
              <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='10px'
                mt='10px'>
                <Flex
                  align='left'>
                  <Button
                    variant='outline'
                    _hover={{ bg: 'brand.500', color: 'white' }}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<AiOutlineDatabase size='20px' />}
                    onClick={() => {
                      onOpen3()
                    }}>
                    TEST
                  </Button>
                  <Button
                    variant='outline'
                    _hover={{ bg: 'brand.500', color: 'white' }}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<AiOutlineDatabase size='20px' />}
                    onClick={() => {
                      createPartsPricesFunc()
                    }}>
                    SAVE TO DATABASE
                  </Button>
                  <Button
                    variant='outline'
                    _hover={{ bg: 'brand.500', color: 'white' }}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<AiOutlineDownload size='20px' />}
                    onClick={() => {
                      // refreshData()
                    }}>
                    DOWNLOAD SCREENSHOTS
                  </Button>
                  <Button
                    variant='outline'
                    _hover={{ bg: 'brand.500', color: 'white' }}
                    borderColor='brand.500'
                    color='brand.200'
                    // color='white'
                    // w='150px'
                    h='35px'
                    fontSize='xs'
                    fontWeight='bold'
                    alignSelf={{ sm: "flex-start", lg: null }}
                    mb={{ sm: "6px", md: "0px" }}
                    // mt='38px'
                    ml='10px'
                    leftIcon={<BiReset size='20px' />}
                    onClick={() => {
                      clearImportFields()
                    }}>
                    CLEAR IMPORT FIELDS
                  </Button>
                </Flex>
                <Stack
                  direction='row'
                  spacing='10px'
                  alignSelf={{ sm: "flex-start", lg: "auto" }}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant='outlineWhite'
                      w='125px'
                      h='35px'
                      fontSize='xs'
                      fontWeight='bold'
                      rightIcon={<IoIosArrowDown />}>
                      FILTERS
                    </MenuButton>
                    <MenuList
                      border='transparent'
                      backdropFilter='blur(63px)'
                      bg='linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.69) 76.65%)'
                      borderRadius='20px'>
                      <MenuItem color='gray.500'>Status: Paid</MenuItem>
                      <MenuItem color='gray.500'>Status: Refunded</MenuItem>
                      <MenuItem color='gray.500'>Status: Canceled</MenuItem>
                      <MenuDivider />
                      <MenuItem color='red.300'>Remove filter</MenuItem>
                    </MenuList>
                  </Menu>
                  <Button
                    variant='outlineWhite'
                    w='100px'
                    h='35px'
                    borderRadius='12px'
                    fontSize='10px'
                    fontWeight='bold'>
                    EXPORT EXCEL
                  </Button>
                </Stack>
              </Flex>
            </Flex>
          </Card>
        </Box>
        <Card px='0px'>
          <CardBody>
            {/* <SearchTablePartsPrices tableData={tableDataPartsPricesList} columnsData={columnsDataPartsPrices} /> */}
            <MemoizedSearchTablePartsPrices tableData={tableDataPartsPricesList} columnsData={columnsDataPartsPrices} />
          </CardBody>
        </Card>
      </Flex>





      {/* <SettingsModal suppliersCookies={suppliersCookies} isOpen={isOpen2} onClose={onClose2} ></SettingsModal> */}
      <SettingsModal
        isOpen={isOpen2}
        onClose={onClose2}
        // saveCookiesFunc={/* your saveCookiesFunc logic */}
        // resetSuppliersSwitches={/* your resetSuppliersSwitches logic */}
        suppliersCookies={suppliersCookies}
        setSuppliersCookies={setSuppliersCookies}
      />
      <LoadingModal isOpen={isLoading} onClose={handleCloseModal} />
      <Modal size='sm' isCentered isOpen={isOpen3} onClose={onClose3}>
        {overlay}
        {/* <ModalContent bg='#1a202c' overflowY="auto" maxHeight="90vh"> */}
        <ModalContent
          bg='#1a202c'
        >
          <ModalHeader color='white' pb='none' p='0px'>
            <Box
              // mt='20px'
              mb={{ sm: '24px', md: '50px', xl: '20px' }}
              borderRadius='15px'
              // px='0px'
              display='flex'
              flexDirection='column'
              justifyContent='center'
              align='center'>
              {/* Header */}
              <Card
                direction={{ sm: 'column', md: 'row' }}
                mx='auto'
                maxH='330px'
                w={{ sm: '90%', xl: '100%' }}
                justifyContent={{ sm: 'center', md: 'space-between' }}
                align='center'
                p='10px'
                borderRadius='0px'
                // borderTopLeftRadius='6px'
                // borderTopRightRadius='6px'>
                borderTopRadius='6px'>
                <Flex align='center' direction={{ sm: 'column', md: 'row' }}>
                  <Flex
                    align='center'
                    mb={{ sm: '10px', md: '0px' }}
                    direction={{ sm: 'column', md: 'row' }}
                    w={{ sm: '100%' }}
                    textAlign={{ sm: 'center', md: 'start' }}
                    height='73px'
                    alignItems='center'>
                    <Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
                      {/* <SearchIcon mt='6px' mr='8px'></SearchIcon> */}
                      <Text
                        align='top'
                        fontSize={{ sm: 'lg', lg: 'xl' }}
                        color='#fff'
                        fontWeight='bold'
                        ms={{ sm: '8px', md: '0px' }}
                        ml='20px'>
                        Dasi authorization
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </Box>
          </ModalHeader>
          <ModalCloseButton color='white' />
          <ModalBody ml='60px' mr='80px'>
            <Image src='https://store.dasi.com/captchaimage.aspx' />
            <FormControl>
              <Input
                mt='10px'
                bg='#0F1535'
                border='.5px solid'
                borderColor='rgba(226, 232, 240, 0.3)'
                _hover='none'
                color='#fff'
                borderRadius='15px'
                placeholder='Please enter numbers...'
                fontSize='xs'
                onChange={e => setCaptchaText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button bg="#582CFF" fontSize='12px' color='white' onClick={() => authorizeDasiFunc2()}>CONFIRM</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default PartsPrices;