import React, { useCallback, useState, useMemo } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    DarkMode,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    Text,
    Box,
    Stack,
    Switch,
    Textarea,
    Image,
} from '@chakra-ui/react';

import { BiReset } from "react-icons/bi";
import { AiFillSave } from "react-icons/ai";

import { HSeparator } from "components/Separator/Separator";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";
import { Element, Link } from "react-scroll";

// Images
import AerospheresImage from "assets/img/parts_prices/aerospheres.png";
import BoeingImage from "assets/img/parts_prices/boeing.png";
import BombardierImage from "assets/img/parts_prices/bombardier.png";
import EmbraerImage from "assets/img/parts_prices/embraer.png";
import GulfstreamImage from "assets/img/parts_prices/gulfstream.png";
import LasImage from "assets/img/parts_prices/las.png";
import SilmidImage from "assets/img/parts_prices/silmid.png";
import DasiImage from "assets/img/parts_prices/dasi.png";

const SettingsModal = React.memo(({ isOpen, onClose, saveCookiesFunc, resetSuppliersSwitches, suppliersCookies, setSuppliersCookies }) => {
    console.log('SettingsModal rerendered')

    const [switchAerospheres, setSwitchAerospheres] = useState(false);
    const [switchBoeing, setSwitchBoeing] = useState(false);
    const [switchBombardier, setSwitchBombardier] = useState(true);
    const [switchEmbraer, setSwitchEmbraer] = useState(false);
    const [switchGulfstream, setSwitchGulfstream] = useState(false);
    const [switchLas, setSwitchLas] = useState(false);
    const [switchSilmid, setSwitchSilmid] = useState(false);
    const [switchDasi, setSwitchDasi] = useState(false);
    const [switchScreenshots, setSwitchScreenshots] = useState(false);

    const handleInputChange = useCallback((supplierName, newValue) => {
        setSuppliersCookies((prevSuppliersCookies) => ({
            ...prevSuppliersCookies,
            [supplierName]: newValue,
        }));
    }, [setSuppliersCookies]);

    const handleSwitchAerospheresChange = () => {
        setSwitchAerospheres(!switchAerospheres);
    }
    const handleSwitchBoeingChange = () => {
        setSwitchBoeing(!switchBoeing);
    }
    const handleSwitchBombardierChange = () => {
        setSwitchBombardier(!switchBombardier);
    }
    const handleSwitchEmbraerChange = () => {
        setSwitchEmbraer(!switchEmbraer);
    }
    const handleSwitchGulfstreamChange = () => {
        setSwitchGulfstream(!switchGulfstream);
    }
    const handleSwitchLasChange = () => {
        setSwitchLas(!switchLas);
    }
    const handleSwitchSilmidChange = () => {
        setSwitchSilmid(!switchSilmid);
    }
    const handleSetSwitchDasiChange = () => {
        setSwitchDasi(!switchDasi);
    }
    const handleSwitchScreenshotsChange = () => {
        setSwitchScreenshots(!switchScreenshots);
    }

    const BombardierDetails = useMemo(() => (
        <>
            {/* *** Bombardier *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={BombardierImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            Bombardier
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Main Bombardier parts platform
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchBombardier}
                            isChecked={switchBombardier}
                            onChange={() => {
                                handleSwitchBombardierChange();
                            }}
                            colorScheme='brand'
                        />

                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.bombardier}
                                    onChange={(e) => handleInputChange('bombardier', e.target.value)}
                                />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.bombardier, handleInputChange]);
    const EmbraerDetails = useMemo(() => (
        <>
            {/* *** Embraer *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={EmbraerImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            Embraer
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Main Embraer parts platform
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchEmbraer}
                            isChecked={switchEmbraer}
                            onChange={() => {
                                handleSwitchEmbraerChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.embraer}
                                    onChange={(e) => handleInputChange('embraer', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.embraer, handleInputChange]);
    const GulfstreamDetails = useMemo(() => (
        <>
            {/* *** Gulfstream *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={GulfstreamImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            Gulfstream
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Main Gulfstream parts platform
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchGulfstream}
                            isChecked={switchGulfstream}
                            onChange={() => {
                                handleSwitchGulfstreamChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.gulfstream}
                                    onChange={(e) => handleInputChange('gulfstream', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.gulfstream, handleInputChange]);
    const BoeingDetails = useMemo(() => (
        <>
            {/* *** Boeing *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={BoeingImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            Boeing
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Good for chemicals info
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchBoeing}
                            isChecked={switchBoeing}
                            onChange={() => {
                                handleSwitchBoeingChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.boeing}
                                    onChange={(e) => handleInputChange('boeing', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.boeing, handleInputChange]);
    const AerospheresDetails = useMemo(() => (
        <>
            {/* *** Aerospheres *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={AerospheresImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            Aerospheres
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Description
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchAerospheres}
                            isChecked={switchAerospheres}
                            onChange={() => {
                                handleSwitchAerospheresChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.aerospheres}
                                    onChange={(e) => handleInputChange('aerospheres', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.aerospheres, handleInputChange]);
    const LasDetails = useMemo(() => (
        <>
            {/* *** LAS Aerospace *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={LasImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            LAS Aerospace
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Good for consumables
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchLas}
                            isChecked={switchLas}
                            onChange={() => {
                                handleSwitchLasChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.las}
                                    onChange={(e) => handleInputChange('las', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.las, handleInputChange]);
    const SilmidDetails = useMemo(() => (
        <>
            {/* *** Silmid *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={SilmidImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            Silmid
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Good for chemicals price info(depending on quantity)
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchSilmid}
                            isChecked={switchSilmid}
                            onChange={() => {
                                handleSwitchSilmidChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.silmid}
                                    onChange={(e) => handleInputChange('silmid', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.silmid, handleInputChange]);
    const DasiDetails = useMemo(() => (
        <>
            {/* *** DASI *** */}
            <Flex justify='space-between'>
                <Flex align='center'>
                    <Image src={DasiImage} w='34px' h='34px' me='14px' />
                    <Flex direction='column'>
                        <Text fontSize='md' color='#fff' fontWeight='bold'>
                            DASI
                        </Text>
                        <Text color='gray.400' fontWeight='400' fontSize='xs'>
                            Big platform with information from many suppliers
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Text
                        color='gray.500'
                        fontWeight='500'
                        me='17px'
                        fontSize='xs'>
                        Enabled
                    </Text>
                    <DarkMode>
                        <Switch
                            value={switchDasi}
                            isChecked={switchDasi}
                            onChange={() => {
                                handleSetSwitchDasiChange();
                            }}
                            colorScheme='brand'
                        />
                    </DarkMode>
                </Flex>
            </Flex>
            <Accordion allowMultiple style={{ marginTop: '6px' }} >
                <AccordionItem border='none'>
                    <h2>
                        <AccordionButton justifyContent="right" p="4px">
                            <Box as="span" fontSize="11px" color="gray.400">
                                MORE DETAILS
                                <AccordionIcon fontSize="md" />
                            </Box>
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        {/* <JsonFileReader /> */}
                        <Flex direction='column' w='100%'>
                            <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                    Cookies
                                </FormLabel>
                                <Textarea
                                    bg='#0F1535'
                                    border='.5px solid'
                                    borderColor='rgba(226, 232, 240, 0.3)'
                                    _hover='none'
                                    color='#fff'
                                    borderRadius='15px'
                                    placeholder='...'
                                    fontSize='xs'
                                    value={suppliersCookies.dasi}
                                    onChange={(e) => handleInputChange('dasi', e.target.value)} />
                            </FormControl>
                        </Flex>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            <HSeparator style={{ marginTop: '10px' }} />
        </>
    ), [suppliersCookies.dasi, handleInputChange]);

    return (
        <Modal size='4xl' isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent bg='#1a202c'>
                <ModalHeader color='white' pb='none' p='0px'>
                    <Box
                        // mt='20px'
                        mb={{ sm: '24px', md: '50px', xl: '20px' }}
                        borderRadius='15px'
                        // px='0px'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        align='center'>
                        {/* Header */}
                        <Card
                            direction={{ sm: 'column', md: 'row' }}
                            mx='auto'
                            maxH='330px'
                            w={{ sm: '90%', xl: '100%' }}
                            justifyContent={{ sm: 'center', md: 'space-between' }}
                            align='center'
                            p='10px'
                            borderRadius='0px'
                            borderTopRadius='6px'>
                            <Flex align='center' direction={{ sm: 'column', md: 'row' }}>
                                <Flex
                                    align='center'
                                    mb={{ sm: '10px', md: '0px' }}
                                    direction={{ sm: 'column', md: 'row' }}
                                    w={{ sm: '100%' }}
                                    textAlign={{ sm: 'center', md: 'start' }}
                                    height='73px'
                                    alignItems='center'>
                                    <Flex direction='column' maxWidth='100%' my={{ sm: '0px' }} ml='20px'>
                                        <Text
                                            align='top'
                                            fontSize={{ sm: 'lg', lg: 'xl' }}
                                            color='#fff'
                                            fontWeight='bold'
                                            ms={{ sm: '8px', md: '0px' }}
                                            ml='20px'>
                                            Parts Prices settings
                                        </Text>
                                        {/* <Text fontSize={{ sm: 'sm', md: 'md' }} color='gray.400'>
									Fill all empty fields
								</Text> */}
                                    </Flex>
                                </Flex>
                                <Flex direction={{ sm: 'column', lg: 'row' }} w={{ sm: '100%', md: '50%', lg: 'auto' }} mr='20px'>
                                    <Button
                                        variant='outline'
                                        _hover={{ bg: 'brand.500', color: 'white' }}
                                        borderColor='brand.500'
                                        color='brand.200'
                                        // color='white'
                                        // w='150px'
                                        h='35px'
                                        fontSize='xs'
                                        fontWeight='bold'
                                        alignSelf={{ sm: "flex-start", lg: null }}
                                        mb={{ sm: "6px", md: "0px" }}
                                        mt='38px'
                                        ml='10px'
                                        leftIcon={<AiFillSave size='20px' />}
                                        onClick={() => {
                                            // setOverlay(<OverlayOne />)
                                            // onOpen2()
                                            saveCookiesFunc()
                                            toast({
                                                title: `Settings saved`,
                                                description: 'Your preferred settings saved',
                                                status: 'success',
                                                variant: 'left-accent',
                                                position: 'bottom-right',
                                                isClosable: true,
                                            })
                                        }}>
                                        SAVE
                                    </Button>
                                    <Button
                                        variant='outline'
                                        _hover={{ bg: 'brand.500', color: 'white' }}
                                        borderColor='brand.500'
                                        color='brand.200'
                                        // color='white'
                                        // w='150px'
                                        h='35px'
                                        fontSize='xs'
                                        fontWeight='bold'
                                        alignSelf={{ sm: "flex-start", lg: null }}
                                        mb={{ sm: "6px", md: "0px" }}
                                        mt='38px'
                                        ml='10px'
                                        leftIcon={<BiReset size='20px' />}
                                        // pl='1px'
                                        onClick={() => {
                                            resetSuppliersSwitches()
                                        }}>
                                        RESET
                                    </Button>
                                </Flex>
                            </Flex>
                        </Card>
                    </Box>
                </ModalHeader>
                <ModalCloseButton color='white' />
                <ModalBody color='white'>
                    <Flex gap='2' alignItems='center' direction='column'>
                        {/* Accounts Card */}
                        <Card
                            w={{ sm: "100%" }}
                            alignSelf='flex-end'
                            justifySelf='flex-end'>
                            <Element to='accounts' name='accounts'>
                                <CardHeader mb='40px'>
                                    <Flex direction='column'>
                                        <Text color='#fff' fontSize='lg' fontWeight='bold' mb='4px'>
                                            Suppliers
                                        </Text>
                                        <Text color='gray.400' fontWeight='400' fontSize='sm'>
                                            Choose suppliers to find their offers
                                        </Text>
                                    </Flex>
                                </CardHeader>
                                <CardBody>
                                    <Stack direction='column' spacing='22px' w='100%'>
                                        {BombardierDetails}
                                        {EmbraerDetails}
                                        {GulfstreamDetails}
                                        {BoeingDetails}
                                        {AerospheresDetails}
                                        {LasDetails}
                                        {SilmidDetails}
                                        {DasiDetails}
                                        <Button onClick={() => console.log(suppliersCookies)}></Button>
                                        {/* <TestInputs /> */}
                                    </Stack>
                                </CardBody>
                            </Element>
                        </Card>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});

export default SettingsModal;
